import React, { useEffect } from 'react'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Spinner from 'react-bootstrap/Spinner'
import smoothscroll from 'smoothscroll-polyfill'
import { ToastContainer } from 'react-toastify'
import {
  RootContextProvider,
  ManagerContextProvider,
  ConstructorContextProvider,
  MenuLocalsContextProvider,
} from './context/userContext'
import V2Header from './components/layout/V2Header'
import V2Footer from './components/layout/V2Footer'
import WeAreCookiesUsers from './components/WeAreCookiesUsers'
import V2HomePage from './components/pages/V2HomePage'
import CompanyPage from './components/pages/CompanyPage'
import TermsPage from './components/pages/TermsPage'
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage'
import MenuManager from './components/MenuManager'
import MenuPage from './components/pages/MenuPage'
import SummaryPage from './components/pages/SummaryPage'
import './scss/main.scss'

const { REACT_APP_NOTIFICATION_DELAY_TIME, REACT_APP_NOTIFICATION_MAX_LIMIT } = process.env

const App = () => {
  const LoadingOverlay = () => {
    const { t } = useTranslation()
    return (
      <div className='global-loader spinner-container'>
        <h1>{t('AUTH0_LOADING_TEXT')}</h1>
        <Spinner animation='border' variant='info' size='xl' />
      </div>
    )
  }

  const Wrapper = ({ children }) => {
    const { error, isLoading } = useAuth0()

    if (isLoading) {
      return LoadingOverlay()
    }
    if (error) {
      console.error('AUTH0 ERROR:\n', error)
      if (typeof window !== 'undefined') {
        window.location.href = '/'
      } else {
        return <div>Auth0 Oops... {error.message}</div>
      }
    }

    return <>{children}</>
  }

  const ProtectedRoute = ({ component, ...args }) => (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: LoadingOverlay,
        loginOptions: {
          screen_hint: 'signup',
          appState: {
            returnTo: '/',
          },
        },
      })}
      {...args}
    />
  )

  useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  return (
    <RootContextProvider>
      <ToastContainer
        autoClose={Number.parseInt(REACT_APP_NOTIFICATION_DELAY_TIME)}
        limit={Number.parseInt(REACT_APP_NOTIFICATION_MAX_LIMIT)}
        draggablePercent={20}
        icon={false}
      />
      <Wrapper>
        <div className='main'>
          <V2Header />
          <Switch>
            <Route exact path='/' component={V2HomePage} />
            <Route exact path='/summary' component={SummaryPage} />
            <ProtectedRoute
              exact
              path='/my'
              component={() => (
                <ManagerContextProvider>
                  <MenuManager />
                </ManagerContextProvider>
              )}
            />
            <ProtectedRoute
              exact
              path='/my/:menuid'
              component={() => (
                <ConstructorContextProvider>
                  <MenuLocalsContextProvider>
                    <MenuPage />
                  </MenuLocalsContextProvider>
                </ConstructorContextProvider>
              )}
            />
            <Route exact path='/company' component={CompanyPage} />
            <Route exact path='/terms' component={TermsPage} />
            <Route exact path='/privacy-policy' component={PrivacyPolicyPage} />
            <Route path='*'>
              <Redirect to='/' />
            </Route>
          </Switch>
          <V2Footer />
        </div>
      </Wrapper>
      <WeAreCookiesUsers />
    </RootContextProvider>
  )
}

export default App
