import React from 'react'

const IconImport = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M12.0122 10.0947H11.2241C11.1626 10.0947 11.1113 10.146 11.1113 10.2075V11.1128H1.88721V1.88721H11.1128V2.79248C11.1128 2.854 11.1641 2.90527 11.2256 2.90527H12.0137C12.0752 2.90527 12.1265 2.85547 12.1265 2.79248V1.32471C12.1265 1.07568 11.9258 0.875 11.6768 0.875H1.32471C1.07568 0.875 0.875 1.07568 0.875 1.32471V11.6753C0.875 11.9243 1.07568 12.125 1.32471 12.125H11.6753C11.9243 12.125 12.125 11.9243 12.125 11.6753V10.2075C12.125 10.1445 12.0737 10.0947 12.0122 10.0947ZM12.2847 6.40771L10.2061 4.76709C10.1284 4.70557 10.0156 4.76123 10.0156 4.85938V5.97266H5.41602C5.35156 5.97266 5.29883 6.02539 5.29883 6.08984V6.91016C5.29883 6.97461 5.35156 7.02734 5.41602 7.02734H10.0156V8.14062C10.0156 8.23877 10.1299 8.29443 10.2061 8.23291L12.2847 6.59229C12.2987 6.58132 12.31 6.56732 12.3178 6.55133C12.3256 6.53534 12.3296 6.51779 12.3296 6.5C12.3296 6.48221 12.3256 6.46466 12.3178 6.44867C12.31 6.43268 12.2987 6.41868 12.2847 6.40771Z'
        fill={color}
      />
    </svg>
  )
}

export default IconImport
