import React from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const options = {
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: true,
  closeOnClick: true,
  hideProgressBar: false,
  closeButton: false,
  className: 'custom-notification',
}
const getContent = ({ title, message }) => {
  return (
    <>
      {title && <h3>{title}</h3>}
      <p>{message}</p>
    </>
  )
}

export const ntfWarn = ({ title, message }) => {
  options.className = 'custom-notification warning'
  options.toastId = `warning-${title}${message}`
  return toast.warning(getContent({ title, message }), options)
}

export const ntfSuccess = ({ title, message }) => {
  options.className = 'custom-notification success'
  options.toastId = `success-${title}${message}`
  return toast.success(getContent({ title, message }), options)
}

export const ntfError = ({ title, message }) => {
  options.className = 'custom-notification error'
  options.toastId = `error-${title}${message}`
  return toast.error(getContent({ title, message }), options)
}

export const ntfInfo = ({ title, message }) => {
  options.className = 'custom-notification info'
  options.toastId = `info-${title}${message}`
  return toast.info(getContent({ title, message }), options)
}

export const ntfErrorWithLink = ({ title, message, link: { caption, url } }) => {
  options.className = 'custom-notification error'
  options.toastId = `error-with-link-${title}${message}${caption}${url}`
  options.autoClose = false
  options.closeOnClick = false

  const content = () => {
    return (
      <>
        {title && <h3>{title}</h3>}
        <p>{message}</p>
        <a href={url}>{caption}</a>
      </>
    )
  }
  return toast.error(content, options)
}
