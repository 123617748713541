import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { DebounceInput } from 'react-debounce-input'
import Spinner from 'react-bootstrap/Spinner'
import FormControl from 'react-bootstrap/FormControl'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select'
import {
  RootGetterContext,
  RootSetterContext,
  ManagerGetterContext,
  ManagerSetterContext,
} from '../../context/userContext'
import { createMenu, checkIsAliasUnique, getUser, getMenu } from '../../helpers/index'
import { ntfSuccess, ntfWarn, ntfError } from '../../helpers/notifications'
import { fullList } from '../../data/langList'
import { useIsDesktop } from '../../helpers/hooks'
const availableLanguages = fullList

export const ModalRemove = () => {
  const { showRemoveModal, isLoadingGlobal } = useContext(RootGetterContext)
  const { setShowRemoveModal } = useContext(RootSetterContext)
  const { t } = useTranslation()

  const handleCloseModal = () => {
    !isLoadingGlobal && setShowRemoveModal(false)
  }

  const { show, name, kind, cbName, cbArgs } = showRemoveModal

  return (
    showRemoveModal && (
      <Modal className='remove-modal-dialog' show={show} onHide={handleCloseModal}>
        <Modal.Body>{`${t('CON_MODAL_DELETE_TEXT')}${name ? ` ${name}` : ''}${
          kind ? ` ${kind}` : ''
        }?`}</Modal.Body>
        <Modal.Footer>
          <div className='v2-button sm' onClick={handleCloseModal}>
            {t('CON_MODAL_DELETE_BUTTON_NO')}
          </div>
          <div
            className='v2-button sm v2-button-outline'
            onClick={async () => {
              if (!isLoadingGlobal) {
                const cbResult = await cbName(...cbArgs)
                cbResult
                  ? ntfSuccess({ message: t('MESSAGE_DELETE_SUCCESS') })
                  : ntfError({ message: t('MESSAGE_TRY_AGAIN') })
                handleCloseModal()
              }
            }}
          >
            {isLoadingGlobal && <Spinner as='span' role='status' animation='border' size='sm' />}
            {t('CON_MODAL_DELETE_BUTTON_YES')}
          </div>
        </Modal.Footer>
      </Modal>
    )
  )
}

export const AliasOnlyModal = withRouter(({ history }) => {
  const { token, isLoadingGlobal } = useContext(RootGetterContext)
  const { setIsLoadingGlobal } = useContext(RootSetterContext)
  const { user } = useAuth0()
  const { showAliasOnlyModal } = useContext(ManagerGetterContext)
  const { setUserObject, setShowAliasOnlyModal } = useContext(ManagerSetterContext)
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()

  const [alias, setAlias] = useState('')
  const [aliasWarning, setAliasWarning] = useState('')
  const [isAliasUnique, setIsAliasUnique] = useState(true)
  const [isFetching, setIsFetching] = useState(false)
  const [aliasFontSize, setAliasFontSize] = useState(isDesktop ? 30 : 22)

  useEffect(() => {
    const myAlertEl = document.getElementById('aliasPreviewText')
    if (myAlertEl) {
      const style = window.getComputedStyle(myAlertEl, null).getPropertyValue('font-size')
      const defaultSize = isDesktop ? 30 : 22
      let fontSize = parseFloat(style)
      if (isNaN(fontSize)) fontSize = defaultSize
      const newFontSize =
        fontSize * (Math.trunc(100 * ((isDesktop ? 434 : 290) / myAlertEl.offsetWidth)) / 100) - 1
      myAlertEl.style.fontSize = `${newFontSize > defaultSize ? defaultSize : newFontSize}px`
      setAliasFontSize(newFontSize > defaultSize ? defaultSize : newFontSize)
    }
  }, [alias, isDesktop])

  const aliasCheckErrors = [
    t('MESSAGE_ALIAS_NEED_PATTERN'),
    t('MESSAGE_ALIAS_NEED_AT_LEAST', { minLength: process.env.REACT_APP_MIN_ALIAS_LENGTH }),
    t('MESSAGE_ALIAS_NEED_UNIQUE'),
    t('MESSAGE_TRY_AGAIN'),
  ]

  const handleCheckIsAliasUnique = async (e) => {
    if (isFetching || isLoadingGlobal) return
    setIsFetching(true)
    try {
      if (e.target && e.target.value) {
        const tempAlias = e.target.value.toLowerCase()
        const checkResult = await checkIsAliasUnique(token, tempAlias)
        if (checkResult.success) {
          if (checkResult.data.success) {
            setIsAliasUnique(true)
            setAliasWarning('')
          } else {
            setIsAliasUnique(false)
            setAliasWarning(aliasCheckErrors[checkResult.data.error])
          }
        } else {
          ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
          console.error(`checkIsAliasUnique error:\n${checkResult.error}`)
        }
        setAlias(tempAlias)
      } else {
        setAlias('')
        setIsAliasUnique(true)
        setAliasWarning('')
      }
    } catch (e) {
      console.error(`handleCheckIsAliasUnique error:\n${e.toString()}`)
      ntfError({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
    } finally {
      setIsFetching(false)
    }
  }

  const handleMenuClone = async () => {
    if (!alias || !isAliasUnique || isFetching || isLoadingGlobal) return
    setIsLoadingGlobal(true)
    try {
      const menuData = await getMenu(token, user.sub, showAliasOnlyModal)
      if (menuData.success) {
        const newMenu = menuData.data
        newMenu.alias = ''
        if (alias) {
          newMenu.alias = alias
        }
        newMenu.content = newMenu.content.map((local, l) => {
          local.isPublished = false
          return local
        })

        const cbResult = await createMenu(token, user.sub, alias, newMenu.defaultLanguage, newMenu)
        if (cbResult.success) {
          if (cbResult.data.success) {
            const userData = await getUser(token, user.sub)
            if (userData.success) {
              setUserObject(userData.data)
              setShowAliasOnlyModal(null)
              //history.push(`/my/${cbResult.data.data}`)
            } else {
              console.error('getUser error: ', userData.error)
              ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
            }
          } else {
            console.error('createMenu data.error: ', cbResult.data.error)
            ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
          }
        } else {
          console.error('createMenu error: ', cbResult.error)
          ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
        }
      } else {
        console.error('getMenu error: ', menuData.error)
        ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
      }
    } catch (e) {
      console.error(`handleMenuClone error:\n${e.toString()}`)
      ntfError({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
    } finally {
      setIsLoadingGlobal(false)
    }
  }

  return (
    showAliasOnlyModal && (
      <Modal
        className='alias-modal-dialog alias-modal-dialog-alias-only'
        show={!!showAliasOnlyModal}
        onHide={() => !isFetching && setShowAliasOnlyModal(null)}
      >
        <Modal.Header>
          <h4>{t('MANAGER_MODAL_CLONE_TITLE')}</h4>
        </Modal.Header>
        <Modal.Body>
          <p>{t('MANAGER_MODAL_CLONE_TEXT')}</p>

          <DebounceInput
            element={FormControl}
            debounceTimeout={process.env.REACT_APP_DEBOUNCE_TIMOUT}
            maxLength={process.env.REACT_APP_MAX_ALIAS_LENGTH}
            className={`alias-input${!isAliasUnique ? ' invalid' : ''}`}
            name='alias'
            value={alias.toLowerCase()}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
            onChange={handleCheckIsAliasUnique}
          />

          <div className='alias-info'>
            {isFetching ? (
              <Spinner animation='border' variant='info' size='sm' />
            ) : (
              <p className='alias-hint'>{aliasWarning}</p>
            )}
          </div>
          <div className={`alias-preview alert alert-${!isAliasUnique ? 'danger' : 'success'}`}>
            <p
              id='aliasPreviewText'
              className='alias-preview-text'
              style={{ fontSize: `${aliasFontSize}px` }}
            >
              {`https://${alias || 'alias'}.gimme.menu`}
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div
            className='v2-button sm v2-button-outline'
            onClick={() => !isFetching && setShowAliasOnlyModal(null)}
          >
            {t('MANAGER_MODAL_CLONE_BUTTON_CANCEL')}
          </div>
          <div
            className='v2-button sm'
            onClick={() => {
              if (!isFetching) {
                if (alias && isAliasUnique) {
                  handleMenuClone()
                } else {
                  if (!alias && !aliasWarning) {
                    setIsAliasUnique(false)
                    setAliasWarning(aliasCheckErrors[1])
                  }
                }
              }
            }}
          >
            {isLoadingGlobal && <Spinner as='span' role='status' animation='border' size='sm' />}
            {t('MANAGER_MODAL_CLONE_BUTTON_CLONE')}
          </div>
        </Modal.Footer>
      </Modal>
    )
  )
})

export const AliasModal = withRouter(({ history }) => {
  const { token, isLoadingGlobal } = useContext(RootGetterContext)
  const { setIsLoadingGlobal } = useContext(RootSetterContext)
  const { user } = useAuth0()
  const { showAliasModal } = useContext(ManagerGetterContext)
  const { setShowAliasModal } = useContext(ManagerSetterContext)

  const { t } = useTranslation()
  const isDesktop = useIsDesktop()

  const [alias, setAlias] = useState('')
  const [aliasWarning, setAliasWarning] = useState('')
  const [isAliasUnique, setIsAliasUnique] = useState(true)
  const [customDefaultLang, setCustomDefaultLang] = useState('en')
  const [isFetching, setIsFetching] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [aliasFontSize, setAliasFontSize] = useState(isDesktop ? 30 : 22)

  const totalSteps = 3

  const handleSelectChange = ({ value }) => {
    setCustomDefaultLang(value)
  }

  const getLangName = (short) => {
    const langObj = availableLanguages.filter((item) => item.value === short)
    return langObj[0].label
  }

  const langsToGo = availableLanguages.filter((lang) => lang.value)

  useEffect(() => {
    const myAlertEl = document.getElementById('aliasPreviewText')
    if (myAlertEl) {
      const style = window.getComputedStyle(myAlertEl, null).getPropertyValue('font-size')
      const defaultSize = isDesktop ? 30 : 22
      let fontSize = parseFloat(style)
      if (isNaN(fontSize)) fontSize = defaultSize
      const newFontSize =
        fontSize * (Math.trunc(100 * ((isDesktop ? 434 : 290) / myAlertEl.offsetWidth)) / 100) - 1
      myAlertEl.style.fontSize = `${newFontSize > defaultSize ? defaultSize : newFontSize}px`
      setAliasFontSize(newFontSize > defaultSize ? defaultSize : newFontSize)
    }
  }, [alias, isDesktop])

  const aliasCheckErrors = [
    t('MESSAGE_ALIAS_NEED_PATTERN'),
    t('MESSAGE_ALIAS_NEED_AT_LEAST', { minLength: process.env.REACT_APP_MIN_ALIAS_LENGTH }),
    t('MESSAGE_ALIAS_NEED_UNIQUE'),
    t('MESSAGE_TRY_AGAIN'),
  ]

  const handleCheckIsAliasUnique = async (e) => {
    if (isFetching || isLoadingGlobal) return
    setIsFetching(true)
    try {
      if (e.target && e.target.value) {
        const tempAlias = e.target.value.toLowerCase()
        const checkResult = await checkIsAliasUnique(token, tempAlias)
        if (checkResult.success) {
          if (checkResult.data.success) {
            setIsAliasUnique(true)
            setAliasWarning('')
          } else {
            setIsAliasUnique(false)
            setAliasWarning(aliasCheckErrors[checkResult.data.error])
          }
        } else {
          ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
          console.error(` checkIsAliasUnique error:\n${checkResult.error}`)
        }
        setAlias(tempAlias)
      } else {
        setAlias('')
        setIsAliasUnique(true)
        setAliasWarning('')
      }
    } catch (e) {
      console.error(`handleCheckIsAliasUnique error:\n${e.toString()}`)
      ntfError({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
    } finally {
      setIsFetching(false)
    }
  }

  const handleMenuCreate = async () => {
    if (!alias || !isAliasUnique || isFetching || isLoadingGlobal) return
    setIsLoadingGlobal(true)
    try {
      const cbResult = await createMenu(token, user.sub, alias, customDefaultLang, null)
      if (cbResult.success) {
        if (cbResult.data.success) {
          setShowAliasModal(null)
          history.push(`/my/${cbResult.data.data}`)
        } else {
          console.error('createMenu data.error: ', cbResult.data.error)
          ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
        }
      } else {
        console.error('createMenu error: ', cbResult.error)
        ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
      }
    } catch (e) {
      console.error(`handleMenuCreate error:\n${e.toString()}`)
      ntfError({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_TRY_AGAIN') })
    } finally {
      setIsLoadingGlobal(false)
    }
  }

  const firstStep = () => (
    <>
      <p className='top'>{t('MANAGER_MODAL_CREATE_STEP1_TEXT_1')}</p>
      <p className='top'>{t('MANAGER_MODAL_CREATE_STEP1_TEXT_2')}</p>
      <p>
        <span>{t('MANAGER_MODAL_CREATE_STEP1_IMPORTANT')}</span>
        {` ${t('MANAGER_MODAL_CREATE_STEP1_TEXT_3')}`}
      </p>
    </>
  )

  const secondStep = () => (
    <>
      <p>{t('MANAGER_MODAL_CREATE_STEP2_TEXT')}</p>

      <DebounceInput
        element={FormControl}
        debounceTimeout={process.env.REACT_APP_DEBOUNCE_TIMOUT}
        maxLength={process.env.REACT_APP_MAX_ALIAS_LENGTH}
        className={`alias-input${!isAliasUnique ? ' invalid' : ''}`}
        name='alias'
        value={alias.toLowerCase()}
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
        }}
        onChange={handleCheckIsAliasUnique}
      />

      <div className='alias-info'>
        {isFetching ? (
          <Spinner animation='border' variant='info' size='sm' />
        ) : (
          <p className='alias-hint'>{aliasWarning}</p>
        )}
      </div>
      <div className={`alias-preview alert alert-${!isAliasUnique ? 'danger' : 'success'}`}>
        <p
          id='aliasPreviewText'
          className='alias-preview-text'
          style={{ fontSize: `${aliasFontSize}px` }}
        >
          {`https://${alias || 'alias'}.gimme.menu`}
        </p>
      </div>
    </>
  )

  const thirdStep = () => (
    <>
      <p>{t('MANAGER_MODAL_CREATE_STEP3_TEXT')}</p>
      <div className='default-lang-select'>
        <Select
          size='sm'
          className='select-container'
          classNamePrefix='select-container'
          placeholder={getLangName(customDefaultLang)}
          value={customDefaultLang}
          options={langsToGo}
          onChange={handleSelectChange}
          noOptionsMessage={() => t('CON_LANGUAGE_NOT_FOUND')}
          unstyled='true'
        />
      </div>
    </>
  )

  const getModalBody = () => {
    switch (currentStep) {
      case 2:
        return secondStep()
      case 3:
        return thirdStep()
      default:
        return firstStep()
    }
  }

  const getBackButton = () => {
    let buttonCaption

    if (currentStep > 1) {
      buttonCaption = t('MANAGER_MODAL_CREATE_BUTTON_BACK')
    } else {
      buttonCaption = t('MANAGER_MODAL_CREATE_BUTTON_CANCEL')
    }

    return <>{buttonCaption}</>
  }

  const getNextButton = () => {
    let spinner
    let buttonCaption

    if (currentStep < 3) {
      spinner = ''
      buttonCaption = t('MANAGER_MODAL_CREATE_BUTTON_NEXT')
    } else {
      spinner = isLoadingGlobal && <Spinner as='span' role='status' animation='border' size='sm' />
      buttonCaption = t('MANAGER_MODAL_CREATE_BUTTON_CREATE')
    }

    return (
      <>
        {spinner}
        {buttonCaption}
      </>
    )
  }

  const getClickHandler = (forward) => {
    if (isFetching) return
    if (forward) {
      switch (currentStep) {
        case 2: {
          if (alias && isAliasUnique) {
            return setCurrentStep(currentStep + 1)
          } else {
            if (!alias && !aliasWarning) {
              setIsAliasUnique(false)
              setAliasWarning(aliasCheckErrors[1])
            }
            return
          }
        }
        case 3:
          return handleMenuCreate()
        default:
          return setCurrentStep(currentStep + 1)
      }
    } else {
      return currentStep < 2 ? setShowAliasModal(null) : setCurrentStep(currentStep - 1)
    }
  }

  return (
    showAliasModal && (
      <Modal
        className='alias-modal-dialog'
        show={showAliasModal}
        onHide={() => !isFetching && setShowAliasModal(null)}
      >
        <Modal.Header>
          <h4>{t('MANAGER_MODAL_CREATE_TITLE')}</h4>
          <div className='progress-block'>
            <p>
              {t('MANAGER_MODAL_CREATE_SUBTITLE', {
                currentStep: currentStep,
                totalSteps: totalSteps,
              })}
            </p>
            <div className='progress-bar'>
              <div className={`progress-bar-inner step-${currentStep}`} />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>{getModalBody()}</Modal.Body>

        <Modal.Footer>
          <div className='v2-button sm v2-button-outline' onClick={() => getClickHandler(false)}>
            {getBackButton()}
          </div>
          <div className='v2-button sm' onClick={() => getClickHandler(true)}>
            {getNextButton()}
          </div>
        </Modal.Footer>
      </Modal>
    )
  )
})
