import React from 'react'

const IconCopy = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 13 13'
      fill='none'
    >
      <path
        d='M10.5625 0.8125H3.75781C3.70195 0.8125 3.65625 0.858203 3.65625 0.914062V1.625C3.65625 1.68086 3.70195 1.72656 3.75781 1.72656H10.0547V10.4609C10.0547 10.5168 10.1004 10.5625 10.1562 10.5625H10.8672C10.923 10.5625 10.9688 10.5168 10.9688 10.4609V1.21875C10.9688 0.994043 10.7872 0.8125 10.5625 0.8125ZM8.9375 2.4375H2.4375C2.21279 2.4375 2.03125 2.61904 2.03125 2.84375V9.58115C2.03125 9.68906 2.07441 9.79189 2.15059 9.86807L4.35068 12.0682C4.37861 12.0961 4.41035 12.1189 4.44463 12.138V12.1621H4.49795C4.54238 12.1786 4.58936 12.1875 4.6376 12.1875H8.9375C9.16221 12.1875 9.34375 12.006 9.34375 11.7812V2.84375C9.34375 2.61904 9.16221 2.4375 8.9375 2.4375ZM4.44336 10.8697L3.35029 9.77539H4.44336V10.8697ZM8.42969 11.2734H5.25586V9.4707C5.25586 9.19014 5.02861 8.96289 4.74805 8.96289H2.94531V3.35156H8.42969V11.2734Z'
        fill={color}
      />
    </svg>
  )
}

export default IconCopy
