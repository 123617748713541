import React, { useState, useEffect, useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import InputGroup from 'react-bootstrap/InputGroup'
import { useDebouncedCallback } from 'use-debounce'
import { ntfWarn } from '../helpers/notifications'
import { measureUnitsSchema } from '../validation/schemas'
import { validateMeasureUnit, validateMeasureLargeUnit } from '../validation/validate'
import { placeholders } from '../data'
import {
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'

const { REACT_APP_DEBOUNCE_TIMOUT } = process.env

const DebouncedMenuMeasuresInput = ({ incomingName, incomingValue }) => {
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { currentLanguage } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const [measureUnitValue, setMeasureUnitValue] = useState(incomingValue)
  const { t } = useTranslation()

  const validator =
    incomingName === 'time' || incomingName === 'quantity' || incomingName === 'diameter'
      ? validateMeasureLargeUnit
      : validateMeasureUnit

  const handleMeasureUnitsChange = (name, value) => {
    const _menuLocals = menuLocals.map((translation) => {
      if (translation.lang === currentLanguage) {
        translation.measureUnits[name] = value
      }
      return translation
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const debouncedHandleMeasureUnitsChange = useDebouncedCallback(
    handleMeasureUnitsChange,
    REACT_APP_DEBOUNCE_TIMOUT
  )

  useEffect(() => {
    setMeasureUnitValue(incomingValue)
  }, [incomingValue])

  const i18nUnits = {
    currency: t('CON_MEASURE_CURRENCY'),
    mass: t('CON_MEASURE_WEIGHT'),
    quantity: t('CON_MEASURE_QUANTITY'),
    time: t('CON_MEASURE_TIME'),
    volume: t('CON_MEASURE_VOLUME'),
    diameter: t('CON_MEASURE_DIAMETER'),
    length: t('CON_MEASURE_LENGTH'),
  }

  const i18nErrorUnits = {
    currency: t('MESSAGE_SYMBOL_OR_LETTERS'),
    mass: t('MESSAGE_3_LETTERS_MAX'),
    quantity: t('MESSAGE_6_LETTERS_MAX'),
    time: t('MESSAGE_6_LETTERS_MAX'),
    volume: t('MESSAGE_3_LETTERS_MAX'),
    diameter: t('MESSAGE_6_LETTERS_MAX'),
    length: t('MESSAGE_6_LETTERS_MAX'),
  }

  return (
    <Col className='unit' xl={4}>
      <InputGroup size='md'>
        <InputGroup.Prepend>
          <InputGroup.Text>{i18nUnits[incomingName]}</InputGroup.Text>
        </InputGroup.Prepend>
        <input
          className='form-control'
          type='text'
          name={incomingName}
          value={measureUnitValue}
          disabled={isLocalsLoading}
          placeholder={placeholders[incomingName]}
          onChange={(e) => {
            const fieldValue = e.target.value
            const fieldName = e.target.name
            const isFieldValid = !validator(fieldValue).error
            if (isFieldValid) {
              setMeasureUnitValue(fieldValue)
              debouncedHandleMeasureUnitsChange(fieldName, fieldValue)
            } else {
              ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: i18nErrorUnits[fieldName] })
            }
          }}
        />
      </InputGroup>
    </Col>
  )
}

const SkeletonInput = () => {
  return (
    <Col className='unit' xl={4}>
      <InputGroup size='md'>
        <div className='skeleton-container'>
          <Skeleton />
        </div>
      </InputGroup>
    </Col>
  )
}

const MeasureUnits = () => {
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { currentLanguage } = useContext(ConstructorGetterContext)

  const [langData, setLangData] = useState(null)

  useEffect(() => {
    if (menuLocals && currentLanguage) {
      setLangData(menuLocals.find((local) => local.lang === currentLanguage))
    }
  }, [menuLocals, currentLanguage])

  return (
    <>
      {langData ? (
        Object.keys(langData.measureUnits).map((unit, u) => (
          <DebouncedMenuMeasuresInput
            incomingName={unit}
            incomingValue={langData.measureUnits[unit]}
            key={u}
          />
        ))
      ) : (
        <>
          {Object.keys(Object.fromEntries(measureUnitsSchema._ids._byKey)).map((key) => (
            <SkeletonInput key={key.toString()} />
          ))}
        </>
      )}
    </>
  )
}
export default MeasureUnits
