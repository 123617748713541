import React from 'react'

const IconAddItem = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 11 11'
      fill='none'
    >
      <path
        d='M7.47656 5.15625H5.84375V3.52344C5.84375 3.47617 5.80508 3.4375 5.75781 3.4375H5.24219C5.19492 3.4375 5.15625 3.47617 5.15625 3.52344V5.15625H3.52344C3.47617 5.15625 3.4375 5.19492 3.4375 5.24219V5.75781C3.4375 5.80508 3.47617 5.84375 3.52344 5.84375H5.15625V7.47656C5.15625 7.52383 5.19492 7.5625 5.24219 7.5625H5.75781C5.80508 7.5625 5.84375 7.52383 5.84375 7.47656V5.84375H7.47656C7.52383 5.84375 7.5625 5.80508 7.5625 5.75781V5.24219C7.5625 5.19492 7.52383 5.15625 7.47656 5.15625Z'
        fill={color}
      />
      <path
        d='M5.5 0.6875C2.84238 0.6875 0.6875 2.84238 0.6875 5.5C0.6875 8.15762 2.84238 10.3125 5.5 10.3125C8.15762 10.3125 10.3125 8.15762 10.3125 5.5C10.3125 2.84238 8.15762 0.6875 5.5 0.6875ZM5.5 9.49609C3.29355 9.49609 1.50391 7.70645 1.50391 5.5C1.50391 3.29355 3.29355 1.50391 5.5 1.50391C7.70645 1.50391 9.49609 3.29355 9.49609 5.5C9.49609 7.70645 7.70645 9.49609 5.5 9.49609Z'
        fill={color}
      />
    </svg>
  )
}

export default IconAddItem
