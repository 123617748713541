import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Skeleton from 'react-loading-skeleton'
import {
  RootGetterContext,
  RootSetterContext,
  ManagerGetterContext,
  ManagerSetterContext,
} from '../context/userContext'
import { createUser, getUser, getBuildMenuStatus } from '../helpers/index'
import MenuItem from './MenuItem'
import EmptyMenuBlock from './EmptyMenuBlock'
import { ModalRemove, AliasModal, AliasOnlyModal } from './shared/Modals'
import icons from '../img/icons/index'
import { useIsDesktop } from '../helpers/hooks'

import { ntfErrorWithLink } from '../helpers/notifications'
import { userNotFoundMsg } from '../data/messages'

import QRCode from 'qrcode.react'
import AliasSwapper from './AliasSwapper'

const { REACT_APP_JWT_AUDIENCE, REACT_APP_AUTH0_SCOPE, REACT_APP_MANAGER_INDICATOR_REFRESH_RATE } =
  process.env

const { IconAddItem } = icons

const MenuManager = () => {
  const { user, getAccessTokenSilently } = useAuth0()
  const { token, showRemoveModal, isLoadingGlobal } = useContext(RootGetterContext)
  const { setToken, setIsLoadingGlobal } = useContext(RootSetterContext)
  const { userObject, showAliasModal, showAliasOnlyModal, qrPopup } =
    useContext(ManagerGetterContext)
  const { setUserObject, setShowAliasModal, setQrPopup } = useContext(ManagerSetterContext)

  const { t } = useTranslation()
  const isDesktop = useIsDesktop()

  let timeout = null

  const isThereRunningBuilds = (userObject) => {
    return userObject.menuList.some((menu) => getBuildMenuStatus(menu.events) === 1)
  }

  const autoUpdate = async () => {
    timeout && clearTimeout(timeout)

    if (userObject) {
      if (isThereRunningBuilds(userObject)) {
        const userData = await getUser(token, user.sub)
        if (userData.success) {
          timeout = setTimeout(async () => {
            setUserObject(userData.data)
          }, REACT_APP_MANAGER_INDICATOR_REFRESH_RATE)
        } else {
          ntfErrorWithLink({
            title: t('MESSAGE_ERROR_TITLE'),
            link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
          })
          timeout = setTimeout(async () => {
            autoUpdate()
          }, REACT_APP_MANAGER_INDICATOR_REFRESH_RATE)
        }
      } else {
        timeout = setTimeout(async () => {
          autoUpdate()
        }, REACT_APP_MANAGER_INDICATOR_REFRESH_RATE)
      }
    }
  }

  useEffect(() => {
    if (userObject) {
      autoUpdate()
    }
    return () => timeout && clearTimeout(timeout)
  }, [userObject])

  useEffect(() => {
    ;(async () => {
      setIsLoadingGlobal(true)
      try {
        if (!token) {
          const accessToken = await getAccessTokenSilently({
            audience: REACT_APP_JWT_AUDIENCE,
            scope: REACT_APP_AUTH0_SCOPE,
          })
          setToken(accessToken)
        } else {
          const userData = await getUser(token, user.sub)
          if (userData.success) {
            setUserObject(userData.data)
          } else {
            if (userData.error === userNotFoundMsg) {
              const { name, nickname, given_name, family_name, email, sub } = user

              const userName = name || nickname || given_name || family_name || email || 'Unknown'

              const newUser = await createUser(token, sub, userName, email)
              if (newUser.success) {
                const userData = await getUser(token, sub)
                if (userData.success) {
                  setUserObject(userData.data)
                } else {
                  ntfErrorWithLink({
                    title: 'getUser error',
                    message: userData.error,
                    link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
                  })
                }
              } else {
                ntfErrorWithLink({
                  title: 'createUser error',
                  message: newUser.error,
                  link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
                })
              }
            } else {
              ntfErrorWithLink({
                title: 'getUser error',
                message: userData.error,
                link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
              })
            }
          }
        }
      } catch (e) {
        ntfErrorWithLink({
          title: 'getMenu error',
          message: e.message,
          link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
        })
        console.error('getMenu Error:\n', e.message)
      } finally {
        setIsLoadingGlobal(false)
      }
    })()
    // eslint-disable-next-line
  }, [token])

  const handleMenuCreation = async () => {
    if (isLoadingGlobal) return
    setShowAliasModal(true)
  }

  const sortMenusByLastUpdate = (arr) => {
    try {
      return arr.sort((a, b) => +new Date(b.events.editedAt) - +new Date(a.events.editedAt))
    } catch (e) {
      console.error(`[sortMenusByLastUpdate]\n${e.toString()}`)
      return arr
    }
  }

  return (
    <section className='menu-manager'>
      <div className='menu-manager-header'>
        <div className='inner'>
          {userObject ? (
            <>
              <h2>{t('MANAGER_TITLE')}</h2>
              <div
                className={`v2-button${isDesktop ? '' : ' sm'}`}
                onClick={() => handleMenuCreation()}
              >
                <IconAddItem color='#ffffff' width={isDesktop ? '24px' : '20px'} />
                {t('MANAGER_BUTTON_CREATE')}
              </div>
            </>
          ) : (
            <>
              <h2>
                <Skeleton />
              </h2>
              <div className={`skeleton-button${isDesktop ? '' : ' sm'}`}>
                <Skeleton />
              </div>
            </>
          )}
        </div>
      </div>

      {userObject ? (
        userObject.menuList?.length ? (
          <div className='menu-list'>
            {sortMenusByLastUpdate(userObject.menuList).map((menuItem, m) => {
              return <MenuItem key={m} data={menuItem} />
            })}
          </div>
        ) : (
          <EmptyMenuBlock />
        )
      ) : (
        <div className='menu-list'>
          <MenuItem />
        </div>
      )}
      {showRemoveModal && <ModalRemove />}
      {showAliasModal && <AliasModal userId={userObject?.id} />}
      {showAliasOnlyModal && <AliasOnlyModal userId={userObject?.id} />}
      {qrPopup && (
        <div style={{ position: 'relative', zIndex: '999' }}>
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'fixed',
              top: 0,
              left: 0,
              background: 'rgba(255,255,255,0.5)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => setQrPopup(false)}
          >
            <QRCode renderAs='svg' value={qrPopup} size={250} />
          </div>
        </div>
      )}
      <AliasSwapper />
    </section>
  )
}

export default withRouter(MenuManager)
