import React from 'react'

const DefaultLogo = ({ width = '160', height = '160' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 160 160'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='160' height='160' fill='white' />
      <path
        d='M81.3369 63.9254C77.5382 63.9254 74.3823 63.2613 71.8693 61.933C69.3855 60.6048 67.5154 58.7568 66.2589 56.3891C65.0316 53.9925 64.418 51.1916 64.418 47.9865C64.418 44.8681 65.0608 42.125 66.3466 39.7573C67.6323 37.3607 69.5463 35.4982 72.0885 34.17C74.6307 32.8129 77.772 32.1343 81.5122 32.1343C85.4279 32.1343 88.3792 32.6396 90.3662 33.6503C92.3825 34.632 93.3906 35.8447 93.3906 37.2885C93.3906 38.3568 92.8354 39.3241 91.725 40.1904C90.6438 41.0277 88.9344 41.4464 86.5967 41.4464C86.5967 39.3963 86.1438 37.8515 85.2379 36.812C84.3321 35.7726 83.061 35.2528 81.4246 35.2528C78.5901 35.2528 76.5739 36.3645 75.3758 38.5878C74.207 40.7823 73.6226 43.9152 73.6226 47.9865C73.6226 52.0579 74.2508 55.1908 75.5073 57.3853C76.7638 59.5509 78.9116 60.6337 81.9506 60.6337C82.5934 60.6337 83.3239 60.5904 84.1421 60.5037V54.57C84.1421 53.5016 83.9522 52.7076 83.5723 52.1878C83.1925 51.6681 82.4619 51.4082 81.3807 51.4082H80.548V48.7662H95.5821V51.4082H95.1877C94.2526 51.4082 93.639 51.6825 93.3467 52.2311C93.0545 52.7509 92.9084 53.5882 92.9084 54.7432V61.3267C91.0383 62.1929 89.1535 62.8426 87.2542 63.2757C85.384 63.7088 83.4116 63.9254 81.3369 63.9254Z'
        fill='#FA541C'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M107.36 20.52L92.4877 20.52L92.4877 25.0452L93.5093 25.0452C93.5093 24.6709 93.5819 24.3516 93.727 24.0873C93.861 23.8231 94.1904 23.6909 94.7152 23.6909L104.144 23.6909L104.144 32.9892C104.144 33.5067 104.01 33.8315 103.742 33.9636C103.474 34.1067 103.15 34.1783 102.771 34.1783L102.771 35.1857L107.36 35.1857L107.36 25.7554L107.36 20.52L107.36 20.52Z'
        fill='#595959'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M52.64 59.8142L52.64 74.48L52.64 74.48L67.5123 74.48L67.5123 69.9547L66.4907 69.9547C66.4907 70.3291 66.4181 70.6484 66.273 70.9126C66.139 71.1769 65.8096 71.309 65.2848 71.309L55.8557 71.309L55.8557 62.0108C55.8557 61.4933 55.9896 61.1685 56.2576 61.0364C56.5256 60.8932 56.8495 60.8216 57.2292 60.8216L57.2292 59.8142L52.64 59.8142Z'
        fill='#595959'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M67.5124 20.52L52.64 20.52V20.52H52.64V35.1857H57.229V34.1783C56.8494 34.1783 56.5256 34.1067 56.2576 33.9636C55.9896 33.8315 55.8557 33.5066 55.8557 32.9892V23.6909L65.2849 23.6909C65.8096 23.6909 66.139 23.8231 66.273 24.0873C66.4182 24.3516 66.4907 24.671 66.4907 25.0454L67.5124 25.0454V20.52Z'
        fill='#595959'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M107.36 74.48L107.36 59.8143L102.771 59.8143L102.771 60.8217C103.151 60.8217 103.474 60.8933 103.742 61.0364C104.01 61.1686 104.144 61.4934 104.144 62.0108L104.144 71.3091L94.7151 71.3091C94.1904 71.3091 93.861 71.1769 93.727 70.9127C93.5819 70.6484 93.5093 70.329 93.5093 69.9546L92.4876 69.9546L92.4876 74.48L102.051 74.48L107.36 74.48Z'
        fill='#595959'
      />
      <path
        d='M26.5851 90.344C29.0172 90.344 30.9195 91.304 32.2921 93.224H32.4365L32.87 90.704H37.421V110.396C37.421 113.204 36.5903 115.34 34.9288 116.804C33.2673 118.268 30.8111 119 27.5604 119C26.1637 119 24.8634 118.916 23.6594 118.748C22.4795 118.58 21.3237 118.28 20.192 117.848V113.564C22.624 114.572 25.1885 115.076 27.8854 115.076C30.6546 115.076 32.0392 113.588 32.0392 110.612V110.216C32.0392 109.808 32.0513 109.388 32.0753 108.956C32.1235 108.524 32.1596 108.14 32.1837 107.804H32.0392C31.365 108.836 30.5583 109.58 29.6192 110.036C28.6801 110.492 27.6206 110.72 26.4407 110.72C24.1049 110.72 22.2749 109.832 20.9505 108.056C19.6502 106.256 19 103.76 19 100.568C19 97.352 19.6742 94.844 21.0227 93.044C22.3712 91.244 24.2253 90.344 26.5851 90.344ZM28.2828 94.7C25.7544 94.7 24.4902 96.68 24.4902 100.64C24.4902 104.552 25.7785 106.508 28.355 106.508C29.7276 106.508 30.7389 106.124 31.3891 105.356C32.0633 104.564 32.4004 103.196 32.4004 101.252V100.604C32.4004 98.492 32.0753 96.98 31.4252 96.068C30.775 95.156 29.7276 94.7 28.2828 94.7Z'
        fill='#595959'
      />
      <path
        d='M45.7579 83C46.5526 83 47.2388 83.192 47.8167 83.576C48.3947 83.936 48.6836 84.62 48.6836 85.628C48.6836 86.612 48.3947 87.296 47.8167 87.68C47.2388 88.064 46.5526 88.256 45.7579 88.256C44.9392 88.256 44.2409 88.064 43.663 87.68C43.1091 87.296 42.8322 86.612 42.8322 85.628C42.8322 84.62 43.1091 83.936 43.663 83.576C44.2409 83.192 44.9392 83 45.7579 83ZM48.4308 90.704V110.36H43.049V90.704H48.4308Z'
        fill='#595959'
      />
      <path
        d='M77.2069 90.344C79.4463 90.344 81.1319 90.92 82.2637 92.072C83.4195 93.2 83.9974 95.024 83.9974 97.544V110.36H78.6156V98.876C78.6156 96.044 77.6283 94.628 75.6538 94.628C74.233 94.628 73.2217 95.132 72.6197 96.14C72.0177 97.148 71.7167 98.6 71.7167 100.496V110.36H66.3349V98.876C66.3349 96.044 65.3476 94.628 63.3731 94.628C61.8801 94.628 60.8447 95.192 60.2668 96.32C59.7129 97.424 59.436 99.02 59.436 101.108V110.36H54.0542V90.704H58.1718L58.8942 93.224H59.1832C59.7852 92.216 60.6039 91.484 61.6393 91.028C62.6988 90.572 63.7945 90.344 64.9262 90.344C66.371 90.344 67.5991 90.584 68.6104 91.064C69.6218 91.52 70.3923 92.24 70.9221 93.224H71.3916C71.9936 92.216 72.8244 91.484 73.8839 91.028C74.9675 90.572 76.0752 90.344 77.2069 90.344Z'
        fill='#595959'
      />
      <path
        d='M112.692 90.344C114.931 90.344 116.617 90.92 117.748 92.072C118.904 93.2 119.482 95.024 119.482 97.544V110.36H114.1V98.876C114.1 96.044 113.113 94.628 111.139 94.628C109.718 94.628 108.706 95.132 108.104 96.14C107.502 97.148 107.201 98.6 107.201 100.496V110.36H101.82V98.876C101.82 96.044 100.832 94.628 98.8578 94.628C97.3649 94.628 96.3295 95.192 95.7515 96.32C95.1977 97.424 94.9208 99.02 94.9208 101.108V110.36H89.539V90.704H93.6566L94.379 93.224H94.668C95.27 92.216 96.0887 91.484 97.1241 91.028C98.1836 90.572 99.2792 90.344 100.411 90.344C101.856 90.344 103.084 90.584 104.095 91.064C105.107 91.52 105.877 92.24 106.407 93.224H106.876C107.478 92.216 108.309 91.484 109.369 91.028C110.452 90.572 111.56 90.344 112.692 90.344Z'
        fill='#595959'
      />
      <path
        d='M133.151 90.344C135.872 90.344 138.027 91.124 139.616 92.684C141.205 94.22 142 96.416 142 99.272V101.864H129.286C129.334 103.376 129.779 104.564 130.622 105.428C131.489 106.292 132.681 106.724 134.198 106.724C135.474 106.724 136.63 106.604 137.666 106.364C138.701 106.1 139.773 105.704 140.88 105.176V109.316C139.917 109.796 138.894 110.144 137.81 110.36C136.751 110.6 135.462 110.72 133.945 110.72C131.971 110.72 130.225 110.36 128.708 109.64C127.191 108.896 125.999 107.78 125.132 106.292C124.265 104.804 123.832 102.932 123.832 100.676C123.832 98.372 124.217 96.464 124.988 94.952C125.782 93.416 126.878 92.264 128.275 91.496C129.671 90.728 131.297 90.344 133.151 90.344ZM133.187 94.16C132.151 94.16 131.284 94.496 130.586 95.168C129.912 95.84 129.515 96.884 129.394 98.3H136.943C136.919 97.1 136.606 96.116 136.004 95.348C135.402 94.556 134.463 94.16 133.187 94.16Z'
        fill='#595959'
      />
      <path
        d='M55.0885 120C57.2643 120 58.8902 120.564 59.9661 121.691C61.0421 122.794 61.58 124.592 61.58 127.086V139.64H58.4598V127.23C58.4598 124.185 57.1567 122.662 54.5505 122.662C52.6856 122.662 51.3466 123.201 50.5337 124.281C49.7447 125.36 49.3501 126.93 49.3501 128.993V139.64H46.2299V127.23C46.2299 124.185 44.9149 122.662 42.2848 122.662C40.3481 122.662 39.0091 123.261 38.2679 124.46C37.5267 125.659 37.1561 127.386 37.1561 129.64V139.64H34V120.36H36.5464L37.0126 122.986H37.192C37.7897 121.978 38.5907 121.235 39.5949 120.755C40.623 120.252 41.7109 120 42.8586 120C45.8713 120 47.8319 121.079 48.7404 123.237H48.9198C49.5653 122.134 50.438 121.319 51.5379 120.791C52.6617 120.264 53.8452 120 55.0885 120Z'
        fill='#595959'
      />
      <path
        d='M74.9422 120C76.592 120 78.0027 120.36 79.1743 121.079C80.3698 121.799 81.2783 122.818 81.9 124.137C82.5456 125.432 82.8683 126.954 82.8683 128.705V130.612H69.706C69.7538 132.794 70.3037 134.46 71.3557 135.612C72.4317 136.739 73.926 137.302 75.8388 137.302C77.0582 137.302 78.1342 137.194 79.0667 136.978C80.0231 136.739 81.0034 136.403 82.0076 135.971V138.741C81.0273 139.173 80.0589 139.484 79.1025 139.676C78.1461 139.892 77.0104 140 75.6954 140C73.8782 140 72.2643 139.628 70.8536 138.885C69.4669 138.141 68.379 137.038 67.5899 135.576C66.8248 134.089 66.4423 132.278 66.4423 130.144C66.4423 128.034 66.7889 126.223 67.4823 124.712C68.1996 123.201 69.1919 122.038 70.4591 121.223C71.7503 120.408 73.2446 120 74.9422 120ZM74.9064 122.59C73.4 122.59 72.2045 123.082 71.3199 124.065C70.4591 125.024 69.9451 126.367 69.7777 128.094H79.5688C79.5449 126.463 79.1623 125.144 78.4211 124.137C77.6799 123.106 76.5083 122.59 74.9064 122.59Z'
        fill='#595959'
      />
      <path
        d='M97.0153 120C99.3107 120 101.044 120.564 102.216 121.691C103.387 122.794 103.973 124.592 103.973 127.086V139.64H100.853V127.302C100.853 124.209 99.4183 122.662 96.5491 122.662C94.4211 122.662 92.9507 123.261 92.1377 124.46C91.3248 125.659 90.9183 127.386 90.9183 129.64V139.64H87.7622V120.36H90.3086L90.7749 122.986H90.9542C91.5758 121.978 92.4366 121.235 93.5364 120.755C94.6363 120.252 95.7959 120 97.0153 120Z'
        fill='#595959'
      />
      <path
        d='M126 120.36V139.64H123.418L122.951 137.086H122.808C122.186 138.094 121.326 138.837 120.226 139.317C119.126 139.772 117.954 140 116.711 140C114.392 140 112.646 139.448 111.475 138.345C110.303 137.218 109.717 135.432 109.717 132.986V120.36H112.909V132.77C112.909 135.839 114.332 137.374 117.177 137.374C119.305 137.374 120.776 136.775 121.589 135.576C122.425 134.376 122.844 132.65 122.844 130.396V120.36H126Z'
        fill='#595959'
      />
    </svg>
  )
}

export default DefaultLogo
