import React from 'react'

const IconEyeVisible = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 22 16'
      fill='none'
    >
      <path
        d='M21.0822 7.39531C18.8603 2.71484 15.5017 0.359375 10.9994 0.359375C6.49472 0.359375 3.13847 2.71484 0.916591 7.39766C0.82747 7.58637 0.78125 7.79247 0.78125 8.00117C0.78125 8.20987 0.82747 8.41598 0.916591 8.60469C3.13847 13.2852 6.49706 15.6406 10.9994 15.6406C15.5041 15.6406 18.8603 13.2852 21.0822 8.60234C21.2627 8.22266 21.2627 7.78203 21.0822 7.39531ZM10.9994 13.9531C7.21893 13.9531 4.45097 12.0359 2.49862 8C4.45097 3.96406 7.21893 2.04688 10.9994 2.04688C14.7799 2.04688 17.5478 3.96406 19.5002 8C17.5502 12.0359 14.7822 13.9531 10.9994 13.9531ZM10.9057 3.875C8.62753 3.875 6.78065 5.72188 6.78065 8C6.78065 10.2781 8.62753 12.125 10.9057 12.125C13.1838 12.125 15.0307 10.2781 15.0307 8C15.0307 5.72188 13.1838 3.875 10.9057 3.875ZM10.9057 10.625C9.45487 10.625 8.28065 9.45078 8.28065 8C8.28065 6.54922 9.45487 5.375 10.9057 5.375C12.3564 5.375 13.5307 6.54922 13.5307 8C13.5307 9.45078 12.3564 10.625 10.9057 10.625Z'
        fill={color}
      />
    </svg>
  )
}

export default IconEyeVisible
