import React from 'react'

const IconMenuListPlaceholder = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 436 387'
      fill='none'
    >
      <mask
        id='path-1-outside-1'
        maskUnits='userSpaceOnUse'
        x='0.173828'
        y='-0.276367'
        width='436'
        height='388'
        fill='black'
      >
        <rect fill='white' x='0.173828' y='-0.276367' width='436' height='388' />
        <path d='M241.71 165.251L217.359 156.236C215.483 155.541 213.399 156.499 212.704 158.375C212.01 160.251 212.968 162.335 214.844 163.03L239.195 172.045C241.071 172.74 243.155 171.782 243.85 169.906C244.544 168.03 243.587 165.946 241.71 165.251Z' />
        <path d='M266.053 174.263L257.108 170.951C255.231 170.257 253.147 171.214 252.453 173.091C251.758 174.967 252.716 177.051 254.592 177.745L263.538 181.057C265.414 181.752 267.498 180.794 268.192 178.918C268.887 177.041 267.929 174.958 266.053 174.263Z' />
        <path d='M260.548 189.13L251.602 185.819C249.726 185.124 247.642 186.082 246.948 187.958C246.253 189.834 247.211 191.918 249.087 192.613L258.032 195.924C259.909 196.619 261.992 195.661 262.687 193.785C263.382 191.909 262.424 189.825 260.548 189.13Z' />
        <path d='M236.206 180.119L211.855 171.103C209.979 170.409 207.895 171.367 207.2 173.243C206.506 175.119 207.463 177.203 209.34 177.897L233.691 186.913C235.567 187.607 237.651 186.649 238.346 184.773C239.04 182.897 238.082 180.813 236.206 180.119Z' />
        <path d='M230.705 194.976L206.354 185.961C204.478 185.267 202.394 186.224 201.699 188.1C201.005 189.977 201.963 192.061 203.839 192.755L228.19 201.77C230.066 202.465 232.15 201.507 232.845 199.631C233.539 197.755 232.582 195.671 230.705 194.976Z' />
        <path d='M255.048 203.988L246.103 200.676C244.227 199.982 242.143 200.94 241.448 202.816C240.754 204.692 241.711 206.776 243.588 207.47L252.533 210.782C254.409 211.477 256.493 210.519 257.187 208.643C257.882 206.767 256.924 204.683 255.048 203.988Z' />
        <path d='M202.136 72.5933C206.123 68.7153 208.123 63.5861 207.766 58.1494L206.537 39.4971C206.42 37.7214 205.03 36.2933 203.258 36.1284L175.953 33.5855C174.181 33.4205 172.552 34.5667 172.109 36.2899L167.449 54.4069C166.098 59.6759 167.116 65.0801 170.317 69.6241C172.796 73.1428 176.35 75.8277 180.337 77.3036C180.873 77.5019 181.417 77.676 181.966 77.8296L180.722 91.1826L171.965 90.3681C169.973 90.1829 168.209 91.6475 168.023 93.6395C167.868 95.3078 168.87 96.8166 170.37 97.372C170.661 97.4797 170.971 97.5516 171.294 97.5818L196.019 99.8812C198.011 100.066 199.776 98.6018 199.961 96.6098C200.147 94.6177 198.682 92.8523 196.69 92.6674L187.935 91.8531L189.18 78.4986C193.977 78.0817 198.638 75.9946 202.136 72.5933ZM197.085 67.3993C194.249 70.1575 190.084 71.6456 186.217 71.2825C186.217 71.2824 186.216 71.2824 186.215 71.2823C186.215 71.2823 186.215 71.2821 186.214 71.2823C186.214 71.2822 186.213 71.2824 186.213 71.2822C182.342 70.9236 178.52 68.6894 176.24 65.4525C174.854 63.4846 173.421 60.2832 174.466 56.2091L178.356 41.0852L199.511 43.0559L200.538 58.6252C200.814 62.8337 198.812 65.7197 197.085 67.3993Z' />
        <path d='M132.585 136.591C132.951 138.723 134.12 140.585 135.879 141.832L141.177 145.585L138.264 149.696C137.327 151.01 136.94 152.642 137.205 154.175C137.45 155.597 138.22 156.823 139.352 157.613L161.1 173.028C161.465 173.284 161.859 173.489 162.272 173.642C163.296 174.021 164.436 174.077 165.541 173.784C166.891 173.427 168.09 172.582 168.906 171.419L192.475 138.156C193.632 136.524 193.246 134.263 191.615 133.106L166.881 115.573C166.096 115.017 165.124 114.796 164.177 114.958C163.23 115.119 162.386 115.651 161.83 116.435L160.348 118.527L155.053 114.774C153.292 113.528 151.147 113.04 149.014 113.402C146.877 113.765 145.012 114.937 143.766 116.699L133.952 130.55C132.705 132.309 132.219 134.454 132.585 136.591ZM149.186 146.818L165.646 123.579L184.47 136.922L163.815 166.072L144.992 152.731L149.144 146.871C149.158 146.853 149.173 146.837 149.186 146.818ZM139.862 134.739L149.678 120.885C149.843 120.653 150.067 120.572 150.227 120.545C150.343 120.524 150.502 120.52 150.671 120.583C150.736 120.607 150.801 120.64 150.867 120.686L156.16 124.438L145.366 139.673L140.068 135.92C139.698 135.659 139.602 135.107 139.862 134.739Z' />
        <path d='M317.11 53.8299L178.619 2.55771C171.691 -0.00697953 163.968 3.54232 161.403 10.4702L139.508 69.6122C138.813 71.4884 139.771 73.5723 141.647 74.2669C143.523 74.9615 145.607 74.0037 146.302 72.1275L168.197 12.9854C169.375 9.80413 172.922 8.17381 176.103 9.35171L241.957 33.7319L182.597 194.07L116.743 169.69C113.562 168.512 111.933 164.961 113.113 161.774L135.009 102.633C135.703 100.756 134.745 98.6726 132.869 97.978C130.993 97.2834 128.909 98.2412 128.215 100.117L106.319 159.259C103.752 166.192 107.3 173.92 114.228 176.484L252.719 227.757C259.652 230.323 267.381 226.771 269.948 219.838L325.032 71.0486C327.597 64.1207 324.043 56.3966 317.11 53.8299ZM248.751 36.2472L314.595 60.6239C317.782 61.8037 319.416 65.352 318.238 68.5333L305.037 104.192L233.413 77.6756L248.751 36.2472ZM302.521 110.986L283.027 163.642L211.404 137.126L230.898 84.47L302.521 110.986ZM255.235 220.962L189.391 196.586L208.888 143.92L280.512 170.436L263.154 217.322C261.974 220.509 258.421 222.142 255.235 220.962Z' />
        <path d='M302.731 65.4009L260.412 49.7337C256.782 48.3895 252.735 50.2498 251.391 53.8802L246.899 66.0129C245.555 69.6436 247.415 73.6908 251.046 75.0349L293.364 90.7021C296.995 92.0462 301.042 90.1859 302.386 86.5552L306.878 74.4225C308.222 70.7921 306.362 66.7449 302.731 65.4009ZM295.67 83.8303L253.77 68.3185L258.107 56.6053L300.006 72.1172L295.67 83.8303Z' />
        <path d='M276.602 118.295L285.547 121.607C287.424 122.301 289.507 121.343 290.202 119.467C290.897 117.591 289.939 115.507 288.063 114.813L279.117 111.501C277.241 110.806 275.157 111.764 274.463 113.64C273.768 115.516 274.726 117.6 276.602 118.295Z' />
        <path d='M236.854 103.58L261.205 112.595C263.081 113.29 265.165 112.332 265.86 110.456C266.554 108.579 265.597 106.496 263.721 105.801L239.369 96.7856C237.493 96.091 235.409 97.0488 234.715 98.925C234.02 100.801 234.978 102.885 236.854 103.58Z' />
        <path d='M271.101 133.153L280.047 136.465C281.923 137.159 284.007 136.202 284.701 134.325C285.396 132.449 284.438 130.365 282.562 129.671L273.617 126.359C271.741 125.664 269.657 126.622 268.962 128.498C268.268 130.375 269.225 132.458 271.101 133.153Z' />
        <path d='M231.354 118.438L255.705 127.453C257.582 128.147 259.665 127.19 260.36 125.314C261.055 123.437 260.097 121.353 258.221 120.659L233.869 111.644C231.993 110.949 229.909 111.907 229.215 113.783C228.52 115.659 229.478 117.743 231.354 118.438Z' />
        <path d='M225.85 133.305L250.201 142.32C252.077 143.015 254.161 142.057 254.856 140.181C255.55 138.305 254.593 136.221 252.717 135.526L228.365 126.511C226.489 125.816 224.405 126.774 223.71 128.65C223.016 130.526 223.974 132.61 225.85 133.305Z' />
        <path d='M265.598 148.02L274.543 151.332C276.419 152.027 278.503 151.069 279.198 149.193C279.892 147.317 278.934 145.233 277.058 144.538L268.113 141.226C266.237 140.532 264.153 141.49 263.458 143.366C262.764 145.242 263.721 147.326 265.598 148.02Z' />
        <path d='M135.998 89.529C137.874 90.2235 139.961 89.2562 140.656 87.3801C141.35 85.5039 140.393 83.42 138.517 82.7254C136.64 82.0309 134.556 82.9886 133.862 84.8648L133.855 84.8842C133.16 86.76 134.122 88.8344 135.998 89.529Z' />
        <path d='M22.0993 163.681L7.23867 175.087C0.449142 180.298 -0.834389 190.061 4.37669 196.85C9.28378 203.242 84.2332 300.881 89.2417 307.406C89.7752 308.101 90.5374 308.62 91.3166 308.872L125.914 320.101C127.255 320.537 128.725 320.284 129.844 319.426C130.964 318.566 131.587 317.211 131.513 315.804L129.609 279.483C129.564 278.643 129.254 277.783 128.729 277.1L43.8641 166.543C38.6407 159.738 28.9055 158.457 22.0993 163.681ZM122.633 310.032L101.705 303.239L121.482 288.061L122.633 310.032ZM119.327 278.917L93.4288 298.795L20.8507 204.245L46.7496 184.368L119.327 278.917ZM37.0701 171.758L41.5347 177.574L15.6358 197.452L11.1712 191.636C8.83531 188.593 9.41045 184.217 12.454 181.881L27.3147 170.475C30.3653 168.134 34.7283 168.708 37.0701 171.758Z' />
        <path d='M404.544 294.473L406.269 293.447C426.112 281.603 431.625 256.071 418.593 236.374C412.49 227.196 400.044 224.223 390.77 229.727L383.252 234.203C381.362 230.925 379.379 227.682 377.269 224.501C376.399 223.189 374.619 222.763 373.295 223.552L258.181 292.079C256.857 292.867 256.489 294.571 257.36 295.883C276.17 324.284 302.877 347.144 334.252 361.7L335.333 362.2L313.361 375.28C312.036 376.069 311.668 377.772 312.539 379.085C316.898 385.641 325.789 387.765 332.413 383.833L428.341 326.727C434.954 322.779 436.792 314.269 432.449 307.703C431.578 306.39 429.798 305.965 428.474 306.753L406.502 319.833L406.495 318.694C406.433 310.609 405.781 302.515 404.544 294.473ZM386.014 239.19L393.923 234.482C400.547 230.549 409.438 232.673 413.797 239.229C424.982 256.144 420.398 278.055 403.504 288.435C403.119 286.422 402.698 284.416 402.241 282.416C415.022 273.105 417.953 255.622 409.001 242.085C406.389 238.146 401.05 236.871 397.076 239.236L388.62 244.27C387.779 242.56 386.91 240.867 386.014 239.19ZM391.071 249.443L400.23 243.99C401.554 243.202 403.334 243.627 404.204 244.94C410.962 255.159 409.505 268.276 400.722 276.281C398.26 267.124 395.033 258.148 391.071 249.443ZM428.819 313.18C429.687 316.634 428.233 320.155 425.187 321.973L329.26 379.078C326.209 380.889 322.23 380.603 319.322 378.363L428.819 313.18ZM400.754 318.51L400.789 323.234L341.045 358.8L336.569 356.72C307.098 343.05 281.866 321.799 263.752 295.394L374.03 229.745C391.282 256.665 400.542 287.424 400.754 318.51Z' />
        <path d='M313.092 331.135L313.097 331.132C305.881 325.887 299.071 320.128 292.729 313.907C291.623 312.766 289.806 312.671 288.671 313.694C287.534 314.716 287.51 316.469 288.615 317.609C288.649 317.644 288.684 317.679 288.72 317.712C295.271 324.143 302.305 330.097 309.761 335.518C310.726 336.219 312.013 336.293 313.002 335.704C314.327 334.917 314.697 333.214 313.827 331.901C313.632 331.605 313.383 331.346 313.092 331.135Z' />
        <path d='M326.35 339.915C325.348 339.311 324.349 338.697 323.356 338.073C322.021 337.196 320.246 337.502 319.391 338.758C318.537 340.013 318.926 341.742 320.262 342.619C320.296 342.642 320.331 342.664 320.366 342.685C321.389 343.329 322.419 343.963 323.456 344.585C324.843 345.382 326.59 344.967 327.358 343.659C328.102 342.392 327.658 340.742 326.35 339.915Z' />
      </mask>
      <path
        d='M241.71 165.251L217.359 156.236C215.483 155.541 213.399 156.499 212.704 158.375C212.01 160.251 212.968 162.335 214.844 163.03L239.195 172.045C241.071 172.74 243.155 171.782 243.85 169.906C244.544 168.03 243.587 165.946 241.71 165.251Z'
        fill={color}
      />
      <path
        d='M266.053 174.263L257.108 170.951C255.231 170.257 253.147 171.214 252.453 173.091C251.758 174.967 252.716 177.051 254.592 177.745L263.538 181.057C265.414 181.752 267.498 180.794 268.192 178.918C268.887 177.041 267.929 174.958 266.053 174.263Z'
        fill={color}
      />
      <path
        d='M260.548 189.13L251.602 185.819C249.726 185.124 247.642 186.082 246.948 187.958C246.253 189.834 247.211 191.918 249.087 192.613L258.032 195.924C259.909 196.619 261.992 195.661 262.687 193.785C263.382 191.909 262.424 189.825 260.548 189.13Z'
        fill={color}
      />
      <path
        d='M236.206 180.119L211.855 171.103C209.979 170.409 207.895 171.367 207.2 173.243C206.506 175.119 207.463 177.203 209.34 177.897L233.691 186.913C235.567 187.607 237.651 186.649 238.346 184.773C239.04 182.897 238.082 180.813 236.206 180.119Z'
        fill={color}
      />
      <path
        d='M230.705 194.976L206.354 185.961C204.478 185.267 202.394 186.224 201.699 188.1C201.005 189.977 201.963 192.061 203.839 192.755L228.19 201.77C230.066 202.465 232.15 201.507 232.845 199.631C233.539 197.755 232.582 195.671 230.705 194.976Z'
        fill={color}
      />
      <path
        d='M255.048 203.988L246.103 200.676C244.227 199.982 242.143 200.94 241.448 202.816C240.754 204.692 241.711 206.776 243.588 207.47L252.533 210.782C254.409 211.477 256.493 210.519 257.187 208.643C257.882 206.767 256.924 204.683 255.048 203.988Z'
        fill={color}
      />
      <path
        d='M202.136 72.5933C206.123 68.7153 208.123 63.5861 207.766 58.1494L206.537 39.4971C206.42 37.7214 205.03 36.2933 203.258 36.1284L175.953 33.5855C174.181 33.4205 172.552 34.5667 172.109 36.2899L167.449 54.4069C166.098 59.6759 167.116 65.0801 170.317 69.6241C172.796 73.1428 176.35 75.8277 180.337 77.3036C180.873 77.5019 181.417 77.676 181.966 77.8296L180.722 91.1826L171.965 90.3681C169.973 90.1829 168.209 91.6475 168.023 93.6395C167.868 95.3078 168.87 96.8166 170.37 97.372C170.661 97.4797 170.971 97.5516 171.294 97.5818L196.019 99.8812C198.011 100.066 199.776 98.6018 199.961 96.6098C200.147 94.6177 198.682 92.8523 196.69 92.6674L187.935 91.8531L189.18 78.4986C193.977 78.0817 198.638 75.9946 202.136 72.5933ZM197.085 67.3993C194.249 70.1575 190.084 71.6456 186.217 71.2825C186.217 71.2824 186.216 71.2824 186.215 71.2823C186.215 71.2823 186.215 71.2821 186.214 71.2823C186.214 71.2822 186.213 71.2824 186.213 71.2822C182.342 70.9236 178.52 68.6894 176.24 65.4525C174.854 63.4846 173.421 60.2832 174.466 56.2091L178.356 41.0852L199.511 43.0559L200.538 58.6252C200.814 62.8337 198.812 65.7197 197.085 67.3993Z'
        fill={color}
      />
      <path
        d='M132.585 136.591C132.951 138.723 134.12 140.585 135.879 141.832L141.177 145.585L138.264 149.696C137.327 151.01 136.94 152.642 137.205 154.175C137.45 155.597 138.22 156.823 139.352 157.613L161.1 173.028C161.465 173.284 161.859 173.489 162.272 173.642C163.296 174.021 164.436 174.077 165.541 173.784C166.891 173.427 168.09 172.582 168.906 171.419L192.475 138.156C193.632 136.524 193.246 134.263 191.615 133.106L166.881 115.573C166.096 115.017 165.124 114.796 164.177 114.958C163.23 115.119 162.386 115.651 161.83 116.435L160.348 118.527L155.053 114.774C153.292 113.528 151.147 113.04 149.014 113.402C146.877 113.765 145.012 114.937 143.766 116.699L133.952 130.55C132.705 132.309 132.219 134.454 132.585 136.591ZM149.186 146.818L165.646 123.579L184.47 136.922L163.815 166.072L144.992 152.731L149.144 146.871C149.158 146.853 149.173 146.837 149.186 146.818ZM139.862 134.739L149.678 120.885C149.843 120.653 150.067 120.572 150.227 120.545C150.343 120.524 150.502 120.52 150.671 120.583C150.736 120.607 150.801 120.64 150.867 120.686L156.16 124.438L145.366 139.673L140.068 135.92C139.698 135.659 139.602 135.107 139.862 134.739Z'
        fill={color}
      />
      <path
        d='M317.11 53.8299L178.619 2.55771C171.691 -0.00697953 163.968 3.54232 161.403 10.4702L139.508 69.6122C138.813 71.4884 139.771 73.5723 141.647 74.2669C143.523 74.9615 145.607 74.0037 146.302 72.1275L168.197 12.9854C169.375 9.80413 172.922 8.17381 176.103 9.35171L241.957 33.7319L182.597 194.07L116.743 169.69C113.562 168.512 111.933 164.961 113.113 161.774L135.009 102.633C135.703 100.756 134.745 98.6726 132.869 97.978C130.993 97.2834 128.909 98.2412 128.215 100.117L106.319 159.259C103.752 166.192 107.3 173.92 114.228 176.484L252.719 227.757C259.652 230.323 267.381 226.771 269.948 219.838L325.032 71.0486C327.597 64.1207 324.043 56.3966 317.11 53.8299ZM248.751 36.2472L314.595 60.6239C317.782 61.8037 319.416 65.352 318.238 68.5333L305.037 104.192L233.413 77.6756L248.751 36.2472ZM302.521 110.986L283.027 163.642L211.404 137.126L230.898 84.47L302.521 110.986ZM255.235 220.962L189.391 196.586L208.888 143.92L280.512 170.436L263.154 217.322C261.974 220.509 258.421 222.142 255.235 220.962Z'
        fill={color}
      />
      <path
        d='M302.731 65.4009L260.412 49.7337C256.782 48.3895 252.735 50.2498 251.391 53.8802L246.899 66.0129C245.555 69.6436 247.415 73.6908 251.046 75.0349L293.364 90.7021C296.995 92.0462 301.042 90.1859 302.386 86.5552L306.878 74.4225C308.222 70.7921 306.362 66.7449 302.731 65.4009ZM295.67 83.8303L253.77 68.3185L258.107 56.6053L300.006 72.1172L295.67 83.8303Z'
        fill={color}
      />
      <path
        d='M276.602 118.295L285.547 121.607C287.424 122.301 289.507 121.343 290.202 119.467C290.897 117.591 289.939 115.507 288.063 114.813L279.117 111.501C277.241 110.806 275.157 111.764 274.463 113.64C273.768 115.516 274.726 117.6 276.602 118.295Z'
        fill={color}
      />
      <path
        d='M236.854 103.58L261.205 112.595C263.081 113.29 265.165 112.332 265.86 110.456C266.554 108.579 265.597 106.496 263.721 105.801L239.369 96.7856C237.493 96.091 235.409 97.0488 234.715 98.925C234.02 100.801 234.978 102.885 236.854 103.58Z'
        fill={color}
      />
      <path
        d='M271.101 133.153L280.047 136.465C281.923 137.159 284.007 136.202 284.701 134.325C285.396 132.449 284.438 130.365 282.562 129.671L273.617 126.359C271.741 125.664 269.657 126.622 268.962 128.498C268.268 130.375 269.225 132.458 271.101 133.153Z'
        fill={color}
      />
      <path
        d='M231.354 118.438L255.705 127.453C257.582 128.147 259.665 127.19 260.36 125.314C261.055 123.437 260.097 121.353 258.221 120.659L233.869 111.644C231.993 110.949 229.909 111.907 229.215 113.783C228.52 115.659 229.478 117.743 231.354 118.438Z'
        fill={color}
      />
      <path
        d='M225.85 133.305L250.201 142.32C252.077 143.015 254.161 142.057 254.856 140.181C255.55 138.305 254.593 136.221 252.717 135.526L228.365 126.511C226.489 125.816 224.405 126.774 223.71 128.65C223.016 130.526 223.974 132.61 225.85 133.305Z'
        fill={color}
      />
      <path
        d='M265.598 148.02L274.543 151.332C276.419 152.027 278.503 151.069 279.198 149.193C279.892 147.317 278.934 145.233 277.058 144.538L268.113 141.226C266.237 140.532 264.153 141.49 263.458 143.366C262.764 145.242 263.721 147.326 265.598 148.02Z'
        fill={color}
      />
      <path
        d='M135.998 89.529C137.874 90.2235 139.961 89.2562 140.656 87.3801C141.35 85.5039 140.393 83.42 138.517 82.7254C136.64 82.0309 134.556 82.9886 133.862 84.8648L133.855 84.8842C133.16 86.76 134.122 88.8344 135.998 89.529Z'
        fill={color}
      />
      <path
        d='M22.0993 163.681L7.23867 175.087C0.449142 180.298 -0.834389 190.061 4.37669 196.85C9.28378 203.242 84.2332 300.881 89.2417 307.406C89.7752 308.101 90.5374 308.62 91.3166 308.872L125.914 320.101C127.255 320.537 128.725 320.284 129.844 319.426C130.964 318.566 131.587 317.211 131.513 315.804L129.609 279.483C129.564 278.643 129.254 277.783 128.729 277.1L43.8641 166.543C38.6407 159.738 28.9055 158.457 22.0993 163.681ZM122.633 310.032L101.705 303.239L121.482 288.061L122.633 310.032ZM119.327 278.917L93.4288 298.795L20.8507 204.245L46.7496 184.368L119.327 278.917ZM37.0701 171.758L41.5347 177.574L15.6358 197.452L11.1712 191.636C8.83531 188.593 9.41045 184.217 12.454 181.881L27.3147 170.475C30.3653 168.134 34.7283 168.708 37.0701 171.758Z'
        fill={color}
      />
      <path
        d='M404.544 294.473L406.269 293.447C426.112 281.603 431.625 256.071 418.593 236.374C412.49 227.196 400.044 224.223 390.77 229.727L383.252 234.203C381.362 230.925 379.379 227.682 377.269 224.501C376.399 223.189 374.619 222.763 373.295 223.552L258.181 292.079C256.857 292.867 256.489 294.571 257.36 295.883C276.17 324.284 302.877 347.144 334.252 361.7L335.333 362.2L313.361 375.28C312.036 376.069 311.668 377.772 312.539 379.085C316.898 385.641 325.789 387.765 332.413 383.833L428.341 326.727C434.954 322.779 436.792 314.269 432.449 307.703C431.578 306.39 429.798 305.965 428.474 306.753L406.502 319.833L406.495 318.694C406.433 310.609 405.781 302.515 404.544 294.473ZM386.014 239.19L393.923 234.482C400.547 230.549 409.438 232.673 413.797 239.229C424.982 256.144 420.398 278.055 403.504 288.435C403.119 286.422 402.698 284.416 402.241 282.416C415.022 273.105 417.953 255.622 409.001 242.085C406.389 238.146 401.05 236.871 397.076 239.236L388.62 244.27C387.779 242.56 386.91 240.867 386.014 239.19ZM391.071 249.443L400.23 243.99C401.554 243.202 403.334 243.627 404.204 244.94C410.962 255.159 409.505 268.276 400.722 276.281C398.26 267.124 395.033 258.148 391.071 249.443ZM428.819 313.18C429.687 316.634 428.233 320.155 425.187 321.973L329.26 379.078C326.209 380.889 322.23 380.603 319.322 378.363L428.819 313.18ZM400.754 318.51L400.789 323.234L341.045 358.8L336.569 356.72C307.098 343.05 281.866 321.799 263.752 295.394L374.03 229.745C391.282 256.665 400.542 287.424 400.754 318.51Z'
        fill={color}
      />
      <path
        d='M313.092 331.135L313.097 331.132C305.881 325.887 299.071 320.128 292.729 313.907C291.623 312.766 289.806 312.671 288.671 313.694C287.534 314.716 287.51 316.469 288.615 317.609C288.649 317.644 288.684 317.679 288.72 317.712C295.271 324.143 302.305 330.097 309.761 335.518C310.726 336.219 312.013 336.293 313.002 335.704C314.327 334.917 314.697 333.214 313.827 331.901C313.632 331.605 313.383 331.346 313.092 331.135Z'
        fill={color}
      />
      <path
        d='M326.35 339.915C325.348 339.311 324.349 338.697 323.356 338.073C322.021 337.196 320.246 337.502 319.391 338.758C318.537 340.013 318.926 341.742 320.262 342.619C320.296 342.642 320.331 342.664 320.366 342.685C321.389 343.329 322.419 343.963 323.456 344.585C324.843 345.382 326.59 344.967 327.358 343.659C328.102 342.392 327.658 340.742 326.35 339.915Z'
        fill={color}
      />
      <path
        d='M241.71 165.251L217.359 156.236C215.483 155.541 213.399 156.499 212.704 158.375C212.01 160.251 212.968 162.335 214.844 163.03L239.195 172.045C241.071 172.74 243.155 171.782 243.85 169.906C244.544 168.03 243.587 165.946 241.71 165.251Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M266.053 174.263L257.108 170.951C255.231 170.257 253.147 171.214 252.453 173.091C251.758 174.967 252.716 177.051 254.592 177.745L263.538 181.057C265.414 181.752 267.498 180.794 268.192 178.918C268.887 177.041 267.929 174.958 266.053 174.263Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M260.548 189.13L251.602 185.819C249.726 185.124 247.642 186.082 246.948 187.958C246.253 189.834 247.211 191.918 249.087 192.613L258.032 195.924C259.909 196.619 261.992 195.661 262.687 193.785C263.382 191.909 262.424 189.825 260.548 189.13Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M236.206 180.119L211.855 171.103C209.979 170.409 207.895 171.367 207.2 173.243C206.506 175.119 207.463 177.203 209.34 177.897L233.691 186.913C235.567 187.607 237.651 186.649 238.346 184.773C239.04 182.897 238.082 180.813 236.206 180.119Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M230.705 194.976L206.354 185.961C204.478 185.267 202.394 186.224 201.699 188.1C201.005 189.977 201.963 192.061 203.839 192.755L228.19 201.77C230.066 202.465 232.15 201.507 232.845 199.631C233.539 197.755 232.582 195.671 230.705 194.976Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M255.048 203.988L246.103 200.676C244.227 199.982 242.143 200.94 241.448 202.816C240.754 204.692 241.711 206.776 243.588 207.47L252.533 210.782C254.409 211.477 256.493 210.519 257.187 208.643C257.882 206.767 256.924 204.683 255.048 203.988Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M202.136 72.5933C206.123 68.7153 208.123 63.5861 207.766 58.1494L206.537 39.4971C206.42 37.7214 205.03 36.2933 203.258 36.1284L175.953 33.5855C174.181 33.4205 172.552 34.5667 172.109 36.2899L167.449 54.4069C166.098 59.6759 167.116 65.0801 170.317 69.6241C172.796 73.1428 176.35 75.8277 180.337 77.3036C180.873 77.5019 181.417 77.676 181.966 77.8296L180.722 91.1826L171.965 90.3681C169.973 90.1829 168.209 91.6475 168.023 93.6395C167.868 95.3078 168.87 96.8166 170.37 97.372C170.661 97.4797 170.971 97.5516 171.294 97.5818L196.019 99.8812C198.011 100.066 199.776 98.6018 199.961 96.6098C200.147 94.6177 198.682 92.8523 196.69 92.6674L187.935 91.8531L189.18 78.4986C193.977 78.0817 198.638 75.9946 202.136 72.5933ZM197.085 67.3993C194.249 70.1575 190.084 71.6456 186.217 71.2825C186.217 71.2824 186.216 71.2824 186.215 71.2823C186.215 71.2823 186.215 71.2821 186.214 71.2823C186.214 71.2822 186.213 71.2824 186.213 71.2822C182.342 70.9236 178.52 68.6894 176.24 65.4525C174.854 63.4846 173.421 60.2832 174.466 56.2091L178.356 41.0852L199.511 43.0559L200.538 58.6252C200.814 62.8337 198.812 65.7197 197.085 67.3993Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M132.585 136.591C132.951 138.723 134.12 140.585 135.879 141.832L141.177 145.585L138.264 149.696C137.327 151.01 136.94 152.642 137.205 154.175C137.45 155.597 138.22 156.823 139.352 157.613L161.1 173.028C161.465 173.284 161.859 173.489 162.272 173.642C163.296 174.021 164.436 174.077 165.541 173.784C166.891 173.427 168.09 172.582 168.906 171.419L192.475 138.156C193.632 136.524 193.246 134.263 191.615 133.106L166.881 115.573C166.096 115.017 165.124 114.796 164.177 114.958C163.23 115.119 162.386 115.651 161.83 116.435L160.348 118.527L155.053 114.774C153.292 113.528 151.147 113.04 149.014 113.402C146.877 113.765 145.012 114.937 143.766 116.699L133.952 130.55C132.705 132.309 132.219 134.454 132.585 136.591ZM149.186 146.818L165.646 123.579L184.47 136.922L163.815 166.072L144.992 152.731L149.144 146.871C149.158 146.853 149.173 146.837 149.186 146.818ZM139.862 134.739L149.678 120.885C149.843 120.653 150.067 120.572 150.227 120.545C150.343 120.524 150.502 120.52 150.671 120.583C150.736 120.607 150.801 120.64 150.867 120.686L156.16 124.438L145.366 139.673L140.068 135.92C139.698 135.659 139.602 135.107 139.862 134.739Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M317.11 53.8299L178.619 2.55771C171.691 -0.00697953 163.968 3.54232 161.403 10.4702L139.508 69.6122C138.813 71.4884 139.771 73.5723 141.647 74.2669C143.523 74.9615 145.607 74.0037 146.302 72.1275L168.197 12.9854C169.375 9.80413 172.922 8.17381 176.103 9.35171L241.957 33.7319L182.597 194.07L116.743 169.69C113.562 168.512 111.933 164.961 113.113 161.774L135.009 102.633C135.703 100.756 134.745 98.6726 132.869 97.978C130.993 97.2834 128.909 98.2412 128.215 100.117L106.319 159.259C103.752 166.192 107.3 173.92 114.228 176.484L252.719 227.757C259.652 230.323 267.381 226.771 269.948 219.838L325.032 71.0486C327.597 64.1207 324.043 56.3966 317.11 53.8299ZM248.751 36.2472L314.595 60.6239C317.782 61.8037 319.416 65.352 318.238 68.5333L305.037 104.192L233.413 77.6756L248.751 36.2472ZM302.521 110.986L283.027 163.642L211.404 137.126L230.898 84.47L302.521 110.986ZM255.235 220.962L189.391 196.586L208.888 143.92L280.512 170.436L263.154 217.322C261.974 220.509 258.421 222.142 255.235 220.962Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M302.731 65.4009L260.412 49.7337C256.782 48.3895 252.735 50.2498 251.391 53.8802L246.899 66.0129C245.555 69.6436 247.415 73.6908 251.046 75.0349L293.364 90.7021C296.995 92.0462 301.042 90.1859 302.386 86.5552L306.878 74.4225C308.222 70.7921 306.362 66.7449 302.731 65.4009ZM295.67 83.8303L253.77 68.3185L258.107 56.6053L300.006 72.1172L295.67 83.8303Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M276.602 118.295L285.547 121.607C287.424 122.301 289.507 121.343 290.202 119.467C290.897 117.591 289.939 115.507 288.063 114.813L279.117 111.501C277.241 110.806 275.157 111.764 274.463 113.64C273.768 115.516 274.726 117.6 276.602 118.295Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M236.854 103.58L261.205 112.595C263.081 113.29 265.165 112.332 265.86 110.456C266.554 108.579 265.597 106.496 263.721 105.801L239.369 96.7856C237.493 96.091 235.409 97.0488 234.715 98.925C234.02 100.801 234.978 102.885 236.854 103.58Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M271.101 133.153L280.047 136.465C281.923 137.159 284.007 136.202 284.701 134.325C285.396 132.449 284.438 130.365 282.562 129.671L273.617 126.359C271.741 125.664 269.657 126.622 268.962 128.498C268.268 130.375 269.225 132.458 271.101 133.153Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M231.354 118.438L255.705 127.453C257.582 128.147 259.665 127.19 260.36 125.314C261.055 123.437 260.097 121.353 258.221 120.659L233.869 111.644C231.993 110.949 229.909 111.907 229.215 113.783C228.52 115.659 229.478 117.743 231.354 118.438Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M225.85 133.305L250.201 142.32C252.077 143.015 254.161 142.057 254.856 140.181C255.55 138.305 254.593 136.221 252.717 135.526L228.365 126.511C226.489 125.816 224.405 126.774 223.71 128.65C223.016 130.526 223.974 132.61 225.85 133.305Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M265.598 148.02L274.543 151.332C276.419 152.027 278.503 151.069 279.198 149.193C279.892 147.317 278.934 145.233 277.058 144.538L268.113 141.226C266.237 140.532 264.153 141.49 263.458 143.366C262.764 145.242 263.721 147.326 265.598 148.02Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M135.998 89.529C137.874 90.2235 139.961 89.2562 140.656 87.3801C141.35 85.5039 140.393 83.42 138.517 82.7254C136.64 82.0309 134.556 82.9886 133.862 84.8648L133.855 84.8842C133.16 86.76 134.122 88.8344 135.998 89.529Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M22.0993 163.681L7.23867 175.087C0.449142 180.298 -0.834389 190.061 4.37669 196.85C9.28378 203.242 84.2332 300.881 89.2417 307.406C89.7752 308.101 90.5374 308.62 91.3166 308.872L125.914 320.101C127.255 320.537 128.725 320.284 129.844 319.426C130.964 318.566 131.587 317.211 131.513 315.804L129.609 279.483C129.564 278.643 129.254 277.783 128.729 277.1L43.8641 166.543C38.6407 159.738 28.9055 158.457 22.0993 163.681ZM122.633 310.032L101.705 303.239L121.482 288.061L122.633 310.032ZM119.327 278.917L93.4288 298.795L20.8507 204.245L46.7496 184.368L119.327 278.917ZM37.0701 171.758L41.5347 177.574L15.6358 197.452L11.1712 191.636C8.83531 188.593 9.41045 184.217 12.454 181.881L27.3147 170.475C30.3653 168.134 34.7283 168.708 37.0701 171.758Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M404.544 294.473L406.269 293.447C426.112 281.603 431.625 256.071 418.593 236.374C412.49 227.196 400.044 224.223 390.77 229.727L383.252 234.203C381.362 230.925 379.379 227.682 377.269 224.501C376.399 223.189 374.619 222.763 373.295 223.552L258.181 292.079C256.857 292.867 256.489 294.571 257.36 295.883C276.17 324.284 302.877 347.144 334.252 361.7L335.333 362.2L313.361 375.28C312.036 376.069 311.668 377.772 312.539 379.085C316.898 385.641 325.789 387.765 332.413 383.833L428.341 326.727C434.954 322.779 436.792 314.269 432.449 307.703C431.578 306.39 429.798 305.965 428.474 306.753L406.502 319.833L406.495 318.694C406.433 310.609 405.781 302.515 404.544 294.473ZM386.014 239.19L393.923 234.482C400.547 230.549 409.438 232.673 413.797 239.229C424.982 256.144 420.398 278.055 403.504 288.435C403.119 286.422 402.698 284.416 402.241 282.416C415.022 273.105 417.953 255.622 409.001 242.085C406.389 238.146 401.05 236.871 397.076 239.236L388.62 244.27C387.779 242.56 386.91 240.867 386.014 239.19ZM391.071 249.443L400.23 243.99C401.554 243.202 403.334 243.627 404.204 244.94C410.962 255.159 409.505 268.276 400.722 276.281C398.26 267.124 395.033 258.148 391.071 249.443ZM428.819 313.18C429.687 316.634 428.233 320.155 425.187 321.973L329.26 379.078C326.209 380.889 322.23 380.603 319.322 378.363L428.819 313.18ZM400.754 318.51L400.789 323.234L341.045 358.8L336.569 356.72C307.098 343.05 281.866 321.799 263.752 295.394L374.03 229.745C391.282 256.665 400.542 287.424 400.754 318.51Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M313.092 331.135L313.097 331.132C305.881 325.887 299.071 320.128 292.729 313.907C291.623 312.766 289.806 312.671 288.671 313.694C287.534 314.716 287.51 316.469 288.615 317.609C288.649 317.644 288.684 317.679 288.72 317.712C295.271 324.143 302.305 330.097 309.761 335.518C310.726 336.219 312.013 336.293 313.002 335.704C314.327 334.917 314.697 333.214 313.827 331.901C313.632 331.605 313.383 331.346 313.092 331.135Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
      <path
        d='M326.35 339.915C325.348 339.311 324.349 338.697 323.356 338.073C322.021 337.196 320.246 337.502 319.391 338.758C318.537 340.013 318.926 341.742 320.262 342.619C320.296 342.642 320.331 342.664 320.366 342.685C321.389 343.329 322.419 343.963 323.456 344.585C324.843 345.382 326.59 344.967 327.358 343.659C328.102 342.392 327.658 340.742 326.35 339.915Z'
        stroke={color}
        strokeWidth='2'
        mask='url(#path-1-outside-1)'
      />
    </svg>
  )
}

export default IconMenuListPlaceholder
