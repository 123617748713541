import React from 'react'
import { useTranslation } from 'react-i18next'
import icons from '../img/icons/index'

const EmptyMenuBlock = () => {
  const { t } = useTranslation()
  const { IconMenuListPlaceholder } = icons

  return (
    <section className='menu-list-empty'>
      <h3>{t('MANAGER_MENU_LIST_EMPTY_TITLE')}</h3>
      <div className='placeholder'>
        <IconMenuListPlaceholder color='lightgray' width='100%' />
      </div>
    </section>
  )
}

export default EmptyMenuBlock
