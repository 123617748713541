import React from 'react'
import { useTranslation } from 'react-i18next'

const V2UpdatingMenu = () => {
  const { t } = useTranslation()
  return (
    <section className='v2-section v2-updating-menu'>
      <div className='inner'>
        <h3>{t('HOME_UPDATING_TITLE_TEXT')}</h3>
        <p>{t('HOME_UPDATING_TEXT')}</p>
        {/* <div className='gray-box'>
          product UI screens or
          <br />
          illustration
        </div> */}
      </div>
    </section>
  )
}

export default V2UpdatingMenu
