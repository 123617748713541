import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { findTargetById } from '../helpers'
import { ntfWarn } from '../helpers/notifications'
import { validateItemDescription } from '../validation/validate'
import { placeholders } from '../data'
import {
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
const { REACT_APP_DEBOUNCE_TIMOUT } = process.env

const ItemDescriptionDebouncedInput = ({
  incomingValue,
  incomingId,
  incomingIndex,
  incomingParentIndex,
  incomingGrandParentIndex,
}) => {
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { currentLanguage } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const { t } = useTranslation()

  const [nameValue, setNameValue] = useState(incomingValue)

  useEffect(() => {
    setNameValue(
      menuLocals.find((langObj) => langObj.lang === currentLanguage)?.subItems?.[
        incomingGrandParentIndex
      ]?.subItems?.[incomingParentIndex]?.subItems?.[incomingIndex]?.description || ''
    )
  }, [incomingValue, menuLocals, currentLanguage])

  const handleItemDescriptionChange = (value, targetID) => {
    const newData = menuLocals.map((menu, m) => {
      const langTargetItem = findTargetById(menu, targetID)
      if (langTargetItem && menu.lang === currentLanguage) {
        langTargetItem.description = value
      }
      return menu
    })
    setMenuLocals(newData)

    // prevItemProps.description = value
    // setPrevItemProps({ ...prevItemProps })
    setIsThereUnsavedChanges(true)
  }

  const debouncedHandleItemDescriptionChange = useDebouncedCallback(
    handleItemDescriptionChange,
    REACT_APP_DEBOUNCE_TIMOUT
  )

  return (
    <textarea
      id={`${incomingId}-description`}
      rows={5}
      minLength={0}
      name='description'
      value={nameValue}
      disabled={isLocalsLoading}
      placeholder={placeholders.description}
      onChange={(e) => {
        const value = e.target.value
        const error = validateItemDescription(value).error
        if (!error) {
          setNameValue(value)
          debouncedHandleItemDescriptionChange(value, incomingId)
        } else {
          ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_300_CHARACTERS_MAX') })
        }
      }}
    />
  )
}
export default ItemDescriptionDebouncedInput
