import React, { useEffect, useRef } from 'react'
import V2Hero from '../V2Hero'
import V2HowItWorks from '../V2HowItWorks'
// import V2Gallery from '../V2Gallery'
import V2SeeItLive from '../V2SeeItLive'
import V2UpdatingMenu from '../V2UpdatingMenu'
import V2Faq from '../V2Faq'
import V2ContactForm from '../V2ContactForm'

const V2HomePage = () => {
  const helpRef = useRef(null)
  const faqRef = useRef(null)

  const scrollToHash = () => {
    const hashArray2 = [
      { hash: '#help', ref: helpRef },
      { hash: '#faq', ref: faqRef },
    ]
    const getRef = (hash) => {
      let res = null
      hashArray2.forEach((hashItem) => {
        if (hashItem.hash === hash) {
          res = hashItem.ref
        }
      })
      return res
    }

    if (window.location.hash.length) {
      const currentRef = getRef(window.location.hash)
      if (currentRef) {
        currentRef.current.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }

  useEffect(() => {
    scrollToHash()
  }, [])

  return (
    <main className='v2-home-page-container'>
      <V2Hero />
      <V2HowItWorks />
      {/* <V2Gallery /> */}
      <V2SeeItLive />
      <V2UpdatingMenu />
      <V2Faq ref={faqRef} />
      <V2ContactForm ref={helpRef} />
    </main>
  )
}

export default V2HomePage
