import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import { useIsDesktop } from '../../helpers/hooks'
import LogoSvg from '../../img/v2-logo.svg'
import PozLogoSvg from '../../img/v2-poz-logo.svg'

const { REACT_APP_BASE_URL } = process.env

const V2Footer = ({ history }) => {
  const { user, isAuthenticated, logout, loginWithRedirect } = useAuth0()
  const { t, i18n } = useTranslation()
  const isDesktop = useIsDesktop(767)

  const menuItemsArr2 = [
    { name: t('FOOTER_COMPANY'), route: '/company#top' },
    { name: t('FOOTER_TERMS'), route: '/terms#top' },
    { name: t('FOOTER_HELP'), route: `/#help` },
    { name: t('FOOTER_PRIVACY'), route: '/privacy-policy#top' },
  ]

  function handleChangeLanguage() {
    const _select = document.getElementById('footerLanguageSelect')
    i18n.changeLanguage(_select.value)
  }

  const getLanguageSelect = () => {
    return (
      <select
        id='footerLanguageSelect'
        defaultValue={i18n ? i18n.language : 'en'}
        className='v2-button v2-button-light lang-selector'
        onChange={handleChangeLanguage}
      >
        <option value='en'>Eng</option>
        <option value='ru'>Rus</option>
        <option value='uk'>Ukr</option>
      </select>
    )
  }

  const isUserCanUseSummary = useMemo(() => {
    if (user && isAuthenticated) {
      const userRolesArray = user[`${REACT_APP_BASE_URL}/roles`]
      return userRolesArray?.includes('admin')
    } else return false
  }, [user, isAuthenticated])

  return (
    <footer className='v2-section v2-footer'>
      <div className='inner'>
        <div className='top-row'>
          <div className='menu-container'>
            <a className='logo-link' href='/'>
              <img
                className='logo-image'
                src={LogoSvg}
                alt='logo'
                onClick={() => history.push('/')}
              />
            </a>
            {!isDesktop && (
              <div className='menu'>
                {isUserCanUseSummary && (
                  <div
                    onClick={() => history.push('/summary')}
                    className='v2-button v2-button-light'
                    id='summary-link'
                  >
                    {t('HEADER_SUMMARY_BUTTON_TEXT')}
                  </div>
                )}
                {isAuthenticated ? (
                  <>
                    <div
                      onClick={() => history.push('/my')}
                      className='v2-button v2-button-light'
                      id='my-menus-link'
                    >
                      {t('HEADER_MANAGER_BUTTON_TEXT')}
                    </div>
                    <div
                      className='v2-button v2-button-light'
                      onClick={() => {
                        logout({ returnTo: window.location.origin })
                      }}
                    >
                      {t('HEADER_LOGOUT_BUTTON_TEXT')}
                    </div>
                  </>
                ) : (
                  <div
                    className='v2-button v2-button-light'
                    onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
                  >
                    {t('HEADER_LOGIN_BUTTON_TEXT')}
                  </div>
                )}
                {getLanguageSelect()}
              </div>
            )}
          </div>
          <div className='footer-menu'>
            {menuItemsArr2.map((menuItem, i) => (
              <Link smooth className='footer-link' key={i} to={menuItem.route}>
                <span>{menuItem.name}</span>
              </Link>
            ))}
          </div>
        </div>
        <div className='bottom-row'>
          <p>
            Created with{' '}
            <span role='img' aria-label='emoji'>
              💚
            </span>{' '}
            by{' '}
            <span>
              <img src={PozLogoSvg} alt='pozitiff logo' />
            </span>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default withRouter(V2Footer)
