import React from 'react'
import { useTranslation } from 'react-i18next'
import IconCreate from '../img/how-it-works-create.svg'
import IconCustomize from '../img/how-it-works-customize.svg'
import IconShow from '../img/how-it-works-show.svg'

const V2HowItWorks = () => {
  const { t } = useTranslation()
  const worksList = [
    {
      icon: <img src={IconCreate} alt='icon' />,
      title: t('HOME_HOW_BOX1_TITLE'),
      text: t('HOME_HOW_BOX1_TEXT'),
    },
    {
      icon: <img src={IconCustomize} alt='icon' />,
      title: t('HOME_HOW_BOX2_TITLE'),
      text: t('HOME_HOW_BOX2_TEXT'),
    },
    {
      icon: <img src={IconShow} alt='icon' />,
      title: t('HOME_HOW_BOX3_TITLE'),
      text: t('HOME_HOW_BOX3_TEXT'),
    },
  ]

  return (
    <section className='v2-section v2-how-it-works'>
      <div className='inner'>
        <h3>{t('HOME_HOW_TITLE_TEXT')}</h3>
        <div className='items-list'>
          {worksList.map((item, i) => (
            <div key={i} className='item'>
              <div className='icon-container'>
                {item.icon}
                <h4>{item.title}</h4>
              </div>
              {item.text}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default V2HowItWorks
