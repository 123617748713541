import React, { useContext } from 'react'
import ReactDragListView from 'react-drag-listview'
import {
  ConstructorGetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
import { useIsDesktop } from '../helpers/hooks'

export const CatDragWrapper = ({ children }) => {
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { defaultLanguage, currentLanguage } = useContext(ConstructorGetterContext)
  const isDesktop = useIsDesktop()

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const draggedUserData = menuLocals.map((menu, b) => {
        const subData = Array.of(...menu.subItems)
        const itemOut = subData.splice(fromIndex, 1)[0]
        subData.splice(toIndex, 0, itemOut)
        menu.subItems = subData
        return menu
      })
      setMenuLocals(draggedUserData)
    },
    nodeSelector: 'section',
    handleSelector: 'span',
  }
  return isDesktop && defaultLanguage === currentLanguage ? (
    <ReactDragListView {...dragProps}>{children}</ReactDragListView>
  ) : (
    { ...children }
  )
}

export const SubcatDragWrapper = ({ cat, children }) => {
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { defaultLanguage, currentLanguage } = useContext(ConstructorGetterContext)
  const isDesktop = useIsDesktop()

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const draggedUserData = menuLocals.map((menu, b) => {
        const subData = Array.of(...menu.subItems[cat].subItems)
        const itemOut = subData.splice(fromIndex, 1)[0]
        subData.splice(toIndex, 0, itemOut)
        menu.subItems[cat].subItems = subData
        return menu
      })
      setMenuLocals(draggedUserData)
    },
    nodeSelector: 'article',
    handleSelector: 'span',
  }
  return isDesktop & (defaultLanguage === currentLanguage) ? (
    <ReactDragListView {...dragProps}>{children}</ReactDragListView>
  ) : (
    { ...children }
  )
}

export const ItemDragWrapper = ({ cat, subcat, children }) => {
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { defaultLanguage, currentLanguage } = useContext(ConstructorGetterContext)
  const isDesktop = useIsDesktop()

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const draggedUserData = menuLocals.map((menu, b) => {
        const subData = menu.subItems[cat].subItems[subcat].subItems
        const itemOut = subData.splice(fromIndex, 1)[0]
        subData.splice(toIndex, 0, itemOut)
        menu.subItems[cat].subItems[subcat].subItems = subData
        return menu
      })
      setMenuLocals(draggedUserData)
    },
    nodeSelector: 'li',
    handleSelector: 'span',
  }
  return isDesktop & (defaultLanguage === currentLanguage) ? (
    <ReactDragListView {...dragProps}>{children}</ReactDragListView>
  ) : (
    { ...children }
  )
}
