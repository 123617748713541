import React, { useEffect, useState, useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { renderTooltip } from '../helpers'
import {
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
} from '../context/userContext'
import { useIsDesktop } from '../helpers/hooks'
import icons from '../img/icons/index'

const { IconStartTour } = icons

const IndicatorPanel = () => {
  const { alias, defaultLanguage } = useContext(ConstructorGetterContext)
  const { setStartTour } = useContext(ConstructorSetterContext)
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { t } = useTranslation()

  const isDesktop = useIsDesktop()
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [isAliasLinkActive, setIsAliasLinkActive] = useState(null)
  const [aliasUrl, setAliasUrl] = useState(null)
  const [aliasFontSize, setAliasFontSize] = useState(isDesktop ? 30 : 22)

  useEffect(() => {
    if (alias) {
      setAliasUrl(`https://${alias}.gimme.menu`)
      setIsDataLoaded(true)
    }
  }, [alias])

  useEffect(() => {
    if (menuLocals?.length && defaultLanguage) {
      setIsAliasLinkActive(
        menuLocals.find((langObj) => langObj.lang === defaultLanguage).isPublished
      )
    }
  }, [menuLocals, defaultLanguage])

  useEffect(() => {
    const indicatorContainer = document.getElementById('indicatorPanel')
    const myAliasUrl = document.getElementById('aliasUrl')
    if (!indicatorContainer || !myAliasUrl) return

    const fontSize = parseFloat(
      window.getComputedStyle(myAliasUrl, null).getPropertyValue('font-size')
    )
    const _newFontSize =
      fontSize *
      (Math.trunc(
        100 *
          ((isDesktop ? indicatorContainer.offsetWidth - 30 : indicatorContainer.offsetWidth) /
            myAliasUrl.offsetWidth)
      ) /
        100)
    const defaultSize = isDesktop ? 30 : 24
    const newFontSize = _newFontSize > defaultSize ? defaultSize : _newFontSize
    myAliasUrl.style.fontSize = `${newFontSize}px`
    setAliasFontSize(newFontSize)
  }, [alias, isDesktop])

  const handleStartTour = () => {
    if (document.cookie.split('; ').find((row) => row.startsWith('gimme_skip_tour=true'))) {
      const newCookie = 'gimme_skip_tour=false; SameSite=None; Secure; max-age= 0'
      document.cookie = newCookie
    }
    setStartTour(false)
    setStartTour(true)
  }

  return (
    <div id='indicatorPanel' className='indicator-panel'>
      {isDataLoaded ? (
        <>
          {isAliasLinkActive ? (
            <a
              id='aliasUrl'
              className='alias-url-link'
              target='_blank'
              rel='noopener noreferrer'
              href={aliasUrl}
            >
              {aliasUrl}
            </a>
          ) : isDesktop ? (
            <OverlayTrigger
              placement='bottom-start'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(t('MANAGER_MENU_ITEM_AFTER_PUBLISH_ONLY_LINK'))}
            >
              <p
                id='aliasUrl'
                style={{ fontSize: `${aliasFontSize}px` }}
                className='alias-url-string'
              >
                {aliasUrl}
              </p>
            </OverlayTrigger>
          ) : (
            <p
              id='aliasUrl'
              style={{ fontSize: `${aliasFontSize}px` }}
              className='alias-url-string'
            >
              {aliasUrl}
            </p>
          )}
          {isDesktop && (
            <OverlayTrigger
              placement='bottom-end'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(t('GUIDE_TOOLTIP_TEXT'))}
            >
              <div className='start-tour' onClick={handleStartTour}>
                <IconStartTour color='#ff7a45' width='24px' />
              </div>
            </OverlayTrigger>
          )}
        </>
      ) : (
        <>
          <p
            style={{
              fontSize: `${aliasFontSize}px`,
              width: isDesktop ? 'calc(100% - 30px)' : '100%',
            }}
            className='alias-url-string'
          >
            <Skeleton />
          </p>
          {isDesktop && (
            <div className='start-tour'>
              <Skeleton />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default IndicatorPanel
