import React from 'react'
import { useTranslation } from 'react-i18next'
import { useIsDesktop } from '../helpers/hooks'
import HeroImageFull from '../img/_new-hero-wallpaper-image-full.png'
import HeroImageBack from '../img/_new-hero-wallpaper-back.svg'
import HeroImageMobilo from '../img/_new-hero-wallpaper-mobilo.png'
import V2CtaButton from './V2CtaButton'

const V2Hero = () => {
  const isDesktop = useIsDesktop(782)
  const { t } = useTranslation()
  return (
    <section className='v2-section v2-hero'>
      {isDesktop && (
        <>
          <div className='big-background-shape-container'>
            <img className='background-blob' alt='hero back' src={HeroImageBack} />
          </div>
          <div className='hero-image-container'>
            <img className='hero-image' alt='hero' src={HeroImageFull} />
          </div>
        </>
      )}
      <div className='inner'>
        <div className='content-container'>
          <div className='early-acces'>{t('HOME_HERO_EARLY_BADGE_TEXT')}</div>
          <h1>{t('HOME_HERO_TITLE')}</h1>
          <p className='subtitle'>{t('HOME_HERO_SUBTITLE')}</p>
          <ul>
            <li>{t('HOME_HERO_TEXT_1')}</li>
            <li>{t('HOME_HERO_TEXT_2')}</li>
            <li>{t('HOME_HERO_TEXT_3')}</li>
            <li>{t('HOME_HERO_TEXT_4')}</li>
          </ul>
          <V2CtaButton
            buttonTitle={t('HOME_HERO_CTA_TEXT')}
            text={t('HOME_HERO_FIRST_BADGE_TEXT')}
          />
        </div>
        {!isDesktop && (
          <div className='mobile-wallpapers'>
            <img alt='hero back' src={HeroImageMobilo} />
          </div>
        )}
      </div>
    </section>
  )
}

export default V2Hero
