import React, { useState, useEffect, useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { ConstructorGetterContext, MenuLocalsGetterContext } from '../context/userContext'
import ExportBlock from './ExportBlock'
import icons from '../img/icons/index'
import { placeholders } from '../data'
import { getMeasure } from '../helpers/index'
import { useIsDesktop } from '../helpers/hooks'
import DefaultLogoWhite from '../img/DefaultLogoWhite'
import IconArrows from '../img/icons/IconArrows'
const {
  IconFacebook,
  IconInstagram,
  IconTripadvisor,
  IconTiktok,
  IconLinktree,
  IconWhatsapp,
  IconEmail,
  IconWeb,
  IconGooglemaps,
  IconTelegram,
  IconOpentable,
  IconLink,
} = icons

const socialIcons = {
  facebook: IconFacebook,
  instagram: IconInstagram,
  tripadvisor: IconTripadvisor,
  whatsapp: IconWhatsapp,
  telegram: IconTelegram,
  tikTok: IconTiktok,
  linkTree: IconLinktree,
  googleMaps: IconGooglemaps,
  openTable: IconOpentable,
  website: IconWeb,
  email: IconEmail,
  customLink: IconLink,
}

const { REACT_APP_CLOUDINARY_LOGO_PATH } = process.env

const MenuItemViewer = () => {
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const {
    defaultLanguage,
    currentLanguage,
    logo,
    mainBgColor,
    mainTextColor,
    splashBgColor,
    splashTextColor,
    socials,
    isPreviewOpen,
  } = useContext(ConstructorGetterContext)

  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [langData, setLangData] = useState(null)
  const [defLangData, setDefLangData] = useState(null)
  const isDesktop = useIsDesktop()

  useEffect(() => {
    if (menuLocals?.length && defaultLanguage && currentLanguage) {
      setDefLangData(menuLocals.find((menu) => menu.lang === defaultLanguage))
      setLangData(menuLocals.find((menu) => menu.lang === currentLanguage))
    }
  }, [menuLocals, currentLanguage, defaultLanguage])

  useEffect(() => {
    setIsDataLoaded(
      defLangData &&
        langData &&
        logo !== null &&
        mainBgColor &&
        mainTextColor &&
        splashBgColor &&
        splashTextColor &&
        socials
    )
  }, [
    defLangData,
    langData,
    logo,
    mainBgColor,
    mainTextColor,
    splashBgColor,
    splashTextColor,
    socials,
  ])

  useEffect(() => {
    const viewHeader = document.getElementById('view-header')
    const viewContentList = document.getElementById('view-content-list')
    if (!viewHeader || !viewContentList) return
    viewContentList.style.paddingTop = `${viewHeader.clientHeight}px`
  })

  useEffect(() => {
    const resizeViewer = () => {
      const html = document.documentElement
      const viewContainer = document.getElementById('view-container')
      if (!html || !viewContainer) return
      if (isDesktop) {
        const newViewerHeight = html.clientHeight - viewContainer.getBoundingClientRect().top - 1
        const scaleCoeff = Math.min(newViewerHeight / viewContainer.offsetHeight, 1.0)
        viewContainer.style.transformOrigin = 'top left'
        viewContainer.style.transform = `scale(${scaleCoeff})`
      } else {
        viewContainer.style.transformOrigin = ''
        viewContainer.style.transform = ''
      }
    }

    if (isDesktop) {
      resizeViewer()

      window.addEventListener('scroll', resizeViewer)
      window.addEventListener('resize', resizeViewer)
    } else {
      window.removeEventListener('scroll', resizeViewer)
      window.removeEventListener('resize', resizeViewer)

      resizeViewer()
    }
    return () => {
      window.removeEventListener('scroll', resizeViewer)
      window.removeEventListener('resize', resizeViewer)
    }
  }, [isDesktop])

  const getRestName = (name, color) => (
    <>
      <h2 style={{ color: color }}>{name.toUpperCase()}</h2>
      <IconArrows color={color} width='23px' />
    </>
  )

  const getLogoImage = () =>
    logo ? (
      <img className='logo-img' src={`${REACT_APP_CLOUDINARY_LOGO_PATH}${logo}`} alt='logo' />
    ) : (
      <DefaultLogoWhite width='100' height='100' color={splashTextColor} />
    )
  const syncSocials = () => {
    // Ensure all keys from socialIcons exist in socials
    Object.keys(socialIcons).forEach((key) => {
      if (!socials.hasOwnProperty(key)) {
        socials[key] = '' // Set value to an empty string for missing key
      }
    })
  }

  const getViewer = () => {
    let socialsToDisplay = []
    if (!!socials) {
      syncSocials()
      socialsToDisplay = Object.keys(socials).map((s) => ({
        name: s,
        link: socials[s],
        Icon: socialIcons[s],
      }))
    }
    const iconSize = socialsToDisplay.filter((s) => s.link != '').length > 6 ? '24px' : '30px'
    return (
      <>
        {isDesktop && <ExportBlock />}
        {isDataLoaded ? (
          <div
            id='view-container'
            className={`view-container${isPreviewOpen ? '' : ' hide'}`}
            style={{ backgroundColor: mainBgColor }}
          >
            <div className='content'>
              <div
                style={{
                  background: `linear-gradient(${mainBgColor}, 90%, rgba(255, 255, 255, 0))`,
                }}
                id='view-header'
                className='header'
              >
                <div className='title'>
                  {getRestName(langData?.restName || placeholders.restname, mainTextColor)}
                </div>
                <div className='logo'>
                  {getLogoImage()}
                  <svg
                    className='preloader__background'
                    width='149'
                    height='121'
                    viewBox='0 -30 149 121'
                    fill='none'
                  >
                    <path
                      d='M247.335 18.5147C256.36 113.148 220.879 67.4971 76.3597 86.1475C-16.1766 98.0895 2.43099 23.2985 3.36003 -27.3523C4.28908 -78.0031 27.8505 -98.5515 80.0504 -97.5941C132.25 -96.6366 248.264 -32.1361 247.335 18.5147Z'
                      fill={splashBgColor}
                    />
                  </svg>
                </div>
              </div>

              <div id='view-content-list' className='content-list'>
                {langData && defLangData && (
                  <ul className='categories'>
                    {langData.subItems?.map(
                      (item, i) =>
                        item.isVisible && (
                          <li className='category' key={i}>
                            <h2
                              className={!item.catName ? 'default-value-blur' : ''}
                              style={{ color: mainTextColor }}
                            >
                              {item.catName || defLangData.subItems[i].catName}
                            </h2>
                            <ul className='subcategories'>
                              {item.subItems?.map(
                                (subItem, o) =>
                                  subItem.isVisible && (
                                    <li className='subcategory' key={o}>
                                      <h3
                                        className={!subItem.subcatName ? 'default-value-blur' : ''}
                                        style={{ color: mainTextColor }}
                                      >
                                        {subItem.subcatName ||
                                          defLangData.subItems[i].subItems[o].subcatName}
                                      </h3>
                                      <ul className='items'>
                                        {subItem.subItems?.map(
                                          (dishItem, p) =>
                                            dishItem.isVisible && (
                                              <li className='item' key={p}>
                                                <div
                                                  className='item-content'
                                                  style={{
                                                    color: mainTextColor,
                                                  }}
                                                >
                                                  <div className='name'>
                                                    <h4
                                                      style={{
                                                        color: mainTextColor,
                                                      }}
                                                      className={
                                                        !dishItem.itemName
                                                          ? 'default-value-blur'
                                                          : ''
                                                      }
                                                    >
                                                      {dishItem.itemName ||
                                                        defLangData.subItems[i].subItems[o]
                                                          .subItems[p].itemName}
                                                    </h4>
                                                  </div>
                                                  <p
                                                    className={
                                                      !dishItem.description
                                                        ? 'description default-value-blur'
                                                        : 'description'
                                                    }
                                                    style={{
                                                      color: mainTextColor,
                                                    }}
                                                  >
                                                    {dishItem.description ||
                                                      defLangData.subItems[i].subItems[o].subItems[
                                                        p
                                                      ].description}
                                                  </p>
                                                </div>
                                                <span
                                                  style={{
                                                    color: mainTextColor,
                                                  }}
                                                  className={
                                                    !dishItem.itemName
                                                      ? 'weight default-value-blur'
                                                      : 'weight'
                                                  }
                                                >
                                                  {dishItem.weight > 0 && (
                                                    <>
                                                      <span>{dishItem.weight}</span>{' '}
                                                      <span>
                                                        {getMeasure(
                                                          dishItem.measure,
                                                          langData.measureUnits
                                                        )}
                                                      </span>
                                                    </>
                                                  )}
                                                </span>
                                                <span
                                                  style={{
                                                    color: mainTextColor,
                                                  }}
                                                  className={
                                                    !dishItem.itemName
                                                      ? 'price default-value-blur'
                                                      : 'price'
                                                  }
                                                >
                                                  <span>{dishItem.price}</span>{' '}
                                                  <span>{langData.measureUnits.currency}</span>
                                                </span>
                                              </li>
                                            )
                                        )}
                                      </ul>
                                    </li>
                                  )
                              )}
                            </ul>
                          </li>
                        )
                    )}
                  </ul>
                )}
              </div>

              <div className='footer'>
                <svg
                  width='375'
                  height='30'
                  viewBox='-5 55 372 27'
                  fill={splashBgColor}
                  xmlns='http://www.w3.org/2000/svg'
                  className='bg-mobilo'
                >
                  <path d='M22.47 64.146c38.34-15.12 95.115-6.167 132.552-.43 37.436 5.737 55.534 8.259 98.901 9.345 43.094 1.133 111.458.832 137.912 22.62 26.727 21.742 12.093 111.082-26.335 137.114-38.398 26.209-98.727 13.461-154.5 18.501-55.774 5.04-175.464-11.907-218.5-24.128s-11.496-43.669-19.885-76.188c-8.39-32.518 11.516-71.713 49.856-86.834Z'></path>
                </svg>
                <svg
                  viewBox='0 0 1440 80'
                  fill={splashBgColor}
                  xmlns='http://www.w3.org/2000/svg'
                  preserveAspectRatio='none'
                  className='bg-desktop'
                >
                  <path d='M-21 236.046v-46m0 0v-114c43.667-32.833 184.2-92.8 397-70 266 28.5 547 70 668.5 70 224 0 314.5-33 411-38v152H-21Z'></path>
                </svg>
                <div
                  className='footer-inner'
                  style={{
                    background: splashBgColor,
                    color: splashTextColor,
                  }}
                >
                  <div className='socials'>
                    {' '}
                    {socialsToDisplay.map(
                      ({ name, link, Icon }) =>
                        link !== '' && (
                          <div key={name} onClick={() => window.open(link, '_blank')}>
                            <Icon
                              className={[name, 'footer-social-link'].join(' ')}
                              color={splashTextColor}
                              width={iconSize}
                            />
                          </div>
                        )
                    )}
                  </div>
                  <div
                    style={{ background: splashBgColor, color: splashTextColor }}
                    className='powered-by'
                  >
                    <p>
                      Powered by&nbsp;
                      <a
                        style={{
                          background: splashBgColor,
                          color: splashTextColor,
                        }}
                        href='https://gimme.menu'
                      >
                        gimme.menu
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div id='view-container' className='skeleton-view-container'>
            {isDesktop && <Skeleton />}
          </div>
        )}
      </>
    )
  }

  return (
    <div id='viewer' className='viewer'>
      {getViewer()}
    </div>
  )
}

export default MenuItemViewer
