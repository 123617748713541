import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

const V2Faq = forwardRef((props, ref) => {
  const { t } = useTranslation()
  const faqContent = [
    {
      question: t('HOME_FAQ_Q_1'),
      answer: t('HOME_FAQ_A_1'),
    },
    {
      question: t('HOME_FAQ_Q_2'),
      answer: t('HOME_FAQ_A_2'),
    },
    {
      question: t('HOME_FAQ_Q_3'),
      answer: t('HOME_FAQ_A_3'),
    },
  ]

  return (
    <section ref={ref} id='faq' className='v2-section v2-faq'>
      <div className='inner'>
        <h3>{t('HOME_FAQ_TITLE')}</h3>
        <div className='items-list'>
          {faqContent.map((item, i) => (
            <div key={i} className='item'>
              <p className='question'>{item.question}</p>
              <p>{item.answer}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
})

export default V2Faq
