import React from 'react'
const IconGooglemaps = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill={color}
    >
      <path d='M12.833 8.337l5.868-6.977-0.059-0.015c-0.792-0.217-1.701-0.341-2.64-0.341-0.007 0-0.014 0-0.022 0h0.001c-3.055 0.030-5.794 1.353-7.701 3.448l-0.008 0.008-0.020 0.022zM19.325 1.549l-4.796 5.702 0.034-0.013c0.421-0.146 0.906-0.23 1.41-0.23 1.65 0 3.090 0.902 3.852 2.241l0.011 0.022c0.22 0.376 0.384 0.815 0.468 1.282l0.004 0.024c0.047 0.242 0.074 0.521 0.074 0.805 0 0.254-0.021 0.503-0.062 0.745l0.004-0.026-0.004 0.020 4.794-5.7c-1.262-2.27-3.278-3.992-5.702-4.846l-0.073-0.023zM7.867 4.939l-0.004 0.005c-1.402 1.839-2.246 4.169-2.246 6.697 0 1.538 0.313 3.003 0.878 4.335l-0.027-0.073 5.98-7.109-0.072-0.063zM11.682 10.633l-4.947 5.882c1.057 1.985 2.248 3.698 3.614 5.254l-0.027-0.031c0.309 0.387 0.617 0.777 0.921 1.17l6.228-7.404-0.036 0.012c-0.419 0.145-0.903 0.229-1.405 0.229-1.826 0-3.394-1.105-4.072-2.683l-0.011-0.029c-0.11-0.257-0.201-0.557-0.257-0.869l-0.004-0.026c-0.042-0.229-0.066-0.493-0.066-0.763 0-0.259 0.022-0.513 0.065-0.76l-0.004 0.026zM25.406 7.001c0.605 1.354 0.958 2.934 0.958 4.596 0 2.063-0.543 3.999-1.493 5.674l0.030-0.057c-1.693 2.658-3.379 4.947-5.203 7.116l0.082-0.1c-0.768 0.99-1.463 2.108-2.037 3.296l-0.049 0.112c-0.176 0.406-0.329 0.822-0.479 1.24-0.151 0.416-0.28 0.841-0.425 1.26-0.136 0.392-0.295 0.855-0.784 0.858h-0.009c-0.582-0.001-0.724-0.662-0.869-1.108-0.371-1.22-0.803-2.265-1.322-3.258l0.049 0.103c-0.725-1.274-1.465-2.364-2.279-3.393l0.042 0.056z'></path>
    </svg>
  )
}
export default IconGooglemaps
