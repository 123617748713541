import React, { useEffect, useContext } from 'react'
import Joyride, { ACTIONS, LIFECYCLE } from 'react-joyride'
import { useTranslation } from 'react-i18next'
import { ConstructorGetterContext, ConstructorSetterContext } from '../context/userContext'

const GuideTour = () => {
  const { t } = useTranslation()
  const tourSteps = [
    {
      target: '#rest-name',
      content: t('GUIDE_TEXT_FIRST'),
      placement: 'bottom',
      disableBeacon: true,
      stepIndex: 0,
    },
    {
      target: '#categories-container',
      content: t('GUIDE_TEXT_NEXT'),
      placement: 'right-start',
      disableBeacon: true,
      stepIndex: 1,
    },
    {
      target: '#add-item-button',
      content: t('GUIDE_TEXT_NEW_ITEM'),
      placement: 'right',
      disableBeacon: true,
      stepIndex: 2,
    },
    {
      target: '#beauty-container',
      content: t('GUIDE_TEXT_COLORIZE'),
      placement: 'right-start',
      disableBeacon: true,
      stepIndex: 3,
    },
    {
      target: '#content-container',
      content: t('GUIDE_TEXT_WRITE'),
      disableBeacon: true,
      stepIndex: 4,
    },
    {
      target: '#lang-choose',
      content: t('GUIDE_TEXT_TRANSLATE'),
      disableBeacon: true,
      stepIndex: 5,
    },
    {
      target: '#view-container',
      content: t('GUIDE_TEXT_OBSERVE'),
      placement: 'left',
      disableBeacon: true,
      stepIndex: 6,
    },
    {
      target: '#export-import',
      content: t('GUIDE_TEXT_CLICK'),
      placement: 'bottom-start',
      disableBeacon: true,
      stepIndex: 7,
    },
    {
      target: '#my-menus-link',
      content: t('GUIDE_TEXT_GO'),
      placement: 'bottom-end',
      disableBeacon: true,
      stepIndex: 8,
    },
  ]

  const { startTour } = useContext(ConstructorGetterContext)
  const { setStartTour } = useContext(ConstructorSetterContext)

  useEffect(() => {
    setTimeout(() => {
      const isCookieExist = document.cookie
        .split('; ')
        .find((row) => row.startsWith('gimme_skip_tour=true'))
      if (!isCookieExist) {
        setStartTour(true)
      }
    }, 100)
  }, [setStartTour])

  const setCookie = () => {
    document.cookie = 'gimme_skip_tour=true; SameSite=None; Secure; max-age=15552000000'
    setStartTour(false)
  }

  const handleTourCallback = ({ action, index, size, lifecycle }) => {
    if (
      (action === ACTIONS.SKIP && lifecycle === LIFECYCLE.COMPLETE) ||
      (action === ACTIONS.NEXT && index === --size && lifecycle === LIFECYCLE.COMPLETE)
    ) {
      setCookie()
    }
  }

  return (
    startTour && (
      <div className='tour-container'>
        <Joyride
          steps={tourSteps}
          styles={{
            buttonNext: {
              backgroundColor: '#ff7a45',
              outline: 'none',
            },
            options: {
              zIndex: 9999,
            },
            buttonBack: {
              color: '#ff7a45',
              backgroundColor: '#ffffff',
              outline: 'none',
            },
            buttonLast: {
              backgroundColor: '#ff7a45',
              outline: 'none',
            },
            buttonClose: {
              display: 'none',
            },
            buttonSkip: {
              color: '#ff7a45',
              outline: 'none',
            },
          }}
          showSkipButton={true}
          run={true}
          continuous={true}
          spotlightPadding={10}
          callback={handleTourCallback}
          disableScrollParentFix={true}
          hideCloseButton={true}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          spotlightClicks={true}
          locale={{
            back: t('GUIDE_BUTTON_BACK'),
            last: t('GUIDE_BUTTON_LAST'),
            next: t('GUIDE_BUTTON_NEXT'),
            skip: t('GUIDE_BUTTON_SKIP'),
          }}
        />
      </div>
    )
  )
}

export default GuideTour
