import React from 'react'

const IconPaint = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 10 10'
      fill='none'
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M5.63396 4.34829C5.41246 4.12675 5.02668 4.12675 4.80518 4.34827C4.73477 4.41839 4.68555 4.50137 4.65779 4.59497V4.59526C4.55762 4.93296 4.32439 5.21745 4.01758 5.39833C4.5227 5.68395 5.21215 5.59895 5.63398 5.1771C5.86314 4.94792 5.86314 4.57749 5.63396 4.34829Z'
          fill={color}
        />
        <path
          d='M8.68805 4.63555C8.59397 4.20156 8.43178 3.79119 8.21012 3.40762C7.65502 3.89275 6.92168 4.43789 6.35555 4.50824C6.4569 4.93223 6.32569 5.33256 6.04764 5.61063C5.68246 5.97609 5.19678 6.17729 4.67992 6.17729C4.16336 6.17729 3.67768 5.97609 3.31221 5.61063C3.19559 5.49361 3.10012 5.40791 3.15795 5.23C3.19174 5.12553 3.28158 5.04855 3.39006 5.03109C3.72947 4.97643 4.00623 4.74746 4.09494 4.44783C4.1499 4.26182 4.25205 4.09066 4.39029 3.95272C4.67815 3.66469 5.10527 3.5674 5.49121 3.65465C5.55621 3.08898 6.12072 2.33639 6.59475 1.79139C4.93977 0.83791 2.75488 1.0283 1.28858 2.49541C0.245393 3.53859 -0.195057 4.98014 0.0802363 6.45059C0.397619 8.14541 1.74301 9.52945 3.42869 9.8949C3.75151 9.96473 4.0752 9.99965 4.39631 9.99965C5.14805 9.99965 5.80297 9.85707 6.05252 9.09842C6.20678 8.62963 6.08744 8.12307 5.74086 7.7765C5.51166 7.54731 5.51166 7.17688 5.74086 6.9477C5.96238 6.72617 6.34817 6.72617 6.56967 6.9477C6.91598 7.294 7.42139 7.41361 7.89158 7.25936C8.34662 7.10969 8.67318 6.72588 8.74358 6.25797C8.82428 5.72479 8.80569 5.179 8.68805 4.63555ZM3.22465 2.38008C3.70945 2.38008 4.10383 2.77445 4.10383 3.25926C4.10383 3.74406 3.70945 4.13844 3.22465 4.13844C2.73985 4.13844 2.34547 3.74406 2.34547 3.25926C2.34547 2.77447 2.73983 2.38008 3.22465 2.38008ZM1.17322 5.01764C1.17322 4.53283 1.5676 4.13846 2.0524 4.13846C2.53721 4.13846 2.93158 4.53283 2.93158 5.01764C2.93158 5.50244 2.53721 5.89682 2.0524 5.89682C1.5676 5.89682 1.17322 5.50244 1.17322 5.01764ZM2.63852 8.24131C2.15371 8.24131 1.75934 7.84693 1.75934 7.36213C1.75934 6.87732 2.15371 6.48295 2.63852 6.48295C3.12332 6.48295 3.5177 6.87732 3.5177 7.36213C3.51772 7.84691 3.12332 8.24131 2.63852 8.24131Z'
          fill={color}
        />
        <path
          d='M9.96166 0.0314844C9.95223 0.0220508 9.93019 0 9.85777 0C9.49352 0 8.82975 0.441992 8.16602 1.02549L8.95531 1.81479C9.76594 0.89209 10.1268 0.196816 9.96166 0.0314844Z'
          fill={color}
        />
        <path
          d='M7.73401 1.42383C7.67409 1.48152 7.61305 1.53898 7.5551 1.59693C6.68622 2.46609 6.05661 3.36875 6.0586 3.74367C6.0594 3.90436 6.19584 3.95654 6.31514 3.91477C6.79288 3.83479 7.70403 3.10566 8.38391 2.42578C8.44522 2.36449 8.49795 2.30619 8.5561 2.24594L7.73401 1.42383Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='10' height='10' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconPaint
