import React, { useEffect, useState, useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import InputGroup from 'react-bootstrap/InputGroup'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Col from 'react-bootstrap/Col'
import { eraseOldData } from '../helpers'
import {
  RootSetterContext,
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
import Select from 'react-select'
import { fullList } from '../data/langList'
import icons from '../img/icons/index'
import { useIsDesktop } from '../helpers/hooks'

const { IconRemoveItem } = icons

const LanguageChoose = () => {
  const { setShowRemoveModal } = useContext(RootSetterContext)
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals, setIsLocalsLoading } = useContext(MenuLocalsSetterContext)
  const { defaultLanguage, currentLanguage } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges, setCurrentLanguage } = useContext(ConstructorSetterContext)
  const isDesktop = useIsDesktop()
  const { t } = useTranslation()

  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [langsToGo, setLangsToGo] = useState([])
  const [isLangListEmpty, setIsLangListEmpty] = useState(false)

  const getLangName = (short) => {
    return fullList?.find((item) => item.value === short)?.label
  }

  useEffect(() => {
    if (menuLocals && defaultLanguage && currentLanguage) {
      const menuLangs = menuLocals.map((langObj) => langObj.lang)
      const tempLangs = fullList.filter((lang) => !menuLangs.includes(lang.value))

      if (tempLangs.length > 0) {
        setLangsToGo(tempLangs)
        setIsLangListEmpty(false)
      } else {
        setLangsToGo([getLangName(defaultLanguage)])
        setIsLangListEmpty(true)
      }
      setIsDataLoaded(true)
    } else {
      setIsLangListEmpty(false)
      setIsDataLoaded(false)
    }
  }, [menuLocals, defaultLanguage, currentLanguage])

  const handleAddTranslation = (selectedLang) => {
    if (!isDataLoaded) return
    const translationToCopy = eraseOldData(
      menuLocals.find((local) => local.lang === defaultLanguage)
    )
    translationToCopy.lang = selectedLang.value
    translationToCopy.isPublished = false
    menuLocals.push(translationToCopy)
    setIsLocalsLoading(true)
    setMenuLocals(menuLocals)
    setCurrentLanguage(selectedLang.value)
    setIsThereUnsavedChanges(true)
  }

  const handleDeleteTranslation = () => {
    if (!isDataLoaded || defaultLanguage === currentLanguage) return
    const _menuLocals = menuLocals.filter((local) => local.lang !== currentLanguage)
    setIsLocalsLoading(true)
    setMenuLocals(_menuLocals)
    setCurrentLanguage(defaultLanguage)
    setIsThereUnsavedChanges(true)
    return true
  }

  const getLangButton = (lang, key = null) => (
    <div
      key={key}
      className={`v2-button${!isDesktop ? ' sm' : ''}${
        currentLanguage !== lang ? ' v2-button-outline' : ''
      }`}
      name={lang}
      value={lang}
      checked={currentLanguage === lang}
      onClick={() => {
        if (currentLanguage !== lang) {
          setIsLocalsLoading(true)
          setCurrentLanguage(lang)
        }
      }}
    >
      {getLangName(lang)}
    </div>
  )

  return (
    <div id='lang-choose' className='lang-choose top-line'>
      <div className='languages-list'>
        <ButtonGroup className='language-toggle' toggle>
          {isDataLoaded ? (
            <>
              {menuLocals.map((radio, i) =>
                radio.lang === defaultLanguage ? (
                  <div className='default-language' key={i}>
                    <h5>{t('CON_TRANS_BUTTON_DEFAULT')}</h5>
                    {getLangButton(radio.lang, i)}
                  </div>
                ) : (
                  getLangButton(radio.lang, i)
                )
              )}
            </>
          ) : (
            <div className='default-language'>
              <h5>
                <Skeleton />
              </h5>
              <div className={`skeleton-button${!isDesktop ? ' sm' : ''}`}>
                <Skeleton />
              </div>
            </div>
          )}
        </ButtonGroup>
      </div>
      {isDataLoaded && (
        <>
          {currentLanguage !== defaultLanguage && (
            <Col className='remove-lang-container' xl>
              <div
                className={`v2-button${!isDesktop ? ' sm' : ''}`}
                onClick={() =>
                  setShowRemoveModal({
                    show: true,
                    name: getLangName(currentLanguage),
                    kind: t('CON_MODAL_DELETE_TRANSLATION'),
                    cbName: handleDeleteTranslation,
                    cbArgs: [
                      {
                        menuLocals,
                        setMenuLocals,
                        defaultLanguage,
                        currentLanguage,
                        setCurrentLanguage,
                        setIsThereUnsavedChanges,
                      },
                    ],
                  })
                }
              >
                <IconRemoveItem color='#ffffff' width={isDesktop ? '24px' : '20px'} />
                {` Remove ${getLangName(currentLanguage)}`}
              </div>
            </Col>
          )}
        </>
      )}
      {isDataLoaded ? (
        <>
          {!isLangListEmpty && (
            <div className='add-lang-container'>
              <InputGroup size='md' className=''>
                <InputGroup.Prepend>
                  <InputGroup.Text>{t('CON_TRANS_DROPDOWN_ADD')}</InputGroup.Text>
                </InputGroup.Prepend>
                <Select
                  className='select-container'
                  classNamePrefix='select-container'
                  placeholder={t('CON_TRANS_DROPDOWN_SELECT')}
                  value=''
                  options={Array.of(...langsToGo)}
                  onChange={handleAddTranslation}
                  noOptionsMessage={() => t('CON_LANGUAGE_NOT_FOUND')}
                  unstyled='true'
                />
              </InputGroup>
            </div>
          )}
        </>
      ) : (
        <div className='add-lang-container'>
          <InputGroup size='md' className=''>
            <div className='skeleton-container'>
              <Skeleton />
            </div>
          </InputGroup>
        </div>
      )}
    </div>
  )
}

export default LanguageChoose
