import React, { useState, useEffect, useCallback, useContext } from 'react'
import {
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
import icons from '../img/icons/index'
const { IconDragUp, IconDragDown } = icons

export const useCursorPosition = () => {
  const [elementId, setElementId] = useState(null)
  const [cursorPosition, setCursorPosition] = useState(0)
  useEffect(() => {
    if (elementId) {
      const el = document.getElementById(elementId)
      el.focus()
      if (cursorPosition) {
        el.selectionEnd = cursorPosition
        el.selectionStart = cursorPosition
      }
    }
  }, [elementId, cursorPosition])
  return [setElementId, setCursorPosition]
}

export const useIsDesktop = (breakpoint = 991) => {
  const getDiffBool = useCallback(() => (window ? window.innerWidth > breakpoint : null), [
    breakpoint,
  ])
  const [isDesktop, setIsDesktop] = useState(getDiffBool())

  useEffect(() => {
    const handleResize = () => {
      const diffBoolean = getDiffBool()
      setIsDesktop(diffBoolean)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [breakpoint, getDiffBool])

  return isDesktop
}

export const useDragDown = (indexArray, targetId) => {
  const { setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)

  const currentIndex = indexArray[indexArray.length - 1]

  const targetItem = document.getElementById(targetId)
  const getTargetListLength = () => targetItem && targetItem.parentElement.childElementCount

  const handleDragDown = () => {
    let draggedUserData
    switch (indexArray.length) {
      case 1:
        draggedUserData = menuLocals.map((menu, b) => {
          let subData = menu.subItems
          const itemOut = subData.splice(currentIndex, 1)[0]
          subData.splice(currentIndex + 1, 0, itemOut)
          menu.subItems = subData
          return menu
        })
        break
      case 2:
        draggedUserData = menuLocals.map((menu, b) => {
          let subData = menu.subItems[indexArray[0]].subItems
          const itemOut = subData.splice(currentIndex, 1)[0]
          subData.splice(currentIndex + 1, 0, itemOut)
          menu.subItems[indexArray[0]].subItems = subData
          return menu
        })
        break
      case 3:
        draggedUserData = menuLocals.map((menu, b) => {
          let subData = menu.subItems[indexArray[0]].subItems[indexArray[1]].subItems
          const itemOut = subData.splice(currentIndex, 1)[0]
          subData.splice(currentIndex + 1, 0, itemOut)
          menu.subItems[indexArray[0]].subItems[indexArray[1]].subItems = subData
          return menu
        })
        break
      default:
        draggedUserData = menuLocals
        break
    }
    setMenuLocals(draggedUserData)
    setIsThereUnsavedChanges(true)
  }

  if (menuLocals) {
    return currentIndex === getTargetListLength() - 1 ? (
      <span className='drag-down disabled'>
        <IconDragDown color='lightgrey' width='24px' />
      </span>
    ) : (
      <span className='drag-down' onClick={handleDragDown}>
        <IconDragDown color='#ff7a45' width='24px' />
      </span>
    )
  } else return null
}

export const useDragUp = (indexArray) => {
  const { setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)

  const currentIndex = indexArray[indexArray.length - 1]

  const handleDragUp = () => {
    let draggedUserData
    switch (indexArray.length) {
      case 1:
        draggedUserData = menuLocals.map((menu, b) => {
          let subData = menu.subItems
          const itemOut = subData.splice(currentIndex, 1)[0]
          subData.splice(currentIndex - 1, 0, itemOut)
          menu.subItems = subData
          return menu
        })
        break
      case 2:
        draggedUserData = menuLocals.map((menu, b) => {
          let subData = menu.subItems[indexArray[0]].subItems
          const itemOut = subData.splice(currentIndex, 1)[0]
          subData.splice(currentIndex - 1, 0, itemOut)
          menu.subItems[indexArray[0]].subItems = subData
          return menu
        })
        break
      case 3:
        draggedUserData = menuLocals.map((menu, b) => {
          let subData = menu.subItems[indexArray[0]].subItems[indexArray[1]].subItems
          const itemOut = subData.splice(currentIndex, 1)[0]
          subData.splice(currentIndex - 1, 0, itemOut)
          menu.subItems[indexArray[0]].subItems[indexArray[1]].subItems = subData
          return menu
        })
        break
      default:
        draggedUserData = menuLocals
        break
    }
    setMenuLocals(draggedUserData)
    setIsThereUnsavedChanges(true)
  }

  if (menuLocals) {
    return currentIndex === 0 ? (
      <span className='drag-up disabled'>
        <IconDragUp color='lightgrey' width='24px' />
      </span>
    ) : (
      <span className='drag-up' onClick={handleDragUp}>
        <IconDragUp color='#ff7a45' width='24px' />
      </span>
    )
  } else return null
}
