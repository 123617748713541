import React from 'react'
import secondaryImg1 from '../../img/secondary-page-img_01.png'
import secondaryImg2 from '../../img/secondary-page-img_02.png'
import secondaryImg3 from '../../img/secondary-page-img_03.png'
import secondaryImg4 from '../../img/secondary-page-img_04.png'
import secondaryImg5 from '../../img/secondary-page-img_05.png'

const PrivacyPolicyPage = () => {
  return (
    <section className='secondary-page'>
      <div className='text'>
        <h1>Privacy Policy</h1>
        <p>
          <b>PozitiFF Company</b> s.r.o values your privacy. This Privacy Statement explains how
          gimme.menu collects and uses information about you when you utilize <b>Gimme Menu</b>{' '}
          services.
        </p>
        <h5>What type of information do we collect from you?</h5>
        <p>
          We may collect personally identifying data or contact information when you register to use
          the Gimme Menu. This information may include your name, email address, user password, and
          other data (collectively "Personal Information"). You also provide us with Personal
          Information when making orders for our services, including credit card information,
          billing address, and shipping information. PozitiFF Company s.r.o utilizes your Personal
          Information to operate the Gimme Menu, to affect your transactions on Gimme Menu, and to
          study and improve the quality of our services. You may correct or amend your Personal
          Information by editing your Account page or by sending an email to support@gimmemenu.com.
        </p>
        <h5>How is your information used?</h5>
        <p>
          Gimme Menu primarily utilizes your data to process your orders and for the use of our
          Services. We may also employ your information to provide you with customer service or
          notify you about changes to our service. We also can occasionally send you information on
          exclusive offers. However, we try our best to only send promotional info with the express
          consent of our customers.
        </p>
        <p className='green'>
          You can stop the delivery of promotional information from Gimme Menu by using the
          UNSUBSCRIBE link provided in all marketing emails or by sending an email to
          support@gimmemenu.com.
        </p>
        <h5>Who has access to your information?</h5>
        <p>
          We do not sell or rent out your Personal Information to third parties without your express
          consent. As a general rule, we do not sell user personal information. We may only consider
          transactions with user data if it would improve our product or service, complement our
          existing services, or provide extra value for our customers.
        </p>
        <h5>Third-Party Service Providers</h5>
        <p>
          We partner with other companies to support PozitiFF Company s.r.o products. These
          companies can potentially come in contact with user Personal Information. For instance, we
          use third-party software to facilitate our customer service or a payment company to
          conduct our financial transactions. These parties are not allowed to use any personally
          identifiable information, except for the express purpose of facilitating the Gimme Menu
          services.
        </p>
        <p>
          Circumstances under which Personal Information can be disclosed. We may store and disclose
          Personal Information as required by law or to be in compliance with government
          regulations. We may make disclosures that are necessary or advisable to adhere to legal
          and regulatory obligations and to protect the rights, safety, and property of PozitiFF
          Company s.r.o, users of our service – Gimme Menu, or the general public.
        </p>
        <h5>Security</h5>
        <p>
          We take all necessary, prudent, industry-standard measures to protect your Personal
          Information. We use technologies like SSL encryption, passwords, access control
          procedures, and firewalls, as well as physical security.
        </p>
        <h5>Use of Cookies</h5>
        <p className='green'>
          You must have Cookies turned ON to be a member at Gimme Menu. Cookies are very small text
          files saved to your local drive. They serve as your identification and are uniquely yours.
          A cookie can only be read by the server that gave it to you. Cookies tell us that you
          returned to a specific web page on our Site, and help us track your preferences. Cookies
          enable you to login, maintain stored versions of your work, and help us personalize your
          experience at the Site.
        </p>
        <h5>Data Retention</h5>
        <p>
          We hold the personal data for as long as it may be relevant to providing the Gimme Menu
          services. To dispose of it, we may delete it, anonymize it, or take other legally
          sufficient action. It is possible for the copies of data to persist in backups for
          recovery purposes.
        </p>
        <h5>Your Rights</h5>
        <p>
          As required by the General Data Protection Regulation and other applicable EEA state law,
          you have a right to:
        </p>
        <ul>
          <li>
            Accessing your Personal Data and information about recipients of your Personal Data, the
            purposes of the processing, duration of storage, and the source of your Personal Data;
          </li>
          <li>
            Correct or rectify inaccurate or incomplete Personal Data concerning you, and the right
            to have incomplete Personal Data completed;
          </li>
          <li>Under specific consequences to having your Personal Data erased;</li>
          <li>Restrict the processing of your Personal Data in certain circumstances;</li>
          <li>Object to the processing of Personal Data in certain circumstances;</li>
          <li>
            Withdraw your consent to the processing of your Personal Data. The withdrawal does not
            affect the lawfulness of processing before the withdrawal of consent.
          </li>
        </ul>
        <h5>Changes to Gimme Menu Privacy Policy Statement</h5>
        <p>
          PozitiFF can sometimes change this Privacy Statement. Any such updates will occur on this
          page. Make sure you are up to date on it by checking it occasionally. We may also notify
          you about substantial changes to this statement via email, official website, or otherwise.
        </p>
        <p className='last-p'>
          Concerns Questions or Complaints Please direct any and all concerns, questions, or
          complaints about the use of your Personal Information to support@gimmemenu.com, so we
          could investigate, within due diligence, any concerns about our usage of your Personal
          Information.
        </p>
      </div>
      <div className='pattern-container'>
        <img src={secondaryImg1} alt='foood-patttern1' />
        <img src={secondaryImg2} alt='foood-patttern2' />
        <img src={secondaryImg3} alt='foood-patttern3' />
        <img src={secondaryImg4} alt='foood-patttern4' />
        <img src={secondaryImg5} alt='foood-patttern5' />
      </div>
    </section>
  )
}

export default PrivacyPolicyPage
