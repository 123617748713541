import React from 'react'

const IconExport = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='-1 0 12.2 10'
      fill='none'
    >
      <path
        d='M5.02715 7.03711C5.03738 7.05018 5.05045 7.06075 5.06537 7.06803C5.0803 7.0753 5.09668 7.07907 5.11328 7.07907C5.12988 7.07907 5.14626 7.0753 5.16119 7.06803C5.17611 7.06075 5.18918 7.05018 5.19941 7.03711L6.73066 5.0998C6.78672 5.02871 6.73613 4.92344 6.64453 4.92344H5.63145V0.296875C5.63145 0.236719 5.58223 0.1875 5.52207 0.1875H4.70176C4.6416 0.1875 4.59238 0.236719 4.59238 0.296875V4.92207H3.58203C3.49043 4.92207 3.43984 5.02734 3.4959 5.09844L5.02715 7.03711ZM10.1172 6.55859H9.29688C9.23672 6.55859 9.1875 6.60781 9.1875 6.66797V8.77344H1.03906V6.66797C1.03906 6.60781 0.989844 6.55859 0.929688 6.55859H0.109375C0.0492187 6.55859 0 6.60781 0 6.66797V9.375C0 9.61699 0.195508 9.8125 0.4375 9.8125H9.78906C10.0311 9.8125 10.2266 9.61699 10.2266 9.375V6.66797C10.2266 6.60781 10.1773 6.55859 10.1172 6.55859Z'
        fill={color}
      />
    </svg>
  )
}

export default IconExport
