import React, { useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'
import LogoSvg from '../../img/v2-logo.svg'

const { REACT_APP_BASE_URL } = process.env

const V2Header = ({ history }) => {
  const { user, isAuthenticated, logout, loginWithRedirect } = useAuth0()
  const { t, i18n } = useTranslation()

  function handleChangeLanguage() {
    const _select = document.getElementById('headerLanguageSelect')
    i18n.changeLanguage(_select.value)
  }

  const getLanguageSelect = () => {
    return (
      <select
        id='headerLanguageSelect'
        defaultValue={i18n ? i18n.language : 'en'}
        className='v2-button v2-button-light lang-selector'
        onChange={handleChangeLanguage}
      >
        <option value='en'>Eng</option>
        <option value='ru'>Rus</option>
        <option value='uk'>Ukr</option>
      </select>
    )
  }

  const isUserCanUseSummary = useMemo(() => {
    if (user && isAuthenticated) {
      const userRolesArray = user[`${REACT_APP_BASE_URL}/roles`]
      return userRolesArray?.includes('admin')
    } else return false
  }, [user, isAuthenticated])

  return (
    <header id='header' className='v2-section v2-header'>
      <div className='inner'>
        <a className='logo-link' href='/'>
          <img className='logo-image' src={LogoSvg} alt='logo' onClick={() => history.push('/')} />
        </a>
        <div className='menu'>
          {isUserCanUseSummary && (
            <div
              onClick={() => history.push('/summary')}
              className='v2-button v2-button-light'
              id='summary-link'
            >
              {t('HEADER_SUMMARY_BUTTON_TEXT')}
            </div>
          )}
          {isAuthenticated ? (
            <>
              <div
                onClick={() => history.push('/my')}
                className='v2-button v2-button-light'
                id='my-menus-link'
              >
                {t('HEADER_MANAGER_BUTTON_TEXT')}
              </div>
              <div
                className='v2-button v2-button-light'
                onClick={() => {
                  logout({ returnTo: window.location.origin })
                }}
              >
                {t('HEADER_LOGOUT_BUTTON_TEXT')}
              </div>
            </>
          ) : (
            <div
              className='v2-button v2-button-light'
              onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
            >
              {t('HEADER_LOGIN_BUTTON_TEXT')}
            </div>
          )}
          {getLanguageSelect()}
        </div>
      </div>
    </header>
  )
}

export default withRouter(V2Header)
