import React from 'react'

const IconFacebook = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
    >
      <path
        d='M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H18.625C19.0398 19.375 19.375 19.0398 19.375 18.625V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM17.875 17.875H13.5602V12.1141H15.9977L16.3633 9.28516H13.5602V7.47812C13.5602 6.65781 13.7875 6.1 14.9617 6.1H16.4594V3.56875C16.1992 3.53359 15.3109 3.45625 14.275 3.45625C12.1141 3.45625 10.6352 4.77578 10.6352 7.19687V9.28281H8.19297V12.1117H10.6375V17.875H2.125V2.125H17.875V17.875Z'
        fill={color}
      />
    </svg>
  )
}

export default IconFacebook
