/* eslint-disable no-prototype-builtins */
import axios from 'axios'
import React from 'react'
import { defaultItem, defaultSubcategory, defaultCategory } from '../data/index'
import { validateMenu, validateImportCsvMenu } from '../validation/validate'
import Popover from 'react-bootstrap/Popover'
import { enGB, ru, uk } from 'date-fns/locale'
import {
  parseISO,
  format,
  formatDistance,
  differenceInDays,
  differenceInMilliseconds,
} from 'date-fns'
import Papa from 'papaparse'
import { v4 as uuid } from 'uuid'

Papa.parsePromise = function (file, options = {}) {
  return new Promise(function (complete, error) {
    Papa.parse(file, { ...options, complete, error })
  })
}

export const renderTooltip = (props) => (
  <Popover className='popover custom-popover' id='popover-top'>
    <div className='popover-inner custom-popover-inner'>{props}</div>
  </Popover>
)

export const importJson = (e, userDataHook) => {
  e.preventDefault()
  const { userDataRaw, setUserDataRaw, setLang, tempErrors, setTempErrors, setShowList } =
    userDataHook

  const importInput = document.getElementById(e.target.id)
  const file = e.target.files[0]
  // eslint-disable-next-line
  const reader = new FileReader()
  reader.readAsText(file)
  reader.onloadend = (e) => {
    try {
      const resultJSON = JSON.parse(e.target.result)
      if (validateMenu(resultJSON).error) {
        const jsonError = Array.from(tempErrors)
        jsonError.push('ioError')
        setTempErrors(jsonError)
      } else {
        const jsonError = Array.from(tempErrors)
        resultJSON.id = userDataRaw.id
        resultJSON.alias = userDataRaw.alias
        resultJSON.events.editedAt = new Date().toISOString()
        setUserDataRaw(resultJSON)
        setLang(resultJSON.defaultLanguage)
        setTempErrors(jsonError.filter((item) => item !== 'ioError'))
        updateShowList(resultJSON, setShowList)
      }
    } catch (e) {
      const jsonError = Array.from(tempErrors)
      jsonError.push('ioError')
      setTempErrors(jsonError)
    }
    importInput.value = ''
  }
}

export const exportJson = (e, userDataHook) => {
  e.preventDefault()
  const { userDataRaw, tempErrors, setTempErrors } = userDataHook
  if (validateMenu(userDataRaw).error) {
    const jsonError = Array.from(tempErrors)
    jsonError.push('ioError')
    setTempErrors(jsonError)
  } else {
    const jsonError = Array.from(tempErrors)
    setTempErrors(jsonError.filter((item) => item !== 'ioError'))

    const a = document.createElement('a')
    a.href = URL.createObjectURL(
      // eslint-disable-next-line
      new Blob([JSON.stringify(userDataRaw, null, 2)], {
        type: 'text/plain',
      })
    )
    a.setAttribute('download', 'menu.json')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

export const eraseOldData = (menuIncoming) => {
  const menuObj = JSON.parse(JSON.stringify(menuIncoming))
  const fieldsToErase = ['catName', 'subcatName', 'itemName', 'description', 'restName', 'menuName']
  const doErase = (obj) => {
    for (const el of Object.keys(obj)) {
      fieldsToErase.forEach((field) => {
        if (el === field) {
          obj[field] = ''
        }
      })
    }
    obj.subItems && obj.subItems.forEach((subItem) => doErase(subItem))
    return obj
  }
  return doErase(menuObj)
}

export const findTargetById = (currentNode, targetId) => {
  // console.log('findTargetById currentNode :>> ', currentNode)
  if (currentNode.id && targetId === currentNode.id) {
    return currentNode
  }
  if (currentNode.subItems) {
    for (const item of currentNode.subItems) {
      const check = findTargetById(item, targetId)
      if (check) {
        return check
      }
    }
  }
  return null
}

export const findTargetByIdLocale = (currentNode, targetId, lang) => {
  if (targetId === currentNode.id && currentNode.lang === lang) {
    return currentNode
  }
  if (currentNode.subItems) {
    for (const item of currentNode.subItems) {
      const check = findTargetByIdLocale(item, targetId)
      if (check) {
        return check
      }
    }
  }
  return null
}

export const getMeasure = (letter, measureUnits) => {
  let res
  switch (letter) {
    case 'v':
      res = measureUnits.volume
      break
    case 't':
      res = measureUnits.time
      break
    case 'q':
      res = measureUnits.quantity
      break
    case 'd':
      res = measureUnits.diameter
      break
    case 'l':
      res = measureUnits['length']
      break
    default:
      res = measureUnits.mass
      break
  }
  return res
}

export function handleItemDescriptionChange(value, userDataHook, targetID) {
  const {
    userDataRaw,
    setUserDataRaw,
    lang,
    prevItemProps,
    setPrevItemProps,
    setIsThereUnsavedChanges,
  } = userDataHook

  const newData = userDataRaw.content.map((menu, m) => {
    const langTargetItem = findTargetById(menu, targetID)
    if (langTargetItem && menu.lang === lang) {
      langTargetItem.description = value
    }
    return menu
  })

  userDataRaw.content = newData

  prevItemProps.description = value
  setPrevItemProps({ ...prevItemProps })
  userDataRaw.events.editedAt = new Date().toISOString()
  setUserDataRaw({ ...userDataRaw })
  setIsThereUnsavedChanges(true)
}

export const handleUnitNameChange = (
  name,
  value,
  menuLocals,
  setMenuLocals,
  currentLanguage,
  setIsThereUnsavedChanges,
  targetID
) => {
  const newData = menuLocals.map((menu, m) => {
    if (menu.lang === currentLanguage) {
      const targetItem = findTargetById(menu, targetID)
      if (targetItem) {
        targetItem[name] = value
      }
    }
    return menu
  })

  setMenuLocals(newData)
  setIsThereUnsavedChanges(true)
}

export function handleItemMeasureChange(name, value, userDataHook, targetID) {
  const { userDataRaw, setUserDataRaw, prevItemProps, setPrevItemProps, setIsThereUnsavedChanges } =
    userDataHook
  const newData = userDataRaw.content.map((menu, m) => {
    const langTargetItem = findTargetById(menu, targetID)

    langTargetItem[name] = value
    return menu
  })

  userDataRaw.content = newData

  prevItemProps[name] = value
  setPrevItemProps({ ...prevItemProps })
  userDataRaw.events.editedAt = new Date().toISOString()
  setUserDataRaw({ ...userDataRaw })
  setIsThereUnsavedChanges(true)
}

export function handleColorChange(name, value, userDataHook) {
  const { userDataRaw, setUserDataRaw, setIsThereUnsavedChanges } = userDataHook
  userDataRaw[name] = value
  userDataRaw.events.editedAt = new Date().toISOString()
  setUserDataRaw({ ...userDataRaw })
  setIsThereUnsavedChanges(true)
}

export function handleSocialChange(name, value, userDataHook) {
  const { userDataRaw, setUserDataRaw, setIsThereUnsavedChanges } = userDataHook
  const newUserData = value
  userDataRaw.socials[name] = newUserData
  userDataRaw.events.editedAt = new Date().toISOString()
  setUserDataRaw({ ...userDataRaw })
  setIsThereUnsavedChanges(true)
}

export function handleMeasureUnitsChange(name, value, userDataHook) {
  const { userDataRaw, setUserDataRaw, lang, setIsThereUnsavedChanges } = userDataHook

  userDataRaw.content.forEach((translation) => {
    if (translation.lang === lang) {
      translation.measureUnits[name] = value
    }
  })
  userDataRaw.events.editedAt = new Date().toISOString()
  setUserDataRaw({ ...userDataRaw })
  setIsThereUnsavedChanges(true)
}

export function handleRestaurantNameChange(value, userDataHook) {
  const { userDataRaw, setUserDataRaw, lang, setIsThereUnsavedChanges } = userDataHook
  const langContent = userDataRaw.content.filter((langObj) => langObj.lang === lang)

  langContent[0].restName = value
  userDataRaw.events.editedAt = new Date().toISOString()
  setUserDataRaw({ ...userDataRaw })
  setIsThereUnsavedChanges(true)
}

export function createItem(e, userDataHook, catIndex, subCatIndex) {
  e.preventDefault()
  const { userDataRaw, setUserDataRaw, showList, setShowList, setIsThereUnsavedChanges } =
    userDataHook
  const defaultLanguage = userDataRaw.defaultLanguage
  let defItem = null
  let _prevItemProps = null

  switch (arguments.length) {
    case 4: {
      defItem = Object.assign({}, defaultItem())
      _prevItemProps = userDataHook.prevItemProps
      break
    }
    case 3: {
      defItem = Object.assign({}, defaultSubcategory())
      break
    }
    default: {
      defItem = Object.assign({}, defaultCategory())
      break
    }
  }
  const clearDefItem = eraseOldData(defItem)
  const newUserDataRaw = userDataRaw.content.map((menu, n) => {
    const itemToPush = menu.lang === defaultLanguage ? defItem : clearDefItem
    if (_prevItemProps) {
      const propsArr = [
        'weight',
        'measure',
        'price',
        'sku',
        'handle',
        'image',
        'subVariant1',
        'subVariant2',
        'subVariant3',
      ]
      propsArr.forEach((item) => {
        itemToPush[item] = _prevItemProps[item]
      })
    }
    switch (arguments.length) {
      case 4: {
        menu.subItems[catIndex].subItems[subCatIndex].subItems.push(itemToPush)
        break
      }
      case 3: {
        menu.subItems[catIndex].subItems.push(itemToPush)
        break
      }
      default: {
        menu.subItems.push(itemToPush)
        break
      }
    }
    return menu
  })
  userDataRaw.content = newUserDataRaw
  userDataRaw.events.editedAt = new Date().toISOString()
  setUserDataRaw({ ...userDataRaw })
  setIsThereUnsavedChanges(true)
  const tempShowList = Array.from(showList)
  tempShowList.push({ name: defItem.id, isOpen: true })
  setShowList(tempShowList)
}

export function deleteItem(userDataHook, catIndex, subcatIndex, itemIndex) {
  const { userDataRaw, setUserDataRaw, showList, setShowList, setIsThereUnsavedChanges } =
    userDataHook
  let targetArray
  let targetIndex
  let targetId

  const newUserDataRaw = userDataRaw.content.map((menu, n) => {
    switch (arguments.length) {
      case 4: {
        targetArray = menu.subItems[catIndex].subItems[subcatIndex].subItems
        targetIndex = itemIndex
        targetId = menu.subItems[catIndex].subItems[subcatIndex].subItems[itemIndex]
        break
      }
      case 3: {
        targetArray = menu.subItems[catIndex].subItems
        targetIndex = subcatIndex
        targetId = menu.subItems[catIndex].subItems[subcatIndex]
        break
      }
      default: {
        targetArray = menu.subItems
        targetIndex = catIndex
        targetId = menu.subItems[catIndex]
        break
      }
    }
    targetArray.splice(targetIndex, 1)
    return menu
  })
  userDataRaw.content = newUserDataRaw
  const tempShowList = Array.from(showList).filter((item) => item.id !== targetId)
  userDataRaw.events.editedAt = new Date().toISOString()
  setUserDataRaw({ ...userDataRaw })
  setIsThereUnsavedChanges(true)
  setShowList(tempShowList)
  return true
}

export function deleteLogo(userDataHook) {
  if (!userDataHook || !userDataHook.userDataRaw || !userDataHook.setUserDataRaw) {
    return false
  } else {
    const { userDataRaw, setUserDataRaw, setIsThereUnsavedChanges } = userDataHook
    userDataRaw.logo = ''
    userDataRaw.events.editedAt = new Date().toISOString()
    setUserDataRaw({ ...userDataRaw })
    setIsThereUnsavedChanges(true)
    return true
  }
}

export const addLanguage = (newLang, userDataHook) => {
  const { userDataRaw, setUserDataRaw, setLang, setIsThereUnsavedChanges } = userDataHook
  const menuDef = userDataRaw.content.filter(
    (langObj) => langObj.lang === userDataRaw.defaultLanguage
  )
  const newLangMenu = { ...menuDef[0] }
  newLangMenu.lang = newLang
  newLangMenu.isPublished = false
  const cleanNewMenu = eraseOldData(newLangMenu)
  userDataRaw.content.push(cleanNewMenu)
  userDataRaw.events.editedAt = new Date().toISOString()
  setUserDataRaw({ ...userDataRaw })
  setIsThereUnsavedChanges(true)
  setLang(newLang)
}

export const deleteLanguage = (userDataHook) => {
  const { userDataRaw, setUserDataRaw, lang, setLang, setIsThereUnsavedChanges } = userDataHook
  userDataRaw.content.forEach((menuItem, m) => {
    if (menuItem.lang === lang) {
      userDataRaw.content.splice(m, 1)
    }
  })
  userDataRaw.events.editedAt = new Date().toISOString()
  setUserDataRaw({ ...userDataRaw })
  setIsThereUnsavedChanges(true)
  setLang(userDataRaw.defaultLanguage)
  return true
}

export const updateShowList = (json, setShowList) => {
  const idBuffer = []
  const findID = (obj) => {
    if (obj.id) {
      idBuffer.push(obj.id)
    }
    if (obj.subItems) {
      for (const item of obj.subItems) {
        findID(item)
      }
    }
  }
  findID(json.content[0])
  const showList = idBuffer.map((item, i) => ({ name: item, isOpen: true }))
  setShowList(showList)
}

export const expandAllItems = (showList, setShowList, setIsLocalsLoading) => {
  setIsLocalsLoading(true)
  const newShowList = showList.map((item, i) => {
    item.isOpen = true
    return item
  })
  setShowList(newShowList)
}

export const rollUpAllItems = (showList, setShowList, setIsLocalsLoading) => {
  setIsLocalsLoading(true)
  const newShowList = showList.map((item, i) => {
    item.isOpen = false
    return item
  })
  setShowList(newShowList)
}

export const openizator = (id, showList, setShowList) => {
  const newShowList = showList.map((item, i) => {
    if (item.name === id) {
      item.isOpen = !item.isOpen
    }
    return item
  })
  setShowList(newShowList)
}

export const getBuildMenuStatus = (events) => {
  const now = new Date()
  const tempEvents = events ? events : {}

  const eventsArray = Object.entries(tempEvents)
    .map((v) => v[1] && { name: v[0], time: v[1] })
    .filter((item) => item !== null)

  const diffs = eventsArray.map((el, e) => {
    el.time = differenceInMilliseconds(now, parseISO(el.time))
    return el
  })
  diffs.sort((a, b) => +a.time - +b.time)

  if (diffs.length) {
    switch (diffs[0].name) {
      case 'buildedAt':
        return 1
      case 'publishedAt':
        return 0
      case 'failedAt':
        return 2
      case 'editedAt':
        if (diffs.length > 1) {
          switch (diffs[1].name) {
            case 'publishedAt':
              return 0
            case 'buildedAt':
              return 1
            case 'failedAt':
              return 2
            default:
              return 0
          }
        }
        return 0
      default:
        return 0
    }
  }
  return 0
}

/**
 *
 * @param {object} events object with menu events timestamps
 * @returns [string]
 * @description array of strings with answer text
 */
export const getIndicator = (events, language, t) => {
  let _locale
  switch (language) {
    case 'ru':
      _locale = ru
      break

    case 'uk':
      _locale = uk
      break

    case 'en':
      _locale = enGB
      break

    default:
      _locale = enGB
      break
  }

  const now = new Date()

  const getDistance = (el) => {
    const dt = parseISO(el)
    if (differenceInDays(now, dt) > 0) {
      return format(dt, 'PPpp', { locale: _locale })
    } else {
      return formatDistance(dt, now, {
        includeSeconds: true,
        addSuffix: true,
        locale: _locale,
      })
    }
  }

  const tempEvents = events ? events : {}

  const eventsArray = Object.entries(tempEvents)
    .map((v) => v[1] && { name: v[0], time: v[1] })
    .filter((item) => item !== null)

  const diffs = eventsArray.map((el, e) => {
    el.time = differenceInMilliseconds(now, parseISO(el.time))
    return el
  })
  diffs.sort((a, b) => +a.time - +b.time)

  let result = []

  if (diffs.length) {
    if (diffs.length === 1) {
      result = [t('INDICATOR_CREATED', { dt: getDistance(events.createdAt) })]
    } else if (diffs.length === 2) {
      result =
        diffs[0].time === diffs[1].time
          ? [t('INDICATOR_CREATED', { dt: getDistance(events.createdAt) })]
          : [t('INDICATOR_EDITED', { dt: getDistance(events.editedAt) })]
    } else {
      switch (diffs[0].name) {
        case 'buildedAt':
          result = [t('INDICATOR_BUILD_IN_PROGRESS')]
          break
        case 'publishedAt':
          result = [t('INDICATOR_PUBLISHED', { dt: getDistance(events.publishedAt) })]
          break
        case 'failedAt':
          result = [
            t('INDICATOR_BUILD_FAILED_1', { dt: getDistance(events.failedAt) }),
            t('INDICATOR_BUILD_FAILED_2'),
          ]
          break
        case 'editedAt':
          switch (diffs[1].name) {
            case 'publishedAt':
              result = [
                t('INDICATOR_PUBLISHED_BUT'),
                t('INDICATOR_EDITED', { dt: getDistance(events.editedAt) }),
              ]
              break
            case 'buildedAt':
              result = [
                t('INDICATOR_PROGRESS_BUT'),
                t('INDICATOR_EDITED', { dt: getDistance(events.editedAt) }),
              ]
              break
            case 'failedAt':
              result = [
                t('INDICATOR_FAILED_BUT'),
                t('INDICATOR_EDITED', { dt: getDistance(events.editedAt) }),
              ]
              break
            default:
              break
          }
          break
        default:
          break
      }
    }
  }
  return result
}

// connect to lambdas

export const createUser = async (token, userAuthId, userName, email) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/createUser',
      data: {
        userAuthId,
        userName,
        email,
      },
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('createUser Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const getUser = async (token, userAuthId) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/getUser',
      data: {
        userAuthId: userAuthId,
      },
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('getUser Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const createMenu = async (token, userAuthId, alias, customDefaultLang, newMenu) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/createMenu',
      data: {
        userAuthId: userAuthId,
        alias: alias,
        defaultLang: customDefaultLang,
        userMenu: newMenu,
      },
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('createMenu Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const deleteMenu = async (token, userAuthId, menuId) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/deleteMenu',
      data: {
        userAuthId: userAuthId,
        menuId: menuId,
      },
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('deleteMenu Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const getMenu = async (token, userAuthId, menuId) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/getMenu',
      data: {
        userAuthId: userAuthId,
        menuId: menuId,
      },
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('getMenu Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const updateMenu = async (token, userAuthId, menuData) => {
  try {
    const data = {
      userAuthId: userAuthId,
      menuData: menuData,
    }
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/updateMenu',
      data,
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('updateMenu Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const getAuthUser = async (token, userAuthId) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/getAuthUser',
      data: {
        userAuthId: userAuthId,
      },
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('getAuthUser Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const publishMenu = async (token, userAuthId, menuId, alias) => {
  try {
    const data = {
      userAuthId: userAuthId,
      menuId: menuId,
      alias: alias,
    }
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/publishMenu',
      data,
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })

    return JSON.parse(res.data)
  } catch (e) {
    console.error('publishMenu Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const getPdfData = async (token, userAuthId, menuId, lang) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/getPdfData',
      data: {
        userAuthId: userAuthId,
        menuId: menuId,
        lang: lang,
      },
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('getUser Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const checkIsAliasUnique = async (token, word) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/checkAlias',
      data: {
        word: word,
      },
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('checkIsAliasUnique Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const uploadLogo = async (token, imagePathString) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/uploadLogo',
      // data: { imageBase64Str },
      data: { imagePathString },
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('uploadLogo Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const getSummary = async (token, userAuthId) => {
  try {
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/getSummary',
      data: {
        userAuthId: userAuthId,
      },
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('getSummary Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

export const importCsvMenu = async (menuLocals, file) => {
  if (!file) {
    return false
  }

  try {
    let csvData = await Papa.parsePromise(file, { header: true })
    console.log({ csvData })

    const validationError = validateImportCsvMenu(csvData.data).error
    if (validationError) {
      console.error({ validationError })
      throw new Error('Invalid CSV')
    }

    // Группируем CSV данные по Handle
    csvData.data = Object.values(
      csvData.data.reduce((acc, item) => {
        if (!item['Handle']) return acc
        if (!acc[item['Handle']]) {
          acc[item['Handle']] = { ...item, variants: [item] }
        } else {
          acc[item['Handle']].variants.push(item)
        }
        return acc
      }, {})
    )

    if (menuLocals.length === 0) {
      throw new Error('Menu is empty')
    }

    let _menuLocal = JSON.parse(JSON.stringify(menuLocals[0]))
    const csvItems = new Map()
    const movedItems = new Map() // Для хранения перемещаемых элементов

    // Создаем Map из CSV элементов для быстрого поиска
    csvData.data.forEach((item) => {
      if (!item['Category']) return
      const [catName, subcatName = ''] = item['Category'].split('__')
      csvItems.set(item['SKU'], {
        item,
        catName,
        subcatName,
      })
    })

    // 1. Обновляем существующие элементы и собираем элементы для перемещения
    _menuLocal.subItems = _menuLocal.subItems.map((category) => {
      category.subItems = category.subItems.map((subcategory) => {
        subcategory.subItems = subcategory.subItems
          .map((item) => {
            if (!item) return null // Skip empty items
            const csvItem = csvItems.get(item.sku)
            if (csvItem) {
              // Проверяем, изменилась ли категория или субкатегория
              if (
                csvItem.catName !== category.catName ||
                csvItem.subcatName !== subcategory.subcatName
              ) {
                movedItems.set(item.sku, {
                  item: createItemFromCsv(item, csvItem.item),
                  catName: csvItem.catName,
                  subcatName: csvItem.subcatName,
                })
                csvItems.delete(item.sku)
                return null // Удаляем из текущей позиции
              }
              csvItems.delete(item.sku)
              return createItemFromCsv(item, csvItem.item)
            }
            return null
          })
          .filter(Boolean) // Remove null/undefined items
        return subcategory
      })
      return category
    })

    // 2. Удаляем пустые субкатегории
    _menuLocal.subItems = _menuLocal.subItems.map((category) => {
      category.subItems = category.subItems.filter(
        (subcategory) => subcategory && subcategory.subItems && subcategory.subItems.length > 0
      )
      return category
    })

    // 3. Удаляем пустые категории
    _menuLocal.subItems = _menuLocal.subItems.filter(
      (category) => category && category.subItems && category.subItems.length > 0
    )

    // 4. Добавляем перемещенные и новые элементы
    const allNewItems = [...Array.from(movedItems.values()), ...Array.from(csvItems.values())]
    if (allNewItems.length > 0) {
      allNewItems.forEach(({ item, catName, subcatName }) => {
        if (!item || !catName) return // Skip invalid items

        let category = _menuLocal.subItems.find((c) => c && c.catName === catName)
        if (!category) {
          category = { id: uuid(), isVisible: true, catName, subItems: [] }
          _menuLocal.subItems.push(category)
        }

        let subcategory = category.subItems.find((s) => s && s.subcatName === subcatName)
        if (!subcategory) {
          subcategory = { id: uuid(), isVisible: true, subcatName, subItems: [] }
          category.subItems.push(subcategory)
        }

        const newItem =
          item instanceof Object && item.hasOwnProperty('sku')
            ? item
            : createItemFromCsv(null, item)
        if (newItem) {
          subcategory.subItems.push(newItem)
        }
      })
    }

    // Final cleanup of any remaining empty items
    _menuLocal.subItems = _menuLocal.subItems.filter(Boolean)

    return { success: true, data: [_menuLocal] }
  } catch (e) {
    console.error('importCsvMenu Error:\n', e.message)
    return { success: false, error: e.message }
  }
}

function createItemFromCsv(oldItem, newItem) {
  return {
    id: uuid(),
    isVisible: newItem['Available for sale [Moonhut Beach Restaurant]'] === 'Y',
    itemName: newItem['Name'] ? newItem['Name'].split('[')[0]?.trim() : '',
    description: trimAllTagsSafe(newItem['Description'] || ''),
    weight: newItem['Weight'] || '',
    measure: 'm',
    price: !isNaN(+newItem['Price [Moonhut Beach Restaurant]'])
      ? (+newItem['Price [Moonhut Beach Restaurant]'] || 0).toString()
      : '0',
    sku: newItem['SKU'] || '',
    handle: newItem['Handle'] || '',
    image: oldItem?.image || '',
    subVariant1: newItem?.variants?.length
      ? {
          name: newItem['Option 1 name'] || '',
          values: newItem.variants
            .filter((v) => v['Option 1 value'])
            .map((v) => ({
              id: uuid(),
              isVisible: v?.['Available for sale [Moonhut Beach Restaurant]'] === 'Y',
              subVariantName: v?.['Option 1 value'] || '',
              price: !isNaN(+v?.['Price [Moonhut Beach Restaurant]'])
                ? (+v?.['Price [Moonhut Beach Restaurant]'] || 0).toString()
                : '0',
              sku: v?.['SKU'] || '',
            }))
            .sort((a, b) => parseFloat(a.price) - parseFloat(b.price)),
        }
      : null,
    subVariant2: newItem?.variants?.length
      ? {
          name: newItem['Option 2 name'],
          values: newItem.variants
            .filter((v) => v['Option 2 value'])
            .map((v) => ({
              id: uuid(),
              isVisible: v?.['Available for sale [Moonhut Beach Restaurant]'] === 'Y',
              subVariantName: v?.['Option 2 value'] || '',
              price: !isNaN(+v?.['Price [Moonhut Beach Restaurant]'])
                ? (+v?.['Price [Moonhut Beach Restaurant]'] || 0).toString()
                : '0',
              sku: v?.['SKU'] || '',
            }))
            .sort((a, b) => parseFloat(a.price) - parseFloat(b.price)),
        }
      : null,
    subVariant3: newItem?.variants?.length
      ? {
          name: newItem['Option 3 name'],
          values: newItem.variants
            .filter((v) => v['Option 3 value'])
            .map((v) => ({
              id: uuid(),
              isVisible: v?.['Available for sale [Moonhut Beach Restaurant]'] === 'Y',
              subVariantName: v?.['Option 3 value'] || '',
              price: !isNaN(+v?.['Price [Moonhut Beach Restaurant]'])
                ? (+v?.['Price [Moonhut Beach Restaurant]'] || 0).toString()
                : '0',
              sku: v?.['SKU'] || '',
            }))
            .sort((a, b) => parseFloat(a.price) - parseFloat(b.price)),
        }
      : null,
  }
}

export const trimAllTags = (str = '') => {
  return str.replace(/<[^>]+>/g, '')
}

export const trimAllTagsSafe = (str = '') => {
  try {
    return new DOMParser().parseFromString(str, 'text/html').documentElement.textContent
  } catch (e) {
    console.error('trimAllTags Error:\n', e.message)
    return str
  }
}

export const swapAliases = async (token, userAuthId, alias1, alias2) => {
  try {
    const data = {
      userAuthId,
      alias1,
      alias2,
    }
    const res = await axios({
      method: 'POST',
      url: '/.netlify/functions/swapAliases',
      data,
      responseType: 'text',
      headers: { Authorization: `Bearer ${token}` },
    })
    return JSON.parse(res.data)
  } catch (e) {
    console.error('swapAliases Error:\n', e.message)
    return { success: false, error: e.message }
  }
}
