import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const WeAreCookiesUsers = ({ history }) => {
  const [isCookieExists, setIsCookieExists] = useState(true)
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const found = document.cookie.split('; ').find((row) => row.startsWith('gimme_cookies=true'))
    setIsCookieExists(found)
  }, [isCookieExists])

  const handlePressOk = () => {
    if (!isCookieExists) {
      const newCookie = 'gimme_cookies=true; SameSite=None; Secure; max-age=15552000000'
      document.cookie = newCookie
      setIsCookieExists(true)
    }
  }

  const handleClickLink = () => {
    history.push('/privacy-policy')
    setTimeout(window.scroll(0, 0), 0)
  }

  return (
    <div
      className={`cookies-warning-container ${i18n.language}${!isCookieExists ? ' showtime' : ''}`}
    >
      <div className='text-block'>
        <p>
          {t('COOKIE_TEXT')} <span onClick={handleClickLink}>{t('COOKIE_LINK_TEXT')}</span>
        </p>
      </div>
      <div className='button-block'>
        <div className='ok-button' onClick={handlePressOk}>
          <p>OK</p>
        </div>
      </div>
    </div>
  )
}

export default withRouter(WeAreCookiesUsers)
