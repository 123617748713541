import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter as Router } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import i18n from './i18n'

const {
  REACT_APP_DOMAIN,
  REACT_APP_CLIENT_ID,
  REACT_APP_JWT_AUDIENCE,
  REACT_APP_AUTH0_SCOPE,
  REACT_APP_BASE_URL,
  REACT_APP_GTM_MANAGER_TAG,
} = process.env

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_MANAGER_TAG,
}

TagManager.initialize(tagManagerArgs)

const Auth0Wrapper = ({ children }) => {
  const originUrl = window ? window.location.origin : REACT_APP_BASE_URL
  return (
    <Auth0Provider
      domain={REACT_APP_DOMAIN}
      clientId={REACT_APP_CLIENT_ID}
      audience={REACT_APP_JWT_AUDIENCE}
      scope={REACT_APP_AUTH0_SCOPE}
      redirectUri={`${originUrl}/my`}
      prompt='select_account'
      ui_locales={i18n.languages.join(' ')}
    >
      <Router>{children}</Router>
    </Auth0Provider>
  )
}

ReactDOM.render(
  <Auth0Wrapper>
    <App />
  </Auth0Wrapper>,
  document.getElementById('root')
)
