import React from 'react'
import HeroCtaGiftIcon from '../img/hero-cta-gift-icon.svg'
import { useAuth0 } from '@auth0/auth0-react'

const V2CtaButton = ({ buttonTitle, text }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  return isAuthenticated ? null : (
    <div className='cta-container'>
      <div className='v2-button' onClick={() => loginWithRedirect({ screen_hint: 'signup' })}>
        {buttonTitle}
      </div>
      <div className='cta-offer'>
        <img src={HeroCtaGiftIcon} alt='giftbox' />
        <span>{text}</span>
      </div>
    </div>
  )
}

export default V2CtaButton
