import React from 'react'
const IconOpentable = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill={color}
    >
      <path d='m 8.236088,11.325043 c -1.89819,-0.1835 -3.45012,-1.5832005 -3.86114,-3.4823005 -0.0806,-0.3727 -0.0868,-1.2317 -0.0116,-1.6345 0.28964,-1.5527 1.42123,-2.8527 2.91713,-3.3514 0.49668,-0.1655 0.74344,-0.2017 1.37658,-0.2017 0.49721,0 0.61252,0.01 0.85535,0.061 1.75467,0.3793 3.03368,1.6486 3.42555,3.3995 0.0557,0.249 0.0626,0.3466 0.062,0.8814 -5.4e-4,0.5208 -0.008,0.6365 -0.0593,0.8623 -0.13432,0.5971 -0.39194,1.1861 -0.72188,1.6503 -0.2308,0.3248 -0.69702,0.7911005 -1.02245,1.0227005 -0.84882,0.6042 -1.927,0.893 -2.96026,0.7931 z m 0.64015,-3.2622005 c 0.33784,-0.071 0.62031,-0.2906 0.77288,-0.6005 0.0711,-0.1444 0.0891,-0.2169 0.0985,-0.3971 0.0251,-0.4775 -0.20333,-0.8696 -0.62396,-1.0712 -0.20882,-0.1001 -0.57081,-0.1244 -0.79131,-0.053 -0.30842,0.1 -0.53188,0.298 -0.67393,0.5981 -0.0768,0.1621 -0.0838,0.1995 -0.0831,0.4443 8.6e-4,0.34 0.0632,0.5109 0.26663,0.7306 0.28147,0.304 0.65268,0.4292 1.03431,0.3489 z m -7.10208,-0.034 c -0.30992,-0.098 -0.55331,-0.3092 -0.68962,-0.5976 -0.0773,-0.1636 -0.0851,-0.2035 -0.0845,-0.4341 8.6e-4,-0.3334 0.0654,-0.5018 0.28038,-0.7316 0.26377,-0.282 0.61747,-0.4079 0.96451,-0.3434 0.94817,0.1764 1.2302,1.3533 0.46412,1.9368 -0.24083,0.1835 -0.65484,0.2587 -0.93489,0.1699 z' />
    </svg>
  )
}
export default IconOpentable
