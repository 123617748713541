import React, { useState, useEffect, useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import { useDebouncedCallback } from 'use-debounce'
import { ntfWarn } from '../helpers/notifications'
import { validateRestName } from '../validation/validate'
import { placeholders } from '../data'
import {
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
const { REACT_APP_DEBOUNCE_TIMOUT } = process.env

const RestaurantName = () => {
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { currentLanguage } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const { t } = useTranslation()
  const [restNameValue, setRestNameValue] = useState(null)

  useEffect(() => {
    if (menuLocals && currentLanguage) {
      setRestNameValue(menuLocals.find((local) => local.lang === currentLanguage).restName)
    }
  }, [menuLocals, currentLanguage])

  const updateMenuLocals = (value) => {
    setMenuLocals(
      menuLocals.map((local) => {
        if (local.lang === currentLanguage) {
          local.restName = value
        }
        return local
      })
    )
  }

  const debouncedUpdateMenuLocals = useDebouncedCallback((value) => {
    updateMenuLocals(value)
  }, REACT_APP_DEBOUNCE_TIMOUT)

  return (
    <Row className='rest-name-container top-line'>
      <Col id='rest-name' className='rest-name' xl>
        <InputGroup size='md'>
          {restNameValue !== null ? (
            <>
              <InputGroup.Prepend>
                <InputGroup.Text>{t('CON_NAME_TEXT')}</InputGroup.Text>
              </InputGroup.Prepend>
              <input
                className='form-control'
                placeholder={placeholders.restname}
                id='rest-name-input'
                name='restName'
                disabled={isLocalsLoading}
                value={restNameValue}
                onChange={(e) => {
                  const value = e.target.value
                  const error = validateRestName(value).error
                  if (!error) {
                    setIsThereUnsavedChanges(true)
                    setRestNameValue(value)
                    debouncedUpdateMenuLocals(value)
                  } else {
                    ntfWarn({
                      title: t('MESSAGE_ERROR_TITLE'),
                      message: t('MESSAGE_24_CHARACTERS_MAX'),
                    })
                  }
                }}
              />
            </>
          ) : (
            <div className='skeleton-container'>
              <Skeleton />
            </div>
          )}
        </InputGroup>
      </Col>
    </Row>
  )
}

export default RestaurantName
