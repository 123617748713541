import React from 'react'

const IconLogout = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M15.4972 7.50046H9.16405C8.88805 7.50046 8.66406 7.27647 8.66406 7.00047C8.66406 6.72448 8.88805 6.50049 9.16405 6.50049H15.4972C15.7732 6.50049 15.9972 6.72448 15.9972 7.00047C15.9972 7.27647 15.7732 7.50046 15.4972 7.50046Z'
          fill={color}
        />
        <path
          d='M12.9999 10.0005C12.8718 10.0005 12.7439 9.9518 12.6465 9.85391C12.4512 9.65848 12.4512 9.34184 12.6465 9.14653L14.7931 7L12.6465 4.85334C12.4512 4.65803 12.4512 4.34139 12.6465 4.14608C12.8419 3.95065 13.1585 3.95065 13.3539 4.14608L15.8538 6.646C16.0491 6.84131 16.0491 7.15795 15.8538 7.35326L13.3539 9.85318C13.2558 9.9518 13.1279 10.0005 12.9999 10.0005Z'
          fill={color}
        />
        <path
          d='M5.33321 16.0005C5.19051 16.0005 5.05514 15.9805 4.91989 15.9385L0.907931 14.6018C0.362049 14.4112 0 13.9025 0 13.3339V1.33431C0 0.59898 0.598004 0.000976562 1.33333 0.000976562C1.47591 0.000976562 1.61128 0.0209955 1.74665 0.0629864L5.75849 1.39961C6.30449 1.59028 6.66642 2.09893 6.66642 2.66752V14.6671C6.66642 15.4025 6.06854 16.0005 5.33321 16.0005ZM1.33333 1.00095C1.14999 1.00095 0.999969 1.15097 0.999969 1.33431V13.3339C0.999969 13.4759 1.0953 13.6079 1.23128 13.6552L5.22445 14.9859C5.25313 14.9951 5.29048 15.0005 5.33321 15.0005C5.51655 15.0005 5.66645 14.8505 5.66645 14.6671V2.66752C5.66645 2.52556 5.57112 2.3936 5.43513 2.34624L1.44197 1.01559C1.41329 1.00632 1.37593 1.00095 1.33333 1.00095Z'
          fill={color}
        />
        <path
          d='M10.165 5.3337C9.889 5.3337 9.66501 5.1097 9.66501 4.83371V1.83381C9.66501 1.37447 9.29112 1.00046 8.83178 1.00046H1.33202C1.05602 1.00046 0.832031 0.776465 0.832031 0.500473C0.832031 0.22448 1.05602 0.000488281 1.33202 0.000488281H8.83178C9.8431 0.000488281 10.665 0.822484 10.665 1.83381V4.83371C10.665 5.1097 10.441 5.3337 10.165 5.3337Z'
          fill={color}
        />
        <path
          d='M8.83462 14.0002H6.16795C5.89196 14.0002 5.66797 13.7762 5.66797 13.5002C5.66797 13.2242 5.89196 13.0002 6.16795 13.0002H8.83462C9.29396 13.0002 9.66784 12.6262 9.66784 12.1669V9.16698C9.66784 8.89098 9.89184 8.66699 10.1678 8.66699C10.4438 8.66699 10.6678 8.89098 10.6678 9.16698V12.1669C10.6678 13.1782 9.84594 14.0002 8.83462 14.0002Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconLogout
