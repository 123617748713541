import React from 'react'

const IconNotes = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
    >
      <path
        d='M17.6773 2.16252H14.6653V1.54845C14.6653 0.694637 13.9707 0 13.1169 0H6.88314C6.02932 0 5.33469 0.694637 5.33469 1.54845V2.16252H2.32268C1.04196 2.16252 0 3.20448 0 4.4852V24.1043C0 25.385 1.04196 26.427 2.32268 26.427H17.6773C18.958 26.427 20 25.385 20 24.1043V4.4852C20 3.20448 18.958 2.16252 17.6773 2.16252ZM6.88314 1.54845H13.1169L13.1179 3.96539C13.1179 3.96539 13.1176 3.96544 13.1169 3.96544H6.88314V1.54845ZM18.4515 24.1043C18.4515 24.5312 18.1042 24.8785 17.6773 24.8785H2.32263C1.89572 24.8785 1.5484 24.5312 1.5484 24.1043V8.96798H18.4515V24.1043ZM18.4515 7.41952H1.5484V4.4852C1.5484 4.05829 1.89572 3.71098 2.32263 3.71098H5.33463V3.96544C5.33463 4.81926 6.02927 5.51389 6.88309 5.51389H13.1168C13.9706 5.51389 14.6653 4.81926 14.6653 3.96544V3.71098H17.6773C18.1042 3.71098 18.4515 4.05829 18.4515 4.4852V7.41952Z'
        fill={color}
      />
      <path
        d='M11.7497 11.9683H4.71759C4.29001 11.9683 3.94336 12.3149 3.94336 12.7425C3.94336 13.1701 4.29001 13.5167 4.71759 13.5167H11.7497C12.1773 13.5167 12.524 13.1701 12.524 12.7425C12.524 12.3149 12.1773 11.9683 11.7497 11.9683Z'
        fill={color}
      />
      <path
        d='M11.7497 15.9092H4.71759C4.29001 15.9092 3.94336 16.2558 3.94336 16.6834C3.94336 17.111 4.29001 17.4576 4.71759 17.4576H11.7497C12.1773 17.4576 12.524 17.111 12.524 16.6834C12.524 16.2558 12.1773 15.9092 11.7497 15.9092Z'
        fill={color}
      />
      <path
        d='M11.7497 19.8506H4.71759C4.29001 19.8506 3.94336 20.1972 3.94336 20.6248C3.94336 21.0524 4.29001 21.399 4.71759 21.399H11.7497C12.1773 21.399 12.524 21.0524 12.524 20.6248C12.524 20.1972 12.1773 19.8506 11.7497 19.8506Z'
        fill={color}
      />
      <path
        d='M15.248 11.9683H14.3074C13.8799 11.9683 13.5332 12.3149 13.5332 12.7425C13.5332 13.1701 13.8799 13.5167 14.3074 13.5167H15.248C15.6755 13.5167 16.0222 13.1701 16.0222 12.7425C16.0222 12.3149 15.6756 11.9683 15.248 11.9683Z'
        fill={color}
      />
      <path
        d='M15.248 15.9092H14.3074C13.8799 15.9092 13.5332 16.2558 13.5332 16.6834C13.5332 17.111 13.8799 17.4576 14.3074 17.4576H15.248C15.6755 17.4576 16.0222 17.111 16.0222 16.6834C16.0222 16.2558 15.6756 15.9092 15.248 15.9092Z'
        fill={color}
      />
      <path
        d='M15.248 19.8506H14.3074C13.8799 19.8506 13.5332 20.1972 13.5332 20.6248C13.5332 21.0524 13.8799 21.399 14.3074 21.399H15.248C15.6755 21.399 16.0222 21.0524 16.0222 20.6248C16.0222 20.1972 15.6756 19.8506 15.248 19.8506Z'
        fill={color}
      />
    </svg>
  )
}

export default IconNotes
