import React from 'react'
import secondaryImg1 from '../../img/secondary-page-img_01.png'
import secondaryImg2 from '../../img/secondary-page-img_02.png'
import secondaryImg3 from '../../img/secondary-page-img_03.png'

const CompanyPage = () => {
  return (
    <section className='secondary-page company-page'>
      <div className='text'>
        <h1>Our Mission</h1>
        <p>
          <b>Gimme Menu</b> is a one-stop solution for online and QR menus developed by{' '}
          <b>PozitiFF Company</b> s.r.o. in 2020. It’s a straightforward way to create, edit, and
          manage online menus to better serve your clients and grow your foodservice business with
          online marketing.
        </p>
        <p>
          During the pandemic, QR menus turned from a nifty and useful novelty into one of the
          essentials for every foodservice business – be it a world-famous restaurant or a
          family-owned food truck. But apart from providing extra safety to the client, the benefits
          of an online menu go much further than that:
        </p>
        <ul>
          <li>
            It can be changed and modified to include new, special, and seasonal dishes or
            propositions;
          </li>
          <li>The price and portion size can be adjusted to fit the demand;</li>
          <li>Better service for the clients, who check out the menu at their own leisure;</li>
          <li>And all of it with no cost added and in fact can save quite a bit of overhead.</li>
        </ul>
        <p>
          The extraordinary circumstance of our time might have brought QR menus into fashion, but
          the benefits offered are sure to make them stay. The lockdown wasn’t easy for the
          foodservice industry worldwide, especially the smaller businesses.
        </p>
        <p>
          With <strong>Gimme Menu</strong>, you can manage a multitude of menus at once or make your
          first ever online menu. Customize it to fit your brand image, or make it look striking
          with very little effort. No special skills are needed. At <b>PozitiFF</b>, we emphasize
          streamlining the design and user experience. We aim to provide straightforward and
          flexible tools for our clients.
        </p>
        <p className='last-p'>Create your own special online menu in a snap with Gimme Menu!</p>
      </div>
      <div className='pattern-container sm'>
        <img src={secondaryImg1} alt='foood-patttern1' />
        <img src={secondaryImg2} alt='foood-patttern2' />
        <img src={secondaryImg3} alt='foood-patttern3' />
      </div>
    </section>
  )
}

export default CompanyPage
