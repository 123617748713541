import React from 'react'

const IconDrag = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 -1 9 26'
      fill='none'
    >
      <path
        d='M4.51202 24.0633L8.68389 18.2976C8.77295 18.1734 8.68389 18 8.53155 18H7.43233C7.19326 18 6.96592 18.1148 6.8253 18.3094L4.35967 21.7172L1.89405 18.3094C1.75342 18.1148 1.52842 18 1.28702 18H0.187797C0.0354531 18 -0.0536094 18.1734 0.0354531 18.2976L4.20733 24.0633C4.22484 24.0872 4.24777 24.1067 4.27424 24.1202C4.30071 24.1336 4.32998 24.1406 4.35967 24.1406C4.38936 24.1406 4.41863 24.1336 4.4451 24.1202C4.47157 24.1067 4.4945 24.0872 4.51202 24.0633Z'
        fill={color}
      />
      <path
        d='M8.68158 5.84297L4.50969 0.0773435C4.43469 -0.0257816 4.28234 -0.0257816 4.20734 0.0773435L0.0354532 5.84297C-0.0536096 5.96719 0.0354532 6.14062 0.187798 6.14062H1.28702C1.52608 6.14062 1.75343 6.02578 1.89405 5.83125L4.35734 2.42344L6.82298 5.83125C6.9636 6.02578 7.1886 6.14062 7.43001 6.14063H8.52923C8.68158 6.14063 8.77064 5.96719 8.68158 5.84297Z'
        fill={color}
      />
    </svg>
  )
}

export default IconDrag
