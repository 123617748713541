import React, { useState, useContext, useEffect, useMemo, memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import {
  RootSetterContext,
  ConstructorGetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
  ConstructorSetterContext,
} from '../context/userContext'
import icons from '../img/icons/index'
import { useIsDesktop } from '../helpers/hooks'
import { findTargetById } from '../helpers'
import { useAuth0 } from '@auth0/auth0-react'
const { IconExport, IconRemoveItem } = icons

const {
  REACT_APP_CLOUDINARY_CLOUD_NAME,
  REACT_APP_CLOUDINARY_API_KEY,
  REACT_APP_CLOUDINARY_UPLOAD_PRESET,
} = process.env

const ItemImageLoader = ({
  incomingId,
  incomingIndex,
  incomingParentIndex,
  incomingGrandParentIndex,
}) => {
  const { user, isAuthenticated } = useAuth0()
  const { setShowRemoveModal } = useContext(RootSetterContext)
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { currentLanguage } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const [uploadWidget, setUploadWidget] = useState(null)
  const isDesktop = useIsDesktop()
  const { t, i18n } = useTranslation()

  const startUploadProcess = () => {
    console.log('startUploadProcess')
    const _uploadWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
        apiKey: REACT_APP_CLOUDINARY_API_KEY,
        uploadPreset: REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        sources: ['local', 'url'],
        defaultSource: 'local',
        clientAllowedFormats: ['image'],
        showAdvancedOptions: false,
        showPoweredBy: false,
        autoMinimize: true,
        multiple: false,
        theme: 'minimal',
        styles: {
          palette: {
            window: '#fff',
            windowBorder: 'grey',
            tabIcon: '#da3600',
            menuIcons: 'grey',
            link: '#da3600',
            action: '#da3600',
            inactiveTabIcon: '#ff7a45',
            error: '#da3600',
            inProgress: 'ligthgrey',
            complete: '#17a2b8',
            sourceBg: '#fff',
            textDark: '#000',
            textLight: '#fff',
          },
          frame: {
            background: 'rgba(0, 0, 0, 0.5)',
          },
          fonts: {
            default: null,
            "'Arimo', sans-serif": {
              url: 'https://fonts.googleapis.com/css?family=Arimo:400,700&display=swap',
              active: true,
            },
          },
        },
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          setImage(`${result.info.public_id}.${result.info.format}`)
          setIsThereUnsavedChanges(true)
          _uploadWidget.close(true)
        } else {
          error && console.log('processResult error :>> ', error)
        }
      }
    )
    setUploadWidget(_uploadWidget)
    _uploadWidget.open()
  }

  const image = useMemo(() => {
    return (
      menuLocals.find((langObj) => langObj.lang === currentLanguage)?.subItems?.[
        incomingGrandParentIndex
      ]?.subItems?.[incomingParentIndex]?.subItems?.[incomingIndex]?.image || ''
    )
  }, [menuLocals, currentLanguage])

  const setImage = (value) => {
    const newData = menuLocals.map((menu, m) => {
      const langTargetItem = findTargetById(menu, incomingId)
      if (langTargetItem && menu.lang === currentLanguage) {
        langTargetItem.image = value
      }
      return menu
    })
    setMenuLocals(newData)
  }

  const handleRemoveImage = () => {
    setImage('')
    setIsThereUnsavedChanges(true)
    return true
  }

  const isAdmin = useMemo(() => {
    if (user && isAuthenticated) {
      const userRolesArray = user[`${process.env.REACT_APP_BASE_URL}/roles`]
      return userRolesArray?.includes('admin')
    } else return false
  }, [user, isAuthenticated])

  return isAdmin ? (
    <div className='custom-logo-input'>
      {image !== null ? (
        <>
          {image ? (
            <div
              size='sm'
              className={`v2-button${!isDesktop ? ' sm' : ''} v2-button-outline`}
              onClick={() => {
                setShowRemoveModal({
                  show: true,
                  name: '',
                  kind: 'image',
                  cbName: handleRemoveImage,
                  cbArgs: [],
                })
              }}
            >
              <img
                src={`https://res.cloudinary.com/${REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload/c_pad,h_25,w_25/${image}`}
                alt='item image'
                style={{ width: '25px', height: '25px' }}
              />
              <IconRemoveItem color='#ff7a45' width={isDesktop ? '24px' : '20px'} />
            </div>
          ) : (
            <div
              id='upload_widget'
              className={`v2-button${!isDesktop ? ' sm' : ''}`}
              onClick={startUploadProcess}
            >
              <IconExport color='#ffffff' width={isDesktop ? '24px' : '20px'} />
              Upload Image
            </div>
          )}
        </>
      ) : (
        <div className={`skeleton-button${!isDesktop ? ' sm' : ''}`}>
          <Skeleton />
        </div>
      )}
    </div>
  ) : null
}

export default memo(ItemImageLoader)
