import React, { useState, useContext, useMemo, useEffect } from 'react'
import { RootGetterContext, ManagerGetterContext } from '../context/userContext'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify'
import { swapAliases } from '../helpers'

const { REACT_APP_BASE_URL } = process.env

const AliasSwapper = () => {
  const [isInitialized, setIsInitialized] = useState(false)
  const [isSwapping, setIsSwapping] = useState(false)
  const [alias1, setAlias1] = useState(undefined)
  const [alias2, setAlias2] = useState(undefined)

  const { user, isAuthenticated } = useAuth0()
  const { token, isLoadingGlobal } = useContext(RootGetterContext)
  const { userObject } = useContext(ManagerGetterContext)

  const isUserCanUseSummary = useMemo(() => {
    if (user && isAuthenticated) {
      const userRolesArray = user[`${REACT_APP_BASE_URL}/roles`]
      return userRolesArray?.includes('admin')
    } else return false
  }, [user, isAuthenticated])

  const handleAlias1Change = (e) => {
    console.log(e.target.value)
    setAlias1(e.target.value)
  }

  const handleAlias2Change = (e) => {
    setAlias2(e.target.value)
  }

  const aliasOptions = useMemo(() => {
    if (userObject) {
      return userObject.menuList.map((menu) => menu.alias)
    }
    return []
  }, [userObject])

  useEffect(() => {
    if (aliasOptions.length > 1 && !isInitialized) {
      setAlias1(aliasOptions[0])
      setAlias2(aliasOptions[1])
      setIsInitialized(true)
    }
  }, [aliasOptions])

  const handleSwap = async () => {
    setIsSwapping(true)

    if (
      confirm(
        'Swapping aliases is risky. It follows by a full menu rebuild for both menus. Do you want to continue?'
      ) !== true
    ) {
      setIsSwapping(false)
      return false
    }

    const res = await swapAliases(token, userObject.id, alias1, alias2)
    if (res.success) {
      toast.success('Aliases swapped successfully')
    } else {
      toast.error(res.error)
    }
    setIsSwapping(false)
    window.location.reload()
  }

  return isInitialized && isUserCanUseSummary ? (
    <div className='flex items-center gap-1'>
      <label className='flex items-center gap-1 v2-button-outline'>
        Alias1:
        <select onChange={handleAlias1Change} value={alias1} disabled={isSwapping} className=''>
          {aliasOptions.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </label>
      <label className='flex items-center gap-1 v2-button-outline'>
        Alias2:
        <select onChange={handleAlias2Change} value={alias2} disabled={isSwapping} className=''>
          {aliasOptions.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </label>
      <button
        disabled={!alias1 || !alias2 || alias1 === alias2 || isSwapping}
        onClick={handleSwap}
        className='v2-button sm v2-button-outline disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-not-allowed disabled:hover:bg-transparent'
      >
        Swap aliases
      </button>
    </div>
  ) : null
}

export default AliasSwapper
