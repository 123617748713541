import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import { ManagerSetterContext } from '../context/userContext'
import { fullList } from '../data/langList'
import { ntfSuccess } from '../helpers/notifications'
import icons from '../img/icons/index'
import { useIsDesktop } from '../helpers/hooks'

const { IconCopy, IconExport, IconEyeVisible } = icons

const MenuLanguageBlock = ({ langName, isPub, handleSaveQrZip, handleSaveMdZip, url }) => {
  const { setQrPopup } = useContext(ManagerSetterContext)
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()

  const langLocalName = fullList.find((item) => item.value === langName)?.label

  return (
    <div className='language-url-qr'>
      {langName ? (
        <h4>{t('MANAGER_MENU_ITEM_LANGUAGE', { langLocalName: langLocalName })}</h4>
      ) : (
        <h4>
          <Skeleton />
        </h4>
      )}
      <div className='link-container'>
        {url ? (
          <>
            {isPub ? (
              <a href={url} target='_blank' rel='noopener noreferrer'>
                {url}
              </a>
            ) : (
              <span>{url}</span>
            )}
          </>
        ) : (
          <div className='skeleton-container'>
            <Skeleton />
          </div>
        )}
      </div>
      {url && !isPub && <h4 className='disabled'>{t('MANAGER_MENU_ITEM_AFTER_PUBLISH_ONLY')}</h4>}
      <div className='copy-url-show-qr'>
        <div className='copy-url-show-qr-inner'>
          {url ? (
            <>
              <div
                className={`v2-button sm v2-button-outline${isPub ? '' : ' disabled'}`}
                onClick={
                  isPub
                    ? () => {
                        navigator.clipboard.writeText(url)
                        ntfSuccess({ message: t('MESSAGE_URL_COPIED') })
                      }
                    : null
                }
              >
                {isDesktop && <IconCopy color='#ff7a45' width='20px' />}
                {t('MANAGER_MENU_ITEM_BUTTON_COPY_URL')}
              </div>
              <div
                className={`v2-button sm v2-button-outline${isPub ? '' : ' disabled'}`}
                onClick={
                  isPub
                    ? () => {
                        setQrPopup(url)
                      }
                    : null
                }
              >
                {isDesktop && <IconEyeVisible color='#ff7a45' width='20px' />}
                {t('MANAGER_MENU_ITEM_BUTTON_SHOW_QR')}
              </div>
              <div
                className={`v2-button sm v2-button-outline${isPub ? '' : ' disabled'}`}
                onClick={isPub ? handleSaveQrZip : null}
              >
                {isDesktop && <IconExport color='#ff7a45' width='20px' />}
                {t('MANAGER_MENU_ITEM_BUTTON_ZIP_QR')}
              </div>
              <div
                className={`v2-button sm v2-button-outline${isPub ? '' : ' disabled'}`}
                onClick={isPub ? handleSaveMdZip : null}
              >
                {isDesktop && <IconExport color='#ff7a45' width='20px' />}
                {t('MANAGER_MENU_ITEM_BUTTON_SAVE_MARKDOWN')}
              </div>
            </>
          ) : (
            <>
              <div className='skeleton-button sm'>
                <Skeleton />
              </div>
              <div className='skeleton-button sm'>
                <Skeleton />
              </div>
              <div className='skeleton-button sm'>
                <Skeleton />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default MenuLanguageBlock
