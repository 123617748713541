import React, { useState, useRef, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import InputGroup from 'react-bootstrap/InputGroup'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { ItemDragWrapper } from './DragWrapper'
import { renderTooltip, openizator } from '../helpers'
import { defaultItem } from '../data/index'
import UnitNameDebouncedInput from './UnitNameDebouncedInput'
import Item from './Item'
import {
  RootSetterContext,
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
import icons from '../img/icons/index'
import { useIsDesktop, useDragDown, useDragUp } from '../helpers/hooks'
import { findTargetById } from '../helpers/index'

const { IconAddItem, IconRemoveItem, IconEyeVisible, IconEyeHidden, IconDragOld } = icons

const SubCategory = ({ subcatId, catIndex, subcatIndex }) => {
  const { setShowRemoveModal } = useContext(RootSetterContext)
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { defaultLanguage, currentLanguage, showList } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges, setShowList } = useContext(ConstructorSetterContext)

  const isDesktop = useIsDesktop()
  const { t } = useTranslation()

  const [defaultLangData, setDefaultLangData] = useState(null)
  const [langData, setLangData] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (menuLocals && currentLanguage) {
      setLangData(menuLocals.find((langObj) => langObj.lang === currentLanguage))
    }
  }, [menuLocals, currentLanguage])

  useEffect(() => {
    if (menuLocals && defaultLanguage) {
      const _defaultLangData = menuLocals.find((langObj) => langObj.lang === defaultLanguage)
      setDefaultLangData(_defaultLangData)
    }
  }, [menuLocals, defaultLanguage])

  const target = useRef(null)

  const dragDownBtn = useDragDown([catIndex, subcatIndex], subcatId)
  const dragUpBtn = useDragUp([catIndex, subcatIndex], subcatId)

  useEffect(() => {
    if (showList) {
      showList.forEach((item) => {
        if (item.name === subcatId) {
          setIsOpen(item.isOpen)
        }
      })
    }
  }, [showList, subcatId])

  const getItemsList = () => (
    <ul className='items'>
      {langData.subItems[catIndex]?.subItems[subcatIndex]?.subItems?.map((item, q) => (
        <Item
          key={q}
          itemId={item.id}
          catIndex={catIndex}
          subcatIndex={subcatIndex}
          itemIndex={q}
        />
      ))}
    </ul>
  )

  const handleToggleVisibility = () => {
    const defaultIsVisible = findTargetById(defaultLangData, subcatId).isVisible
    const _menuLocals = menuLocals.map((local) => {
      if (currentLanguage === defaultLanguage) {
        const currentUnit = findTargetById(local, subcatId)
        currentUnit.isVisible = !defaultIsVisible
      } else {
        if (local.lang === currentLanguage) {
          const currentUnit = findTargetById(local, subcatId)
          currentUnit.isVisible = !currentUnit.isVisible
        }
      }
      return local
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const handleDeleteSubcategory = () => {
    menuLocals.forEach((local) => {
      local.subItems[catIndex].subItems = langData.subItems[catIndex].subItems.filter(
        (item) => item.id !== subcatId
      )
    })
    setMenuLocals(menuLocals)
    setIsThereUnsavedChanges(true)
    return true
  }

  const handleCreateItem = () => {
    const _defaultItem = defaultItem()
    const _menuLocals = JSON.parse(JSON.stringify(menuLocals))

    _menuLocals.map((local) => {
      for (let item of local.subItems[catIndex].subItems) {
        if (item.id === subcatId) {
          item.subItems.push({ ..._defaultItem })
          break
        }
      }
      return local
    })

    showList.push({ name: _defaultItem.id, isOpen: true })
    setMenuLocals(_menuLocals)
    setShowList(showList)
    setIsThereUnsavedChanges(true)
  }

  const getHideButton = () => {
    const buttonItself = (
      <label className='btn-hide input-label' htmlFor={`${currentLanguage}_${subcatId}`}>
        {langData.subItems[catIndex].subItems[subcatIndex].isVisible ? (
          <IconEyeVisible color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
        ) : (
          <IconEyeHidden color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
        )}
        <input
          type='checkbox'
          id={`${currentLanguage}_${subcatId}`}
          name='isVisible'
          value={langData.subItems[catIndex].subItems[subcatIndex].isVisible}
          onClick={
            isLocalsLoading
              ? null
              : (e) => {
                  handleToggleVisibility(e)
                }
          }
        />
      </label>
    )

    return isDesktop ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_HIDE'))}
        target={target.current}
      >
        {buttonItself}
      </OverlayTrigger>
    ) : (
      buttonItself
    )
  }

  const getDeleteButton = () => {
    const buttonItself = (
      <div
        className='btn-delete'
        onClick={
          isLocalsLoading
            ? null
            : () => {
                setShowRemoveModal({
                  show: true,
                  name: langData.subItems[catIndex].subItems[subcatIndex].subcatName,
                  kind: t('CON_MODAL_DELETE_SUBCATEGORY'),
                  cbName: handleDeleteSubcategory,
                  cbArgs: [],
                })
              }
        }
      >
        <IconRemoveItem color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='24px' />
      </div>
    )

    return isDesktop ? (
      <OverlayTrigger
        placement='top'
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_DELETE'))}
        target={target.current}
      >
        {buttonItself}
      </OverlayTrigger>
    ) : (
      buttonItself
    )
  }

  const getDefaultLanguageButtons = () =>
    currentLanguage === defaultLanguage && (
      <Col className='default-language-buttons'>
        {isDesktop ? (
          <>
            <div className='toggleHide'>{getHideButton()}</div>
            <OverlayTrigger
              placement='top'
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip(t('CON_CONTENT_TOOLTIP_DRAG'))}
              target={target.current}
            >
              <span className='drag'>
                <IconDragOld color={isLocalsLoading ? '#da3600' : '#ff7a45'} width='30px' />
              </span>
            </OverlayTrigger>
          </>
        ) : (
          <>
            {getDeleteButton()}
            {getHideButton()}
            {dragUpBtn}
          </>
        )}
      </Col>
    )

  const getHint = () => {
    const showHint =
      currentLanguage !== defaultLanguage &&
      defaultLangData &&
      defaultLangData.subItems[catIndex].subItems[subcatIndex].subcatName
    return (
      <>
        {showHint ? (
          <div className='hint-row'>
            <InputGroup size='sm' className='hint-content'>
              <InputGroup.Prepend>
                <InputGroup.Text>{t('CON_CONTENT_SUBCATEGORY_TEXT')}</InputGroup.Text>
              </InputGroup.Prepend>
              <div className='hint-container'>
                {defaultLangData.subItems[catIndex].subItems[subcatIndex].subcatName}
              </div>
            </InputGroup>
          </div>
        ) : null}
      </>
    )
  }

  const getNameAndButton = () => (
    <div className='name-and-delete'>
      {getHint()}
      {!isDesktop && getDefaultLanguageButtons()}

      <div className='actions-block'>
        <InputGroup size='sm'>
          <InputGroup.Prepend>
            <InputGroup.Text>{t('CON_CONTENT_SUBCATEGORY_TEXT')}</InputGroup.Text>
          </InputGroup.Prepend>
          <UnitNameDebouncedInput
            incomingKind='subcat'
            incomingName='subcatName'
            incomingValue={langData.subItems[catIndex].subItems[subcatIndex].subcatName}
            incomingId={subcatId}
            incomingIndex={subcatIndex}
            incomingParentIndex={catIndex}
          />
        </InputGroup>
        {currentLanguage === defaultLanguage && (isDesktop ? getDeleteButton() : dragDownBtn)}
        {currentLanguage !== defaultLanguage && getHideButton()}
      </div>
    </div>
  )

  const getHeader = () => (
    <Row
      aria-expanded={isOpen}
      className={`unit-header expandable${isOpen ? ' open' : ''}${
        currentLanguage !== defaultLanguage ? ' translated' : ''
      }`}
    >
      <div className='expand-btn' onClick={() => openizator(subcatId, showList, setShowList)} />
      {getNameAndButton()}
      {isDesktop && getDefaultLanguageButtons()}
    </Row>
  )

  const getContent = () => (
    <Collapse in={isOpen}>
      <Container className='unit-content' fluid>
        <ItemDragWrapper cat={catIndex} subcat={subcatIndex}>
          {getItemsList()}
        </ItemDragWrapper>

        {currentLanguage === defaultLanguage && (
          <Row className='control-box'>
            <div
              className='v2-button sm'
              id='add-item-button'
              variant='info'
              onClick={() => {
                handleCreateItem()
              }}
            >
              <IconAddItem color='#ffffff' width={isDesktop ? '24px' : '20px'} />
              {t('CON_CONTENT_ITEM_TEXT')}
            </div>
          </Row>
        )}
      </Container>
    </Collapse>
  )

  return langData ? (
    <Container
      className={`subcategory-container ${
        langData.subItems[catIndex].subItems[subcatIndex].isVisible ? '' : 'grayed'
      }`}
      as='article'
      id={subcatId}
      fluid
    >
      {getHeader()}
      {getContent()}
    </Container>
  ) : null
}

export default SubCategory
