import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { findTargetById } from '../helpers'
import { ntfWarn } from '../helpers/notifications'
import { validateUnitName } from '../validation/validate'
import { placeholders } from '../data'
import {
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
const { REACT_APP_DEBOUNCE_TIMOUT } = process.env

const UnitNameDebouncedInput = ({
  incomingKind,
  incomingName,
  incomingValue,
  incomingId,
  incomingIndex,
  incomingParentIndex = undefined,
  incomingGrandParentIndex = undefined,
}) => {
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { currentLanguage } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const { t } = useTranslation()

  const [nameValue, setNameValue] = useState(incomingValue)

  useEffect(() => {
    let newName
    if (incomingGrandParentIndex !== undefined) {
      // we have item
      newName =
        menuLocals.find((langObj) => langObj.lang === currentLanguage).subItems?.[
          incomingGrandParentIndex
        ]?.subItems?.[incomingParentIndex]?.subItems?.[incomingIndex]?.itemName || ''
    } else if (incomingParentIndex !== undefined) {
      // we have subcategory
      newName =
        menuLocals.find((langObj) => langObj.lang === currentLanguage).subItems?.[
          incomingParentIndex
        ]?.subItems?.[incomingIndex]?.subcatName || ''
    } else {
      // we have category
      newName =
        menuLocals.find((langObj) => langObj.lang === currentLanguage).subItems?.[incomingIndex]
          ?.catName || ''
    }
    setNameValue(newName)
    // eslint-disable-next-line
  }, [incomingValue, menuLocals, currentLanguage])

  const handleUnitNameChange = (name, value) => {
    const _menuLocals = menuLocals.map((local, m) => {
      if (local.lang === currentLanguage) {
        const targetItem = findTargetById(local, incomingId)
        if (targetItem) {
          targetItem[name] = value
        }
      }
      return local
    })

    setMenuLocals(_menuLocals)
    setIsThereUnsavedChanges(true)
  }

  const debouncedHandleUnitNameChange = useDebouncedCallback(
    handleUnitNameChange,
    REACT_APP_DEBOUNCE_TIMOUT
  )

  return (
    <>
      <input
        className='form-control'
        id={`${incomingId}-${incomingKind}-name`}
        name={incomingName}
        value={nameValue}
        placeholder={placeholders[incomingKind]}
        disabled={isLocalsLoading}
        onChange={(e) => {
          const name = e.target.name
          const value = e.target.value
          const error = validateUnitName(value).error
          if (!error) {
            setNameValue(value)
            debouncedHandleUnitNameChange(name, value)
          } else {
            ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_250_CHARACTERS_MAX') })
          }
        }}
      />
    </>
  )
}
export default UnitNameDebouncedInput
