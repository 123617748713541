const {
  colorItemSchema,
  measureUnitSchema,
  measureLargeUnitSchema,
  itemMeasureSchema,
  itemDescriptionSchema,
  unitNameSchema,
  userSchema,
  emailSchema,
  userIdSchema,
  menuIdSchema,
  itemSchema,
  subcategorySchema,
  categorySchema,
  restNameSchema,
  measureUnitsSchema,
  menuTranslationSchema,
  createUserSchema,
  createMenuSchema,
  updateMenuSchema,
  publishMenuSchema,
  getMenuSchema,
  getAuthUserSchema,
  getUserSchema,
  getPdfDataSchema,
  validateMenuSchema,
  validateAliasSchema,
  validateSummarySchema,
  importCsvMenuSchema,
  swapAliasesSchema,
} = require('./schemas')

const validateColor = (candidate) => {
  const { error, value } = colorItemSchema.validate(candidate)
  return { error, value }
}

const validateMeasureUnit = (candidate) => {
  const { error, value } = measureUnitSchema.validate(candidate)
  return { error, value }
}

const validateMeasureLargeUnit = (candidate) => {
  const { error, value } = measureLargeUnitSchema.validate(candidate)
  return { error, value }
}

const validateItemMeasure = (candidate) => {
  const { error, value } = itemMeasureSchema.validate(candidate)
  return { error, value }
}

const validateItemDescription = (candidate) => {
  const { error, value } = itemDescriptionSchema.validate(candidate)
  return { error, value }
}

const validateUnitName = (candidate) => {
  const { error, value } = unitNameSchema.validate(candidate)
  return { error, value }
}

const validateEmail = (candidate) => {
  const { error, value } = emailSchema.validate(candidate)
  return { error, value }
}

const validateUser = (candidate) => {
  const { error, value } = userSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateUserId = (candidate) => {
  const { error, value } = userIdSchema.validate(candidate)
  return { error, value }
}

const validateMenuId = (candidate) => {
  const { error, value } = menuIdSchema.validate(candidate)
  return { error, value }
}

const validateItem = (candidate) => {
  const { error, value } = itemSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateSubcategory = (candidate) => {
  const { error, value } = subcategorySchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateCategory = (candidate) => {
  const { error, value } = categorySchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateRestName = (candidate) => {
  const { error, value } = restNameSchema.validate(candidate)
  return { error, value }
}

const validateMeasureUnits = (candidate) => {
  const { error, value } = measureUnitsSchema.validate(candidate)
  return { error, value }
}

const validateTranslation = (candidate) => {
  const { error, value } = menuTranslationSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateCreateUser = (candidate) => {
  const { error, value } = createUserSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateGetAuthUser = (candidate) => {
  const { error, value } = getAuthUserSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateGetUser = (candidate) => {
  const { error, value } = getUserSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateGetPdfData = (candidate) => {
  const { error, value } = getPdfDataSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateCreateMenu = (candidate) => {
  const { error, value } = createMenuSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateGetMenu = (candidate) => {
  const { error, value } = getMenuSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateDeleteMenu = validateGetMenu

const validateUpdateMenu = (candidate) => {
  const { error, value } = updateMenuSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateSwapAliases = (candidate) => {
  const { error, value } = swapAliasesSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validatePublishMenu = (candidate) => {
  const { error, value } = publishMenuSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateMenu = (candidate) => {
  const { error, value } = validateMenuSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateCheckAlias = (candidate) => {
  const { error, value } = validateAliasSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateGetSummary = (candidate) => {
  const { error, value } = validateSummarySchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

const validateImportCsvMenu = (candidate) => {
  const { error, value } = importCsvMenuSchema.validate(candidate, { abortEarly: false })
  return { error, value }
}

module.exports = {
  validateColor,
  validateMeasureUnit,
  validateMeasureLargeUnit,
  validateItemMeasure,
  validateItemDescription,
  validateUnitName,
  validateUser,
  validateEmail,
  validateUserId,
  validateMenuId,
  validateItem,
  validateSubcategory,
  validateCategory,
  validateRestName,
  validateMeasureUnits,
  validateTranslation,
  validateCreateUser,
  validateCreateMenu,
  validateGetMenu,
  validateGetAuthUser,
  validateGetUser,
  validateGetPdfData,
  validatePublishMenu,
  validateUpdateMenu,
  validateDeleteMenu,
  validateMenu,
  validateCheckAlias,
  validateGetSummary,
  validateImportCsvMenu,
  validateSwapAliases,
}
