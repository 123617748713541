import React, { useState, useContext, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'
import { useDebouncedCallback } from 'use-debounce'
import { ConstructorGetterContext, ConstructorSetterContext } from '../context/userContext'
import icons from '../img/icons/index'
import Dropdown from 'react-bootstrap/Dropdown'
import { ntfWarn } from '../helpers/notifications'
import { useTranslation } from 'react-i18next'
const {
  IconFacebook,
  IconInstagram,
  IconTripadvisor,
  IconTiktok,
  IconLinktree,
  IconWhatsapp,
  IconEmail,
  IconWeb,
  IconGooglemaps,
  IconTelegram,
  IconOpentable,
  IconLink,
} = icons
const { REACT_APP_DEBOUNCE_TIMOUT } = process.env

const socialIcons = {
  facebook: <IconFacebook color='#55595C' width='20px' />,
  instagram: <IconInstagram color='#55595C' width='20px' />,
  tripadvisor: <IconTripadvisor color='#55595C' width='20px' />,
  whatsapp: <IconWhatsapp color='#55595C' width='20px' />,
  telegram: <IconTelegram color='#55595C' width='20px' />,
  tikTok: <IconTiktok color='#55595C' width='20px' />,
  linkTree: <IconLinktree color='#55595C' width='20px' />,
  googleMaps: <IconGooglemaps color='#55595C' width='20px' />,
  openTable: <IconOpentable color='#55595C' width='20px' />,
  website: <IconWeb color='#55595C' width='20px' />,
  email: <IconEmail color='#55595C' width='20px' />,
  customLink: <IconLink color='#55595C' width='20px' />,
}

const DebouncedSocialInput = () => {
  const { socials } = useContext(ConstructorGetterContext)
  const [activeNameType, setActiveNameType] = useState('')
  const { setIsThereUnsavedChanges, setSocials } = useContext(ConstructorSetterContext)
  const [socialValue, setSocialValue] = useState(socials[activeNameType] || '')
  const { t } = useTranslation()

  useEffect(() => {
    if (!activeNameType && socials) {
      setActiveNameType(Object.keys(socials)[0])
    }
    setSocialValue(socials[activeNameType])
  }, [activeNameType, socials])

  const handleSocialChange = (value, _socials) => {
    let _url = value

    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
    if (!urlRegex.test(_url)) {
      ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_ERROR_URL') })
      return
    } else {
      try {
        // Temporary fix. Wrong usage of URL().
        //_url = new URL(`//${value}`, 'https://gimme.menu').href
      } catch (e) {}
      _socials[activeNameType] = _url
      setSocials({ ..._socials })
      setIsThereUnsavedChanges(true)
    }
  }

  const handleAddSocialValue = () => {
    handleSocialChange(socialValue, socials)
  }

  return (
    <Row className={`socials-item ${activeNameType}`}>
      <Col className='socials-item-inner' xl>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id='dropdown-basic'>
            <label htmlFor={activeNameType}>{socialIcons[activeNameType]} &#9662;</label>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(socials).map((socialName, s) => (
              <Dropdown.Item
                key={socialName}
                title={socialName}
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                onClick={() => setActiveNameType(socialName)}
              >
                {socialName.charAt(0).toUpperCase() + socialName.slice(1)}
                {/*{<label htmlFor={socialName}>{socialIcons[socialName]}</label>}*/}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <InputGroup size='md'>
          <input
            className='form-control'
            type='text'
            name={activeNameType}
            value={socialValue || ''}
            onChange={(e) => {
              const value = e.target.value
              setSocialValue(value)
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleAddSocialValue()
            }}
          />
        </InputGroup>
        <div className='v2-button sm ml-1' onClick={handleAddSocialValue}>
          {'Add'}
        </div>
      </Col>
    </Row>
  )
}

const SkeletonInput = () => {
  return (
    <Row className='socials-item'>
      <Col className='socials-item-inner' xl>
        <InputGroup size='md'>
          <Skeleton />
        </InputGroup>
      </Col>
    </Row>
  )
}

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </div>
))
const Socials = () => {
  const { socials } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges, setSocials } = useContext(ConstructorSetterContext)

  const deleteTypeLink = (activeSocialName, _socials) => {
    _socials[activeSocialName] = ''
    setSocials({ ..._socials })
    setIsThereUnsavedChanges(true)
  }

  return (
    <div className='socials'>
      {socials ? (
        <>
          <DebouncedSocialInput />
          {Object.keys(socials)
            .filter((socialName) => socials[socialName] !== '') // Filter out empty social links
            .map((socialName, s) => (
              <Row key={socialName} className={`socials-item ${socialName}`}>
                <Col className='socials-item-inner' xl>
                  <label htmlFor={socialName} title={socialName}>
                    {socialIcons[socialName]}
                  </label>
                  <InputGroup size='md'>
                    <input
                      className='form-control'
                      type='text'
                      name={socialName}
                      value={socials[socialName]}
                      disabled
                    />
                  </InputGroup>
                  <div
                    className='ml-1'
                    style={{ cursor: 'pointer' }}
                    onClick={() => deleteTypeLink(socialName, socials)}
                  >
                    &#x2715;
                  </div>
                </Col>
              </Row>
            ))}
        </>
      ) : (
        <>
          {Object.keys(socialIcons).map((key) => (
            <SkeletonInput key={key.toString()} />
          ))}
        </>
      )}
    </div>
  )
}
export default Socials
