import React, { useState, useContext, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import {
  RootSetterContext,
  ConstructorGetterContext,
  ConstructorSetterContext,
} from '../context/userContext'
import icons from '../img/icons/index'
import { useIsDesktop } from '../helpers/hooks'

const { IconExport, IconRemoveItem } = icons

const {
  REACT_APP_CLOUDINARY_CLOUD_NAME,
  REACT_APP_CLOUDINARY_API_KEY,
  REACT_APP_CLOUDINARY_UPLOAD_PRESET,
} = process.env

const LogoLoader = () => {
  const { setShowRemoveModal } = useContext(RootSetterContext)
  const { logo } = useContext(ConstructorGetterContext)
  const { setLogo, setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const [uploadWidget, setUploadWidget] = useState(null)
  const isDesktop = useIsDesktop()
  const { t, i18n } = useTranslation()

  const startUploadProcess = () => {
    uploadWidget && uploadWidget.open()
  }

  useEffect(() => {
    const _uploadWidget = window.cloudinary.createUploadWidget(
      {
        cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME,
        apiKey: REACT_APP_CLOUDINARY_API_KEY,
        uploadPreset: REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        sources: ['local', 'url'],
        defaultSource: 'local',
        clientAllowedFormats: ['image'],
        showAdvancedOptions: false,
        showPoweredBy: false,
        autoMinimize: true,
        multiple: false,
        theme: 'minimal',
        styles: {
          palette: {
            window: '#fff',
            windowBorder: 'grey',
            tabIcon: '#da3600',
            menuIcons: 'grey',
            link: '#da3600',
            action: '#da3600',
            inactiveTabIcon: '#ff7a45',
            error: '#da3600',
            inProgress: 'ligthgrey',
            complete: '#17a2b8',
            sourceBg: '#fff',
            textDark: '#000',
            textLight: '#fff',
          },
          frame: {
            background: 'rgba(0, 0, 0, 0.5)',
          },
          fonts: {
            default: null,
            "'Arimo', sans-serif": {
              url: 'https://fonts.googleapis.com/css?family=Arimo:400,700&display=swap',
              active: true,
            },
          },
        },
        language: i18n.language,
        text: {
          [i18n.language]: {
            or: t('CLOUDINARY_OR'),
            back: t('CLOUDINARY_BACK'),
            close: t('CLOUDINARY_CLOSE'),
            menu: {
              files: t('CLOUDINARY_MENU_FILES'),
              web: t('CLOUDINARY_MENU_WEB'),
            },
            local: {
              browse: t('CLOUDINARY_LOCAL_BROWSE'),
              dd_title_single: t('CLOUDINARY_LOCAL_DD_TITLE_SINGLE'),
              drop_title_single: t('CLOUDINARY_LOCAL_DROP_TITLE_SINGLE'),
            },
            url: {
              inner_title: t('CLOUDINARY_URL_INNER_TITLE'),
            },
            notifications: {
              general_error: t('CLOUDINARY_NOTIFICATIONS_GENERAL_ERROR'),
              general_prompt: t('CLOUDINARY_NOTIFICATIONS_GENERAL_PROMPT'),
              invalid_add_url: t('CLOUDINARY_NOTIFICATIONS_INVALID_URL'),
            },
            queue: {
              title: t('CLOUDINARY_QUEUE_TITLE'),
              title_uploading: t('CLOUDINARY_QUEUE_TITLE_UPLOADING'),
              mini_title: t('CLOUDINARY_QUEUE_MINI_TITLE'),
              mini_title_uploading: t('CLOUDINARY_QUEUE_MINI_TITLE_UPLOADING'),
              retry_failed: t('CLOUDINARY_QUEUE_RETRY_FAILED'),
              abort_all: t('CLOUDINARY_QUEUE_ABORT_ALL'),
              done: t('CLOUDINARY_QUEUE_DONE'),
              mini_upload_count: t('CLOUDINARY_QUEUE_MINI_UPLOAD_COUNT', {
                interpolation: { skipOnVariables: true },
              }),
              mini_failed: t('CLOUDINARY_QUEUE_MINI_FAILED', {
                interpolation: { skipOnVariables: true },
              }),
              statuses: {
                uploading: t('CLOUDINARY_QUEUE_STATUSES_UPLOADING'),
                error: t('CLOUDINARY_QUEUE_STATUSES_ERROR'),
                uploaded: t('CLOUDINARY_QUEUE_STATUSES_UPLOADED'),
                aborted: t('CLOUDINARY_QUEUE_STATUSES_ABORTED'),
              },
            },
            uploader: {
              errors: {
                unavailable: t('CLOUDINARY_UPLOADER_ERRORS_UNAVAILABLE'),
                allowed_formats: t('CLOUDINARY_UPLOADER_ERRORS_ALLOWED_FORMATS'),
              },
            },
          },
        },
      },
      (error, result) => {
        if (!error && result && result.event === 'success') {
          setLogo(`${result.info.public_id}.${result.info.format}`)
          setIsThereUnsavedChanges(true)
          _uploadWidget.close(true)
        } else {
          error && console.log('processResult error :>> ', error)
        }
      }
    )

    setUploadWidget(_uploadWidget)
  }, [i18n.language, setIsThereUnsavedChanges, setLogo, t])

  const handleRemoveLogo = () => {
    setLogo('')
    setIsThereUnsavedChanges(true)
    return true
  }

  return (
    <div className='custom-logo-input'>
      {logo !== null ? (
        <>
          <div
            id='upload_widget'
            className={`v2-button${!isDesktop ? ' sm' : ''}`}
            onClick={startUploadProcess}
          >
            <IconExport color='#ffffff' width={isDesktop ? '24px' : '20px'} />
            {t('CON_LOGO_BUTTON_UPLOAD')}
          </div>
          {logo && (
            <div
              size='sm'
              className={`v2-button${!isDesktop ? ' sm' : ''} v2-button-outline`}
              onClick={() => {
                setShowRemoveModal({
                  show: true,
                  name: '',
                  kind: t('CON_MODAL_DELETE_LOGO'),
                  cbName: handleRemoveLogo,
                  cbArgs: [],
                })
              }}
            >
              <IconRemoveItem color='#ff7a45' width={isDesktop ? '24px' : '20px'} />
              {t('CON_LOGO_BUTTON_REMOVE')}
            </div>
          )}
        </>
      ) : (
        <div className={`skeleton-button${!isDesktop ? ' sm' : ''}`}>
          <Skeleton />
        </div>
      )}
    </div>
  )
}

export default LogoLoader
