import React from 'react'

const IconPen = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='-1 -1 15 15'
      fill='none'
    >
      <path
        d='M10.7369 1.4428C10.1455 0.852401 9.18784 0.852401 8.59647 1.4428L8.06067 1.98158L2.3572 7.68256L2.34507 7.69478C2.34214 7.69772 2.34214 7.70084 2.33901 7.70084C2.33295 7.70993 2.32386 7.71893 2.3179 7.72802C2.3179 7.73105 2.31477 7.73105 2.31477 7.73408C2.30871 7.74317 2.30578 7.74924 2.29962 7.75833C2.29668 7.76136 2.29668 7.76429 2.29365 7.76742C2.29062 7.77651 2.28759 7.78257 2.28447 7.79166C2.28447 7.7946 2.28153 7.7946 2.28153 7.79772L1.0161 11.6034C0.978983 11.7117 1.0072 11.8317 1.08874 11.9121C1.14603 11.9687 1.2233 12.0003 1.3037 12C1.33656 11.9994 1.36913 11.9943 1.40057 11.9848L5.20292 10.7163C5.20586 10.7163 5.20586 10.7163 5.20898 10.7133C5.21855 10.7105 5.22773 10.7064 5.23616 10.7011C5.23853 10.7008 5.24061 10.6998 5.24231 10.6982C5.25131 10.6921 5.26343 10.686 5.27252 10.6799C5.28152 10.6739 5.2907 10.6648 5.2998 10.6588C5.30283 10.6557 5.30576 10.6557 5.30576 10.6527C5.30889 10.6497 5.31495 10.6468 5.31798 10.6406L11.5572 4.40073C12.1476 3.8093 12.1476 2.85154 11.5572 2.2602L10.7369 1.4428ZM5.10605 10.0047L2.99905 7.89764L8.2726 2.62358L10.3796 4.73069L5.10605 10.0047ZM2.70227 8.45773L4.54298 10.2985L1.77898 11.2189L2.70227 8.45773ZM11.1304 3.97683L10.8095 4.30082L8.70243 2.19353L9.02648 1.86963C9.38093 1.51543 9.95545 1.51543 10.31 1.86963L11.1334 2.6931C11.4852 3.04919 11.4839 3.62244 11.1304 3.97683Z'
        fill={color}
        stroke={color}
        strokeWidth='0.5'
      />
    </svg>
  )
}

export default IconPen
