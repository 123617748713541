import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEN from './locales/en.json'
import translationRU from './locales/ru.json'
import translationUK from './locales/uk.json'

const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  uk: {
    translation: translationUK,
  },
}

const detectorOptions = {
  order: ['localStorage'],
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage'],
  // order: ['localStorage', 'querystring', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    debug: false,
    detection: detectorOptions,
    fallbackLng: 'en',
    // lng: 'cimode',
    supportedLngs: ['en', 'ru', 'uk'],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
