import React, { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useDebouncedCallback } from 'use-debounce'
import { ConstructorGetterContext, ConstructorSetterContext } from '../context/userContext'
import icons from '../img/icons/index'
const { IconPaint } = icons
const { REACT_APP_DEBOUNCE_TIMOUT } = process.env

const ColorPickers = () => {
  const { mainBgColor, mainTextColor, splashBgColor, splashTextColor } = useContext(
    ConstructorGetterContext
  )
  const {
    setIsThereUnsavedChanges,
    setMainBgColor,
    setMainTextColor,
    setSplashBgColor,
    setSplashTextColor,
  } = useContext(ConstructorSetterContext)
  const { t } = useTranslation()

  const DebouncedColorInput = ({ name, description, value, method }) => {
    const handleColorChange = (value) => {
      method(value)
      setIsThereUnsavedChanges(true)
    }
    const debouncedHandleColorChange = useDebouncedCallback(
      handleColorChange,
      REACT_APP_DEBOUNCE_TIMOUT
    )

    return (
      <Col className='color-pickers-item' xl={6}>
        <label htmlFor={name}>
          <span className='description'>{description}</span>
          <span className='icon'>
            <IconPaint color='#55595C' width='10px' />
          </span>
        </label>
        <input
          type='color'
          name={name}
          value={value}
          onChange={(e) => {
            debouncedHandleColorChange(e.target.value)
          }}
        />
      </Col>
    )
  }

  const SkeletonInput = () => {
    return (
      <Col className='color-pickers-item' xl={6}>
        <div className='skeleton-container'>
          <Skeleton />
        </div>
      </Col>
    )
  }

  return (
    <Row className='color-pickers'>
      <>
        {mainBgColor ? (
          <DebouncedColorInput
            name='mainBgColor'
            description={t('CON_COLORS_BG_MAIN')}
            value={mainBgColor}
            method={setMainBgColor}
          />
        ) : (
          <SkeletonInput />
        )}

        {splashBgColor ? (
          <DebouncedColorInput
            name='splashBgColor'
            description={t('CON_COLORS_BG_TOP')}
            value={splashBgColor}
            method={setSplashBgColor}
          />
        ) : (
          <SkeletonInput />
        )}

        {mainTextColor ? (
          <DebouncedColorInput
            name='mainTextColor'
            description={t('CON_COLORS_TEXT_MAIN')}
            value={mainTextColor}
            method={setMainTextColor}
          />
        ) : (
          <SkeletonInput />
        )}

        {splashTextColor ? (
          <DebouncedColorInput
            name='splashTextColor'
            description={t('CON_COLORS_TEXT_TOP')}
            value={splashTextColor}
            method={setSplashTextColor}
          />
        ) : (
          <SkeletonInput />
        )}
      </>
    </Row>
  )
}

export default ColorPickers
