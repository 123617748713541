import React from 'react'

const IconPublish = ({ color, width, height = width }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 10 13'
      fill='none'
    >
      <path
        d='M3.55469 5.11328C3.50742 5.11328 3.46875 5.15195 3.46875 5.19922V8.16406C3.46875 8.21133 3.50742 8.25 3.55469 8.25H3.90381C3.95107 8.25 3.98975 8.21133 3.98975 8.16406V7.13818H4.66973C5.30781 7.13818 5.70312 6.72031 5.70312 6.12734C5.70312 5.54189 5.31318 5.11436 4.67188 5.11436H3.55469V5.11328ZM5.17246 6.12627C5.17246 6.59248 4.88779 6.70957 4.40762 6.70957H3.98975V5.54512H4.59346C4.95654 5.54512 5.17246 5.75674 5.17246 6.12627ZM8.18027 3.1002L5.86856 0.788477C5.8041 0.724023 5.71709 0.6875 5.62578 0.6875H1.0625C0.872363 0.6875 0.71875 0.841113 0.71875 1.03125V9.96875C0.71875 10.1589 0.872363 10.3125 1.0625 10.3125H7.9375C8.12764 10.3125 8.28125 10.1589 8.28125 9.96875V3.34404C8.28125 3.25273 8.24473 3.16465 8.18027 3.1002ZM7.48848 3.50195H5.4668V1.48027L7.48848 3.50195ZM7.50781 9.53906H1.49219V1.46094H4.73633V3.78125C4.73633 3.90091 4.78386 4.01567 4.86847 4.10028C4.95308 4.18489 5.06784 4.23242 5.1875 4.23242H7.50781V9.53906Z'
        fill={color}
      />
    </svg>
  )
}

export default IconPublish
