export const fullList = [
  { value: 'en', name: 'English', label: 'English' },
  { value: 'uk', name: 'Ukrainian', label: 'Українська' },
  { value: 'ru', name: 'Russian', label: 'Русский' },
  { value: 'cs', name: 'Czech', label: 'Česky' },
  { value: 'th', name: 'Thai', label: 'ไทย' },
  { value: 'aa', name: 'Afar', label: 'Afar' },
  { value: 'ab', name: 'Abkhazian', label: 'Аҧсуа' },
  { value: 'af', name: 'Afrikaans', label: 'Afrikaans' },
  { value: 'ak', name: 'Akan', label: 'Akana' },
  { value: 'am', name: 'Amharic', label: 'አማርኛ' },
  { value: 'an', name: 'Aragonese', label: 'Aragonés' },
  { value: 'ar', name: 'Arabic', label: 'العربية' },
  { value: 'as', name: 'Assamese', label: 'অসমীয়া' },
  { value: 'av', name: 'Avar', label: 'Авар' },
  { value: 'ay', name: 'Aymara', label: 'Aymar' },
  { value: 'az', name: 'Azerbaijani', label: 'Azərbaycanca' },
  { value: 'ba', name: 'Bashkir', label: 'Башҡорт' },
  { value: 'be', name: 'Belarusian', label: 'Беларуская' },
  { value: 'bg', name: 'Bulgarian', label: 'Български' },
  { value: 'bh', name: 'Bihari', label: 'भोजपुरी' },
  { value: 'bi', name: 'Bislama', label: 'Bislama' },
  { value: 'bm', name: 'Bambara', label: 'Bamanankan' },
  { value: 'bn', name: 'Bengali', label: 'বাংলা' },
  { value: 'bo', name: 'Tibetan', label: 'བོད་ཡིག' },
  { value: 'br', name: 'Breton', label: 'Brezhoneg' },
  { value: 'bs', name: 'Bosnian', label: 'Bosanski' },
  { value: 'ca', name: 'Catalan', label: 'Català' },
  { value: 'ce', name: 'Chechen', label: 'Нохчийн' },
  { value: 'ch', name: 'Chamorro', label: 'Chamoru' },
  { value: 'co', name: 'Corsican', label: 'Corsu' },
  { value: 'cr', name: 'Cree', label: 'Nehiyaw' },
  { value: 'cu', name: 'Old Church Slavonic / Old Bulgarian', label: 'словѣньскъ' },
  { value: 'cv', name: 'Chuvash', label: 'Чăваш' },
  { value: 'cy', name: 'Welsh', label: 'Cymraeg' },
  { value: 'da', name: 'Danish', label: 'Dansk' },
  { value: 'de', name: 'German', label: 'Deutsch' },
  { value: 'dv', name: 'Divehi', label: 'ދިވެހިބަސް' },
  { value: 'dz', name: 'Dzongkha', label: 'ཇོང་ཁ' },
  { value: 'ee', name: 'Ewe', label: 'Ɛʋɛ' },
  { value: 'el', name: 'Greek', label: 'Ελληνικά' },
  { value: 'eo', name: 'Esperanto', label: 'Esperanto' },
  { value: 'es', name: 'Spanish', label: 'Español' },
  { value: 'et', name: 'Estonian', label: 'Eesti' },
  { value: 'eu', name: 'Basque', label: 'Euskara' },
  { value: 'fa', name: 'Persian', label: 'فارسی' },
  { value: 'ff', name: 'Peul', label: 'Fulfulde' },
  { value: 'fi', name: 'Finnish', label: 'Suomi' },
  { value: 'fj', name: 'Fijian', label: 'Na Vosa Vakaviti' },
  { value: 'fo', name: 'Faroese', label: 'Føroyskt' },
  { value: 'fr', name: 'French', label: 'Français' },
  { value: 'fy', name: 'West Frisian', label: 'Frysk' },
  { value: 'ga', name: 'Irish', label: 'Gaeilge' },
  { value: 'gd', name: 'Scottish Gaelic', label: 'Gàidhlig' },
  { value: 'gl', name: 'Galician', label: 'Galego' },
  { value: 'gn', name: 'Guarani', label: "Avañe'ẽ" },
  { value: 'gu', name: 'Gujarati', label: 'ગુજરાતી' },
  { value: 'gv', name: 'Manx', label: 'Gaelg' },
  { value: 'ha', name: 'Hausa', label: 'هَوُسَ' },
  { value: 'he', name: 'Hebrew', label: 'עברית' },
  { value: 'hi', name: 'Hindi', label: 'हिन्दी' },
  { value: 'ho', name: 'Hiri Motu', label: 'Hiri Motu' },
  { value: 'hr', name: 'Croatian', label: 'Hrvatski' },
  { value: 'ht', name: 'Haitian', label: 'Krèyol ayisyen' },
  { value: 'hu', name: 'Hungarian', label: 'Magyar' },
  { value: 'hy', name: 'Armenian', label: 'Հայերեն' },
  { value: 'hz', name: 'Herero', label: 'Otsiherero' },
  { value: 'ia', name: 'Interlingua', label: 'Interlingua' },
  { value: 'id', name: 'Indonesian', label: 'Bahasa Indonesia' },
  { value: 'ie', name: 'Interlingue', label: 'Interlingue' },
  { value: 'ig', name: 'Igbo', label: 'Igbo' },
  { value: 'ii', name: 'Sichuan Yi', label: '四川彝语' },
  { value: 'ik', name: 'Inupiak', label: 'Iñupiak' },
  { value: 'io', name: 'Ido', label: 'Ido' },
  { value: 'is', name: 'Icelandic', label: 'Íslenska' },
  { value: 'it', name: 'Italian', label: 'Italiano' },
  { value: 'iu', name: 'Inuktitut', label: 'ᐃᓄᒃᑎᑐᑦ' },
  { value: 'ja', name: 'Japanese', label: '日本語' },
  { value: 'jv', name: 'Javanese', label: 'Basa Jawa' },
  { value: 'ka', name: 'Georgian', label: 'ქართული' },
  { value: 'kg', name: 'Kongo', label: 'KiKongo' },
  { value: 'ki', name: 'Kikuyu', label: 'Gĩkũyũ' },
  { value: 'kj', name: 'Kuanyama', label: 'Kuanyama' },
  { value: 'kk', name: 'Kazakh', label: 'Қазақша' },
  { value: 'kl', name: 'Greenlandic', label: 'Kalaallisut' },
  { value: 'km', name: 'Cambodian', label: 'ភាសាខ្មែរ' },
  { value: 'kn', name: 'Kannada', label: 'ಕನ್ನಡ' },
  { value: 'ko', name: 'Korean', label: '한국어' },
  { value: 'kr', name: 'Kanuri', label: 'Kanuri' },
  { value: 'ks', name: 'Kashmiri', label: 'कश्मीरी' },
  { value: 'ku', name: 'Kurdish', label: 'Kurdî' },
  { value: 'kv', name: 'Komi', label: 'Коми' },
  { value: 'kw', name: 'Cornish', label: 'Kernewek' },
  { value: 'ky', name: 'Kirghiz', label: 'Kırgızca' },
  { value: 'la', name: 'Latin', label: 'Latina' },
  { value: 'lb', name: 'Luxembourgish', label: 'Lëtzebuergesch' },
  { value: 'lg', name: 'Ganda', label: 'Luganda' },
  { value: 'li', name: 'Limburgian', label: 'Limburgs' },
  { value: 'ln', name: 'Lingala', label: 'Lingála' },
  { value: 'lo', name: 'Laotian', label: 'ລາວ' },
  { value: 'lt', name: 'Lithuanian', label: 'Lietuvių' },
  { value: 'lv', name: 'Latvian', label: 'Latviešu' },
  { value: 'mg', name: 'Malagasy', label: 'Malagasy' },
  { value: 'mh', name: 'Marshallese', label: 'Kajin Majel' },
  { value: 'mi', name: 'Maori', label: 'Māori' },
  { value: 'mk', name: 'Macedonian', label: 'Македонски' },
  { value: 'ml', name: 'Malayalam', label: 'മലയാളം' },
  { value: 'mn', name: 'Mongolian', label: 'Монгол' },
  { value: 'mo', name: 'Moldovan', label: 'Moldovenească' },
  { value: 'mr', name: 'Marathi', label: 'मराठी' },
  { value: 'ms', name: 'Malay', label: 'Bahasa Melayu' },
  { value: 'mt', name: 'Maltese', label: 'bil-Malti' },
  { value: 'my', name: 'Burmese', label: 'Myanmasa' },
  { value: 'na', name: 'Nauruan', label: 'Dorerin Naoero' },
  { value: 'nd', name: 'North Ndebele', label: 'Sindebele' },
  { value: 'ne', name: 'Nepali', label: 'नेपाली' },
  { value: 'ng', name: 'Ndonga', label: 'Oshiwambo' },
  { value: 'nl', name: 'Dutch', label: 'Nederlands' },
  { value: 'nn', name: 'Norwegian Nynorsk', label: 'Norsk (nynorsk)' },
  { value: 'no', name: 'Norwegian', label: 'Norsk' },
  { value: 'nr', name: 'South Ndebele', label: 'isiNdebele' },
  { value: 'nv', name: 'Navajo', label: 'Diné bizaad' },
  { value: 'ny', name: 'Chichewa', label: 'Chi-Chewa' },
  { value: 'oc', name: 'Occitan', label: 'Occitan' },
  { value: 'oj', name: 'Ojibwa', label: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
  { value: 'om', name: 'Oromo', label: 'Oromoo' },
  { value: 'or', name: 'Oriya', label: 'ଓଡ଼ିଆ' },
  { value: 'os', name: 'Ossetian / Ossetic', label: 'Иронау' },
  { value: 'pa', name: 'Panjabi / Punjabi', label: 'ਪੰਜਾਬੀ' },
  { value: 'pi', name: 'Pali', label: 'Pāli' },
  { value: 'pl', name: 'Polish', label: 'Polski' },
  { value: 'ps', name: 'Pashto', label: 'پښتو' },
  { value: 'pt', name: 'Portuguese', label: 'Português' },
  { value: 'qu', name: 'Quechua', label: 'Runa Simi' },
  { value: 'rm', name: 'Raeto Romance', label: 'Rumantsch' },
  { value: 'rn', name: 'Kirundi', label: 'Kirundi' },
  { value: 'ro', name: 'Romanian', label: 'Română' },
  { value: 'rw', name: 'Rwandi', label: 'Kinyarwandi' },
  { value: 'sa', name: 'Sanskrit', label: 'संस्कृतम्' },
  { value: 'sc', name: 'Sardinian', label: 'Sardu' },
  { value: 'sd', name: 'Sindhi', label: 'सिनधि' },
  { value: 'se', name: 'Northern Sami', label: 'Davvisámegiella' },
  { value: 'sg', name: 'Sango', label: 'Sängö' },
  { value: 'sh', name: 'Serbo-Croatian', label: 'Српскохрватски' },
  { value: 'si', name: 'Sinhalese', label: 'සිංහල' },
  { value: 'sk', name: 'Slovak', label: 'Slovenčina' },
  { value: 'sl', name: 'Slovenian', label: 'Slovenščina' },
  { value: 'sm', name: 'Samoan', label: 'Gagana Samoa' },
  { value: 'sn', name: 'Shona', label: 'chiShona' },
  { value: 'so', name: 'Somalia', label: 'Soomaaliga' },
  { value: 'sq', name: 'Albanian', label: 'Shqip' },
  { value: 'sr', name: 'Serbian', label: 'Српски' },
  { value: 'ss', name: 'Swati', label: 'SiSwati' },
  { value: 'st', name: 'Southern Sotho', label: 'Sesotho' },
  { value: 'su', name: 'Sundanese', label: 'Basa Sunda' },
  { value: 'sv', name: 'Swedish', label: 'Svenska' },
  { value: 'sw', name: 'Swahili', label: 'Kiswahili' },
  { value: 'ta', name: 'Tamil', label: 'தமிழ்' },
  { value: 'te', name: 'Telugu', label: 'తెలుగు' },
  { value: 'tg', name: 'Tajik', label: 'Тоҷикӣ' },
  { value: 'ti', name: 'Tigrinya', label: 'ትግርኛ' },
  { value: 'tk', name: 'Turkmen', label: 'تركمن' },
  { value: 'tl', name: 'Tagalog', label: 'Tagalog' },
  { value: 'tn', name: 'Tswana', label: 'Setswana' },
  { value: 'to', name: 'Tonga', label: 'Lea Faka-Tonga' },
  { value: 'tr', name: 'Turkish', label: 'Türkçe' },
  { value: 'ts', name: 'Tsonga', label: 'Xitsonga' },
  { value: 'tt', name: 'Tatar', label: 'Tatarça' },
  { value: 'tw', name: 'Twi', label: 'Twi' },
  { value: 'ty', name: 'Tahitian', label: 'Reo Mā`ohi' },
  { value: 'ug', name: 'Uyghur', label: 'Uyƣurqə' },
  { value: 'ur', name: 'Urdu', label: 'اردو' },
  { value: 'uz', name: 'Uzbek', label: 'Ўзбек' },
  { value: 've', name: 'Venda', label: 'Tshivenḓa' },
  { value: 'vi', name: 'Vietnamese', label: 'Việtnam' },
  { value: 'vo', name: 'Volapük', label: 'Volapük' },
  { value: 'wa', name: 'Walloon', label: 'Walon' },
  { value: 'wo', name: 'Wolof', label: 'Wollof' },
  { value: 'xh', name: 'Xhosa', label: 'isiXhosa' },
  { value: 'yi', name: 'Yiddish', label: 'ייִדיש' },
  { value: 'yo', name: 'Yoruba', label: 'Yorùbá' },
  { value: 'za', name: 'Zhuang', label: 'Cuengh' },
  { value: 'zh', name: 'Chinese', label: '中文' },
  { value: 'zu', name: 'Zulu', label: 'isiZulu' },
  { value: 'nb', name: 'Norwegian Bokmål', label: 'Norsk (bokmål)' },
]
