import React, { useState, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import questionsWallpapers from '../img/questions-wallpaper.png'

const V2ContactForm = forwardRef((props, ref) => {
  const [isFetching, setIsFetching] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isSent, setIsSent] = useState(false)
  const { t } = useTranslation()

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    setIsFetching(true)
    const obj = {
      name: name.trim(),
      email: email.trim(),
      message: message.trim(),
    }

    await logToDiscord(obj)

    setIsSent(true)
    setIsFetching(false)
  }

  const logToDiscord = async (obj) => {
    const nameEmail = `**${obj.name}**  ${obj.email}`
    const message = `\`\`\`${obj.message}\`\`\``
    const content = [nameEmail, message].join('\n')
    try {
      const DISCORD_WEBHOOK_URLS = [process.env.REACT_APP_CONTACT_URL]
      if (DISCORD_WEBHOOK_URLS.length === 0) throw new Error('No webhook URL provided')
      for (const url of DISCORD_WEBHOOK_URLS) {
        await axios({ method: 'post', url, data: { content } })
      }
    } catch (e) {
      console.error('[logToDiscord] ERROR:', e.toString())
    }
  }
  return (
    <section ref={ref} id='help' className='v2-section v2-contact-form'>
      <div className='inner'>
        {isSent ? (
          <p className='thank-you'>
            <span className='subtitle'>{t('HOME_CONTACT_THANK_SUB_TEXT')}</span>
            <br />
            {t('HOME_CONTACT_THANK_TEXT')}
          </p>
        ) : (
          <>
            <div className='contact-form'>
              <h3>{t('HOME_CONTACT_TITLE')}</h3>
              <p>{t('HOME_CONTACT_SUBTITLE')}</p>
              <div className='input-item'>
                <label htmlFor='name'>{t('HOME_CONTACT_NAME')}</label>
                <input
                  placeholder='John Peterson'
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                  disabled={isFetching}
                  id='name'
                />
              </div>
              <div className='input-item'>
                <label htmlFor='email'>{t('HOME_CONTACT_EMAIL')}</label>
                <input
                  type='email'
                  placeholder='johnpeterson@email.com'
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  disabled={isFetching}
                  id='email'
                />
              </div>
              <div className='input-item'>
                <label htmlFor='message'>{t('HOME_CONTACT_MESSAGE')}</label>
                <textarea
                  rows='5'
                  placeholder='message'
                  value={message}
                  onChange={(e) => setMessage(e.currentTarget.value)}
                  disabled={isFetching}
                  id='message'
                />
              </div>
              <button
                disabled={!name || !email || !message}
                onClick={handleSubmit}
                className='v2-button'
                variant='info'
              >
                {t('HOME_CONTACT_CTA_TEXT')}
              </button>
            </div>
            <div className='wallpapers'>
              <img src={questionsWallpapers} alt='' />
            </div>
          </>
        )}
      </div>
    </section>
  )
})

export default V2ContactForm
