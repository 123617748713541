import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import { findTargetById } from '../helpers'
import { ntfWarn } from '../helpers/notifications'
import { validateItemMeasure } from '../validation/validate'
import {
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../context/userContext'
const { REACT_APP_DEBOUNCE_TIMOUT } = process.env

const ItemMeasureDebouncedInput = ({
  incomingName,
  incomingValue,
  incomingId,
  incomingIndex,
  incomingParentIndex,
  incomingGrandParentIndex,
}) => {
  const { menuLocals, isLocalsLoading } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { currentLanguage } = useContext(ConstructorGetterContext)
  const { setIsThereUnsavedChanges } = useContext(ConstructorSetterContext)
  const { t } = useTranslation()

  const [measureValue, setMeasureValue] = useState(incomingValue)

  useEffect(() => {
    const targetItem = menuLocals.find((langObj) => langObj.lang === currentLanguage)?.subItems?.[
      incomingGrandParentIndex
    ]?.subItems?.[incomingParentIndex]?.subItems?.[incomingIndex]
    setMeasureValue(targetItem?.[incomingName] || '')
  }, [incomingValue, menuLocals, currentLanguage])

  const handleItemMeasureChange = (name, value, incomingId) => {
    const newData = menuLocals.map((menu, m) => {
      const langTargetItem = findTargetById(menu, incomingId)
      langTargetItem[name] = value
      return menu
    })

    // prevItemProps[name] = value
    // setPrevItemProps({ ...prevItemProps })
    setMenuLocals(newData)
    setIsThereUnsavedChanges(true)
  }

  const debouncedHandleItemMeasureChange = useDebouncedCallback(
    handleItemMeasureChange,
    REACT_APP_DEBOUNCE_TIMOUT
  )

  return (
    <input
      className='form-control'
      name={incomingName}
      id={`${incomingId}-${incomingName}`}
      value={measureValue}
      disabled={isLocalsLoading}
      onChange={(e) => {
        const name = e.target.name
        const value = e.target.value
        const error = validateItemMeasure(value).error
        if (!error) {
          setMeasureValue(value)
          debouncedHandleItemMeasureChange(name, value, incomingId)
        } else {
          ntfWarn({ title: t('MESSAGE_ERROR_TITLE'), message: t('MESSAGE_10_DIGITS_MAX') })
        }
      }}
    />
  )
}
export default ItemMeasureDebouncedInput
