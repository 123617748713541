import React from 'react'
import secondaryImg1 from '../../img/secondary-page-img_01.png'
import secondaryImg2 from '../../img/secondary-page-img_02.png'
import secondaryImg3 from '../../img/secondary-page-img_03.png'
import secondaryImg4 from '../../img/secondary-page-img_04.png'
import secondaryImg5 from '../../img/secondary-page-img_05.png'

const TermsPage = () => {
  return (
    <section className='secondary-page'>
      <div className='text'>
        <h1>Terms of Service</h1>
        <p>
          Welcome to Gimme Menu! We provide a service for designing and publishing online and QR
          menus for restaurants and other foodservice providers. We want to help your business grow
          while sparing your time, expense, and trouble.
        </p>
        <p>
          By using our services, you are agreeing to these Terms. Please read them carefully. Our
          services are regularly evolving, and we may update these Terms at some later date, so
          please check back for changes that might affect you.
        </p>
        <h5>1. Basic Definitions</h5>
        <p>
          The services ("Services") are provided by <b>PozitiFF Company</b> s.r.o. with offices in
          Prague, CZ. The <b>Gimme Menu</b> website ("Site") is a service for use by registered
          users ("Accounts"). The Site and Services are the wholly-owned, copyrighted property of{' '}
          <b>PozitiFF Company</b> s.r.o. ("Company"), and are subject to U.S. and international
          copyright protection laws. Gimme Menu is trademarked.
        </p>
        <h5>2. Requirement to Be in Good Standing</h5>
        <p>
          Usage Rights are granted to Accounts who have an active (not canceled) account with a
          valid username and password at https://gimme.menu/. The Company may revoke privileges to
          anyone it believes is abusing the Site as well as seek legal remedies necessary to protect
          its copyrights and intellectual property. The Company also reserves the right, at its sole
          discretion, to refuse or cancel service to anyone it deems to be creating offensive
          material.
        </p>
        <h5>3. Content Submissions from Accounts</h5>
        <p>
          By uploading or entering content, including menu copy, designs, names, prices, logos,
          marketing graphics, food photos, ideas, concepts, or any other type of content
          (collectively "Your Content"), in the normal course of using the Services, you hereby
          grant and, where applicable, warrant that the owner of such content or intellectual
          property has expressly granted <b>Gimme Menu</b> a non-exclusive, royalty-free, perpetual,
          irrevocable, sub-licensable and transferable, worldwide license to use, perform, and
          publicly display Your Content.
        </p>
        <p>
          By uploading Your Content to the Services, you warrant that you have all the necessary
          licenses and permissions to use and share Your Content and the rights necessary to grant
          the licenses in these terms.
        </p>
        <p>
          You will indemnify us from any claim, demand, loss, or damages, including reasonable
          attorney's fees, arising out of or related to Your Content, your use of the Services, or
          your violation of these terms.
        </p>
        <p>
          It is the Company's absolute policy to reject any materials which, at the Company's sole
          discretion, it considers to be obscene or in any other way inappropriate.
        </p>
        <h5>4. Rights and Access Privileges Do Not Transfer</h5>
        <p>Gimme Menu accounts may not be transferred, shared, traded, or sold.</p>
        <h5>5. Limitation of Warranty and Liability</h5>
        <p>
          We provide our Services using a commercially reasonable level of skill and care. We hope
          that you will enjoy using them. But some things we can not promise about our Services.
        </p>
        <p>
          Gimme menu makes no warranties as to the fitness, correctness, reliability, or
          merchantability of the Services, Content, or Site for a particular purpose. Gimme Menu
          provides no assurances or warranties that the Site or Services will be free from errors,
          omissions, incompatibilities, interruptions, or other risks commonly associated with the
          use of computers, software, or the Internet. Gimme Menu has no obligation to service or
          remedy any aspect of the Site or Services. WE PROVIDE THE SERVICE "AS IS".
        </p>
        <p>
          <b>
            IN NO EVENT WILL PozitiFF Company s.r.o., ITS OFFICERS, DIRECTORS, AFFILIATES, OR
            EMPLOYEES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, ECONOMIC, OR CONSEQUENTIAL
            DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE THE SITE OR SERVICES, EVEN IF
            PozitiFF Company s.r.o HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          </b>
        </p>
        <h5>6. About These Terms</h5>
        <p>
          We may modify these terms or add additional terms at any time to better reflect changes to
          laws, regulations, our Services, or our business practices. You should check these terms
          regularly. We will post a notice about changes to these terms on this page. We will make
          reasonable efforts to post notices about significant changes to the terms on our website
          or in email notices to Accounts. Changes addressing new functions for a Service or changes
          made for legal reasons will go into effect immediately. If you do not agree to the
          modified terms for a Service, you should discontinue your usage of the Service.
        </p>
        <p>
          These Terms constitute the entire agreement and control the relationship between PozitiFF
          Company s.r.o. and you. They do not create any third-party beneficiary rights.
        </p>
        <h5>ALL CLAIMS RELATED TO THESE USAGE TERMS WILL BE GOVERNED BY THE LAWS.</h5>
        <p>
          If there is a conflict between these terms and the additional terms, the additional terms
          will control for that conflict.
        </p>
        <p className='last-p'>
          If you have any concerns or questions about our Terms of Use, contact us via email
          support@gimmemenu.com.
        </p>
      </div>
      <div className='pattern-container'>
        <img src={secondaryImg1} alt='foood-patttern1' />
        <img src={secondaryImg2} alt='foood-patttern2' />
        <img src={secondaryImg3} alt='foood-patttern3' />
        <img src={secondaryImg4} alt='foood-patttern4' />
        <img src={secondaryImg5} alt='foood-patttern5' />
      </div>
    </section>
  )
}

export default TermsPage
