import React from 'react'

const IconArrows = ({ color, width, height = width }) => {
  return (
    <svg
      className='icon-arrows'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 23 23'
      fill='none'
    >
      <path
        d='M22.5433 12.9692L12.9695 22.5426C12.3605 23.1519 11.3731 23.1519 10.7644 22.5426C10.1557 21.9339 10.1557 20.9465 10.7644 20.3378L19.2357 11.8668L10.7647 3.39613C10.1559 2.78713 10.1559 1.79988 10.7647 1.19113C11.3734 0.582124 12.3608 0.582124 12.9698 1.19113L22.5435 10.7647C22.8479 11.0692 22.9999 11.4679 22.9999 11.8668C22.9999 12.2659 22.8476 12.6649 22.5433 12.9692Z'
        fill={color}
      ></path>
      <path
        d='M12.2356 12.9692L2.66192 22.5426C2.05292 23.1519 1.06552 23.1519 0.456812 22.5426C-0.151945 21.9339 -0.151945 20.9465 0.456812 20.3378L8.92807 11.8668L0.457058 3.39613C-0.151699 2.78713 -0.151699 1.79988 0.457058 1.19113C1.06582 0.582124 2.05316 0.582124 2.66217 1.19113L12.2359 10.7647C12.5403 11.0692 12.6923 11.4679 12.6923 11.8668C12.6923 12.2659 12.54 12.6649 12.2356 12.9692Z'
        fill={color}
      ></path>
    </svg>
  )
}

export default IconArrows
