import React from 'react'
import { useIsDesktop } from '../helpers/hooks'
import { useTranslation } from 'react-i18next'
import seeItDesktopImage from '../img/see-it-desktop-restbar.png'
import seeItMobiloImage from '../img/see-it-mobilo-restbar.png'
import seeItLiveMobilo from '../img/see-it-mobilo-mobilo.png'
import seeItLiveCircle from '../img/see-it-mobilo-circle.svg'
import V2CtaButton from './V2CtaButton'

const V2SeeItLive = () => {
  const isDesktop = useIsDesktop(782)
  const { t } = useTranslation()

  return (
    <section className='v2-section v2-see-it-live'>
      <div className='inner'>
        <h3>{t('HOME_SEE_TITLE_TEXT')}</h3>
        <p>{t('HOME_SEE_SUBTITLE_TEXT_1')}</p>
        {isDesktop ? (
          <div className='view-container'>
            <div className='view-item desktop'>
              <p>{t('HOME_SEE_DESKTOP_TITLE')}</p>
              <img src={seeItDesktopImage} alt='' />
            </div>
            <div className='view-item mobilo'>
              <p>{t('HOME_SEE_SMARTPHONE_TITLE')}</p>
              <p>{t('HOME_SEE_SMARTPHONE_TEXT')}</p>
              <img src={seeItMobiloImage} alt='' />
            </div>
          </div>
        ) : (
          <div className='mobilo-mobilo'>
            <img className='circle' src={seeItLiveCircle} alt='' />
            <img className='mobilo' src={seeItLiveMobilo} alt='' />
          </div>
        )}
        <V2CtaButton buttonTitle={t('HOME_SEE_CTA_TEXT')} text={t('HOME_SEE_FIRST_BADGE_TEXT')} />
      </div>
    </section>
  )
}

export default V2SeeItLive
